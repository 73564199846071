import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { SummaryService } from '../../summary/summary.service';
import { FactorService } from '../factor.service';
import { NGXLogger } from 'ngx-logger';
import { AxGridComponent } from 'src/app/shared/components';

@Component({
  selector: 'app-factor-detail',
  templateUrl: './security-detail.component.html',
  styleUrls: ['./security-detail.component.scss']
})
export class SecurityDetailComponent implements OnInit {
  @ViewChild(AxGridComponent) grid!: AxGridComponent;

  @Output() onLoad = new EventEmitter();

  constructor(
    public summary: SummaryService,
    private factor: FactorService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.factor.retrieveDetailColumns().subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.grid.setColumns(jsonObj);
      });
  }

  public retrieve(seriesName: string, category: string, currency: string, name: string, sid: number) {
    this.logger.debug("GFPA Factor retrieveDetail started");
    this.grid.showLoadingIndicator();
    this.onLoad.emit();
    this.factor.retrieveDetail(seriesName, category, currency, name, sid).subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.grid.dataSource = jsonObj;
        this.logger.debug("GFPA Factor retrieveDetail finished");
      })
      .add(() => {
        this.grid.hideLoadingIndicator();
      });
  }

}
