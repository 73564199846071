import { Injectable, EventEmitter } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { AppConfigService, AuthService } from '../';

export const SIGNAL_R_STATUS_QUEUE: string = 'SIGNAL_R_STATUS_QUEUE';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection!: signalR.HubConnection;
  private _loadFunc!: Function;

  public set loadFunc(func: Function) {
    this._loadFunc = func;
  }

  get signalrHubConnection() {
    return this.hubConnection;
  }

  constructor(
    private config: AppConfigService,
    private auth: AuthService
  ) {
    this.startConnection();
    this.addDataSourceListener();
  }

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.config.apiUrl + '/api/signalr', {
        headers: {
          'Authorization': this.auth.getAuthorizationHeaderValue(),
          'Content-Type': 'application/json'
        }
        //,withCredentials: true
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err))
  }

  public addDataSourceListener = () => {
    this.hubConnection.on('BroadcastMessage', (msg) => {
      switch (msg.from) {
        case SIGNAL_R_STATUS_QUEUE:
          this._loadFunc(msg.message);
          break;
        default:
          break;
      }
    });
  }

  /*
  public deleteDataSourceListener = (cmp) => {
    this.hubConnection.off('BroadcastMessage', (msg) => {
      cmp.dataSource = msg.message;
      //console.log(data);
    });
  }
  */
}
