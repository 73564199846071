import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { AuthService, AppConfigService, RptParamService } from '../../../shared/services';

@Injectable()

export class SummaryService {
  private _reportId!: number;
  private _portfolioName!: string;
  private _benchmarkName!: string;
  private _beginDate!: Date | null;
  private _endDate!: Date | null;
  private _analysisDate!: Date;
  private _seriesName!: string;

  get reportId(): number {
    if (!this._reportId) {
      this._reportId = this.rptParam.getReportId(this.rptParam.GFPA);
    }

    return this._reportId;
  }

  set reportId(rid: number) {
    this._reportId = rid;
    this.rptParam.setReportId(this.rptParam.GFPA, rid);
  }

  get portfolioName(): string {
    if (!this._portfolioName) {
      this._portfolioName = this.rptParam.getPortfolioName(this.rptParam.GFPA);
    }

    return this._portfolioName;
  }

  set portfolioName(name: string) {
    this._portfolioName = name;
    this.rptParam.setPortfolioName(this.rptParam.GFPA, name);
  }

  get benchmarkName(): string {
    if (!this._benchmarkName) {
      this._benchmarkName = this.rptParam.getBenchmarkName(this.rptParam.GFPA);
    }

    return this._benchmarkName
  }

  set benchmarkName(name: string) {
    this._benchmarkName = name;
    this.rptParam.setBenchmarkName(this.rptParam.GFPA, name);
  }

  get beginDate(): Date | null {
    if (!this._beginDate) {
      this._beginDate = this.rptParam.getBeginDate(this.rptParam.GFPA);
    }

    return this._beginDate;
  }

  set beginDate(date: Date) {
    this._beginDate = date;
    this.rptParam.setBeginDate(this.rptParam.GFPA, date);
  }

  get endDate(): Date | null {
    if (!this._endDate) {
      this._endDate = this.rptParam.getEndDate(this.rptParam.GFPA);
    }

    return this._endDate;
  }

  set endDate(date: Date) {
    this._endDate = date;
    this.rptParam.setEndDate(this.rptParam.GFPA, date);
  }

  get analysisDate(): Date | null {
    // 2020-06-05 james: we would calculate T rows on the fly,
    // so analysis date will always be on the end date in cumulative
    return this.endDate;
    /*
    if (!this._analysisDate) {
      this._analysisDate = this.rptParam.getAnalysisDate(this.rptParam.GFPA);
    }

    return this._analysisDate;
    */
  }

  set analysisDate(date: Date) {
    this._analysisDate = date;
    this.rptParam.setAnalysisDate(this.rptParam.GFPA, date);
  }

  get seriesName(): string {
    if (!this._seriesName) {
      this._seriesName = this.rptParam.getSeriesName(this.rptParam.GFPA);
    }

    return this._seriesName;
  }

  set seriesName(name: string) {
    this._seriesName = name;
    this.rptParam.setSeriesName(this.rptParam.GFPA, name);
  }

  constructor(
    private auth: AuthService,
    private appInfo: AppConfigService,
    private rptParam: RptParamService
  ) { }

  retrieve(): Observable<any> {
    let httpParams = new HttpParams()
      .set('reportId', '' + this.reportId);

    return this.auth.get('/api/GfpaSummary/Get', httpParams);
  }
}
