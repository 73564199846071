import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { AuthService, AppFormatService, AppConfigService } from '../../../shared/services';
import { SummaryService } from '../summary/summary.service';

@Injectable()

export class ParcoordsService {
  private _strSelCols!: string[];

  constructor(
    private summary: SummaryService,
    private auth: AuthService,
    private appFormat: AppFormatService
  ) {
  }

  retrieve(rid: number, date: Date, seriesName: string): Observable<any> {
    if (rid == 0) {
      rid = this.summary.reportId;
    }

    if (!date) {
      if (this.summary.analysisDate) {
        date = this.summary.analysisDate;
      }
    }

    if (seriesName == '0') {
      seriesName = this.summary.seriesName;
    }

    let httpParams = new HttpParams()
      .set('reportId', '' + rid)
      .set('date', this.appFormat.DateIntStr(date))
      .set('seriesName', seriesName);

    return this.auth.get('/api/GfpaParcoords/Get', httpParams);
  }
}

