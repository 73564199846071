<h6 class="content-block">Risk Summary</h6>
<p>
  Report ID: <strong>{{this.summary.reportId}}</strong>
  &nbsp;&nbsp;&nbsp; Portfolio: <strong>{{this.summary.portfolioName}}</strong>
  &nbsp;&nbsp;&nbsp; Benchmark: <strong>{{this.summary.benchmarkName}}</strong>
  &nbsp;&nbsp;&nbsp; Date: <strong>{{this.summary.analysisDate | date: 'shortDate'}}</strong>
</p>

<div class="ax-panel">
  <dx-tab-panel id="tabPanelPopOver"
                [dataSource]="this.summaryPivot.tabs"
                itemTitleTemplate="title"
                itemTemplate="item"
                [deferRendering]="false"
                [showNavButtons]="true"
                [repaintChangesOnly]="true"
                [animationEnabled]="true"
                [(selectedIndex)]="selectedIndex">
    <div *dxTemplate="let content of 'title'">
      <span>
        {{content.name}}
      </span>
    </div>
    <div *dxTemplate="let pItem of 'item'">
      <app-pivot-grid name = "Risk Summary"
                      [dataSource]="pItem.dataSource"
                      [fieldChooserEnabled]="false"
                      [chartLocation]="pItem.chartLocation"
                      [showRowGrandTotals]="true"
                      [processChartCell]="processChartCell"
                      (onCellPrepared)="pItem.onCellPrepared($event)"
                      (onCellClick)="pItem.onCellClick($event)"
                      (onContentReady)="pItem.onContentReady($event)">
      </app-pivot-grid>
    </div>
  </dx-tab-panel>
</div>

