import { Component, NgModule, AfterViewInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DxDataGridModule, DxDataGridComponent, DxButtonComponent, DxButtonModule} from 'devextreme-angular';
import { AppFormatService } from '../../services';
import { ColumnType, CalcType, DisplayType } from 'src/app/shared/utils';


@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class AxGridComponent implements AfterViewInit {
  @ViewChild(DxDataGridComponent) dataGrid!: DxDataGridComponent;

  @Input() name: string = '';
  @Input() dataSource!: any[];
  @Input() columns!: any[];
  @Input() summary: any;
  @Input() height: number | string = '100%';
  @Input() showBorders: boolean = false;
  @Input() enableExport: boolean = false;

  @Output() cellButtonClicked = new EventEmitter();
  @Output() onContentReady = new EventEmitter();
  @Output() onCellDrag = new EventEmitter();
  @Output() onCellPrepared = new EventEmitter();

  private currCellInfo: any;

  constructor(
    public appFormat: AppFormatService
  ) { }

  ngAfterViewInit() {
  }

  public updateDimensions() {
    this.dataGrid.instance.updateDimensions();
  }

  public getGroupNames(): string[] {
    let groupNames: string[] = [];
    this.columns.sort(function (obj1, obj2) {
      return obj1.groupIndex - obj2.groupIndex;
    }).forEach(col => {
      if (col.groupIndex >= 0) {
        groupNames.push(col.dataField);
      }
    });
    return groupNames.slice();
  }

  public setColumns(colAttr: any[]) {
    let groupItems:any[] = [];
    let totalItems:any[] = [];
    this.columns = [];
    colAttr.forEach(item => {
      let typeStr: string = '';
      let formatStr: string = '';
      switch (item.type) {
        case ColumnType.Date:
          typeStr = 'date';
          break;
        case ColumnType.Time:
          typeStr = 'date';
          formatStr = 'MM/dd/yyyy hh:mm:ss a';
          break;
        case ColumnType.Decimal:
          typeStr = 'number';
          formatStr = this.appFormat.GuiNumberFormat;
          break;
        case ColumnType.String:
        case ColumnType.Int:
        default:
          break;
      }

      if (item.isSummaryItem && item.summaryItemType == CalcType.Sum) {
        groupItems.push({
          column: item.name,
          summaryType: 'sum',
          showInGroupFooter: false,
          displayFormat: '{0}',
          valueFormat: this.appFormat.GuiNumberFormat,
          alignByColumn: true
        });

        totalItems.push({
          column: item.name,
          summaryType: 'sum',
          displayFormat: '{0}',
          valueFormat: this.appFormat.GuiNumberFormat,
          alignByColumn: true
        });
      }

      this.summary = {
        groupItems: groupItems,
        totalItems: totalItems
      }

      if (item.display == DisplayType.Show) {
        let col:any = {
          dataField: item.name,
          groupIndex: item.groupIndex,
          dataType: typeStr,
          format: formatStr,
          caption: item.header,
          headerCellTemplate: 'titleHeaderTemplate'
        };

        if (item.sortIndex >= 0) {
          col['sortIndex'] = item.sortIndex;
        }

        if (item.order > 0) {
          col['sortOrder'] = item.order > 1 ? 'desc' : 'asc';
        }

        if (item.showButton) {
          col['cellTemplate'] = 'buttonCellTemplate';
        } else if (item.draggable) {
          switch (item.type) {
            case ColumnType.Decimal:
              col['cellTemplate'] = 'cellDragNumberTemplate';
              break;
            case ColumnType.Date:
            case ColumnType.Time:
            case ColumnType.String:
            case ColumnType.Int:
            default:
              break;
          }
        }

        this.columns.push(col);
      }
    });
  }

  public showLoadingIndicator() {
    this.dataGrid.instance.beginCustomLoading('');
  }

  public hideLoadingIndicator() {
    this.dataGrid.instance.endCustomLoading();
  }

  cellPreparedHandler(event:any) {
    this.onCellPrepared.emit(event);
  }

  exportingHandler(event:any) {
    event.fileName = this.name;
  }

  onCellButtonClick(event:any) {
    this.cellButtonClicked.emit(event);
  }

  contentReadyHandler(event:any) {
    this.onContentReady.emit(event);
  }

  cellHoverChangedHandler(event:any) {
    if (event.rowType == 'data') {
      this.currCellInfo = {
        rowData: event.row.data,
        columnIndex: event.columnIndex
      }
    } else {
      this.currCellInfo = null;
    }
  }

  cellClickHandler(event:any) {
    /*
    if (event.cellElement.classList.contains('cell-selected')) {
      event.cellElement.classList.remove('cell-selected')
    } else {
      event.cellElement.classList.add('cell-selected');
    }
    */
  }

  cellDrag(event:any) {
    let cellInfoStr = '';
    if (this.currCellInfo) {
      cellInfoStr = JSON.stringify(this.currCellInfo);
    }
    event.dataTransfer.setData('text', cellInfoStr);
    this.onCellDrag.emit(event);
  }
}



@NgModule({
  declarations: [AxGridComponent],
  imports: [
    DxDataGridModule,
    DxButtonModule
  ],
  exports: [AxGridComponent]
})

export class AxGridModule { }
