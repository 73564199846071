import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AxGridModule, AxTreeMapModule, AxChartModule, AxParcoordsModule } from '../../shared/components';
import { SummaryComponent } from './summary/summary.component';
import { DetailComponent } from './detail/detail.component';
import { SummaryService } from './summary/summary.service';
import { DetailService } from './detail/detail.service';
import { ParcoordsComponent } from './parcoords/parcoords.component';
import { ParcoordsService } from 'src/app/pages/gfpa/parcoords/parcoords.service';
import { DxButtonModule, DxPopupModule, DxListModule, DxMultiViewModule, DxSortableModule, DxTabPanelModule } from 'devextreme-angular';
import { CategoryComponent } from './factor/category/category.component';
import { FactorComponent } from './factor/factor.component';
import { FactorService } from './factor/factor.service';
import { AxDrillDownBarpModule } from 'src/app/shared/components/drill-down-bar/drill-down-bar.component';
import { CurrencyComponent } from './factor/currency/currency.component';
import { NameComponent } from './factor/name/name.component';
import { SecurityComponent } from './factor/security/security.component';
import { SecurityDetailComponent } from './factor/security-detail/security-detail.component';
import { HistoryTabComponent } from './factor/history-tab/history-tab.component';
import { HistoryTabService } from './factor/history-tab/history-tab.service';
import { HistoryTabDirective } from './factor/history-tab/history-tab.directive';

@NgModule({
  declarations: [
    SummaryComponent,
    DetailComponent,
    ParcoordsComponent,
    CategoryComponent,
    FactorComponent,
    CurrencyComponent,
    NameComponent,
    SecurityComponent,
    SecurityDetailComponent,
    HistoryTabComponent,
    HistoryTabDirective
  ],
  imports: [
    CommonModule,
    AxGridModule,
    AxTreeMapModule,
    AxChartModule,
    AxParcoordsModule,
    DxButtonModule,
    DxPopupModule,
    DxListModule,
    DxMultiViewModule,
    AxDrillDownBarpModule,
    DxSortableModule,
    DxTabPanelModule
  ],
  providers: [
    SummaryService,
    DetailService,
    ParcoordsService,
    FactorService,
    HistoryTabService
  ]
})
export class GfpaModule { }
