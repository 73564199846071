import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { SummaryService } from '../summary/summary.service';
import { SummaryInDepthService } from './summary-in-depth.service';
import { AxBubbleChartComponent } from '../../../shared/components';
import { DxSliderComponent } from 'devextreme-angular';
import { AppFormatService, ColorService } from '../../../shared/services';
import { getPalette } from "devextreme/viz/palette";
import * as _ from 'underscore';

const MARGIN_DEN = 5;

@Component({
  selector: 'app-summary-in-depth',
  templateUrl: './summary-in-depth.component.html',
  styleUrls: ['./summary-in-depth.component.scss']
})
export class SummaryInDepthComponent implements AfterViewInit {
  @ViewChild(AxBubbleChartComponent) chart!: AxBubbleChartComponent;
  @ViewChild(DxSliderComponent) slider!: DxSliderComponent;

  private allDates: Date[] = [];
  private xMax!: number;
  private xMin!: number;
  private yMax!: number;
  private yMin!: number;
  private enableSliderValueEvt!: boolean;
  private animateStep = 1;
  private animateDelay = 0;
  private currData!: any[];

  maxNumDays!: number;
  tooltipFormat = (value:any) => {
    let currDate = this.allDates[value];
    let currDateStr: string = '';
    let dd = this.appFormat.GuiDate(currDate);
    if (dd) {
      currDateStr = dd;
    }
    
    return currDateStr;
  }

  get baseBubbleColors() {
    return getPalette('Bright').simpleSet;
  }

  constructor(
    public summary: SummaryService,
    private summaryInDepth: SummaryInDepthService,
    private router: Router,
    private appFormat: AppFormatService,
    private colorUtil: ColorService
  ) { }

  ngAfterViewInit() {
    this.chart.series = [{
      name: 'Allocation High',
      argumentField: 'xAllocationHigh',
      valueField: 'yAllocationHigh',
      sizeField: 'portWeightAbs',
      tagField: 'path'
    }, {
      name: 'Allocation Low',
      argumentField: 'xAllocationLow',
      valueField: 'yAllocationLow',
      sizeField: 'portWeightAbs',
      tagField: 'path'
    }, {
      name: 'Selection Effect',
      argumentField: 'xSelectionEffect',
      valueField: 'ySelectionEffect',
      sizeField: 'portWeightAbs',
      tagField: 'path'
    }, {
      name: 'Fund Residual',
      argumentField: 'xFundResidual',
      valueField: 'yFundResidual',
      sizeField: 'portWeightAbs',
      tagField: 'path'
    }, {
      name: 'Active Currency Return',
      argumentField: 'xActiveCurrencyReturn',
      valueField: 'yActiveCurrencyReturn',
      sizeField: 'portWeightAbs',
      tagField: 'path'
    }];

    this.chart.legend = {
      backgroundColor: 'transparent',
      font: {
        color: 'black',
        weight: 600
      }
    };

    this.chart.customizeTooltip = (arg:any) => {
      return {
        text: arg.point.tag + '<br/>Return: ' + this.appFormat.GuiNumber(arg.argument) + '<br/>' + arg.seriesName + ': ' + this.appFormat.GuiNumber(arg.value) + '<br/>Portfolio Weight: ' + this.appFormat.GuiNumber(arg.point.data.portWeight)
      };
    }

    this.chart.customizePoint = (arg:any) => {
      let isNegative = false;
      if (arg.data.portWeight < 0) {
        isNegative = true;
      }

      let colors = this.baseBubbleColors;
      let seriesIndex = arg.series.index;
      let seriesColor = 'rgba(255, 0, 0, .5)';
      if (seriesIndex >= 0 && seriesIndex < colors.length) {
        seriesColor = colors[seriesIndex];
      }

      let opColor = this.colorUtil.hex2rgb(seriesColor, .2);
      if (isNegative) {
        return {
          color: 'transparent',
          border: {
            visible: true,
            color: seriesColor,
            dashStyle: 'solid',
            width: 5
          },
          hoverStyle: {
            border: {
              visible: true,
              color: seriesColor,
              dashStyle: 'solid',
              width: 5
            }
          }
        };
      } else {
        return {
          /*
          color: opColor,
          border: {
            visible: true,
            color: seriesColor,
            dashStyle: 'solid',
            width: 5
          },
          hoverStyle: {
            border: {
              visible: true,
              color: seriesColor,
              dashStyle: 'solid',
              width: 5
            }
          }
          */
        };
      }
    }

    this.setVisibleSeries();
    this.playMovie();
  }

  private setVisibleSeries() {
    let visibleIndex = this.summary.inDepthSeriesVisibleIndexList;
    let ii = 0;
    if (visibleIndex && visibleIndex.length > 0) {
      visibleIndex.forEach((item) => {
        this.chart.series[ii].visible = item;
        ii++;
      });
    }
  }

  seriesChanged(e: any) {
    let visibleIndex:any[] = [];
    e.forEach((ss:any) => {
      visibleIndex.push(ss.isVisible());
    });

    this.summary.inDepthSeriesVisibleIndexList = visibleIndex;
    this.setVisibleSeries()

    if (this.currData) {
      this.resetVisualRange(this.findDomainRange(this.currData));
    }
  }

  private resetVisualRange(domainRange: [number, number, number, number]) {
    let xMargin = Math.abs(domainRange[1] - domainRange[0]) / MARGIN_DEN;
    let yMargin = Math.abs(domainRange[3] - domainRange[2]) / MARGIN_DEN;

    this.chart.argumentAxis = {
      label: {
        format: {
          type: 'fixedPoint',
          precision: 3
        }
      },
      title: 'Return',
      visualRangeUpdateMode: 'auto',
      min: domainRange[0] - xMargin,
      max: domainRange[1] + xMargin
    };

    this.chart.valueAxis = {
      label: {
        format: {
          type: 'fixedPoint',
          precision: 3
        }
      },
      title: 'Return Contribution',
      visualRangeUpdateMode: 'auto',
      min: domainRange[2] - yMargin,
      max: domainRange[3] + yMargin
    };
  }

  private setFixVisualRange() {
    let xMargin = Math.abs(this.xMax - this.xMin) / MARGIN_DEN;
    let yMargin = Math.abs(this.yMax - this.yMin) / MARGIN_DEN;

    this.chart.argumentAxis = {
      label: {
        format: {
          type: 'fixedPoint',
          precision: 3
        }
      },
      title: 'Return',
      visualRangeUpdateMode: 'keep',
      min: this.xMin - xMargin,
      max: this.xMax + xMargin
    };

    this.chart.valueAxis = {
      label: {
        format: {
          type: 'fixedPoint',
          precision: 3
        }
      },
      title: 'Return Contribution',
      visualRangeUpdateMode: 'keep',
      min: this.yMin - yMargin,
      max: this.yMax + yMargin
    };

    this.enableSliderValueEvt = false;
  }

  private chartAnimate(dateIndex: number) {
    let length = this.allDates.length;
    let lastIndex = length - 1;
    if (length == 0) {
      return;
    }

    if (dateIndex < 0 ||
      dateIndex > length) {
      return;
    }

    this.chart.showLoadingIndicator();
    this.summaryInDepth.retrieve(this.allDates[dateIndex]).subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.slider.value = dateIndex;
        this.chart.dataSource = jsonObj;

        if (dateIndex == lastIndex) {
          this.enableSliderValueEvt = true;
          this.currData = jsonObj;
          return;
        }

        if (dateIndex < lastIndex) {
          // only draw chart if it's not the last date yet
          dateIndex = dateIndex + this.animateStep;

          if (dateIndex > lastIndex) {
            // if date is over the last date, just draw the last date
            dateIndex = lastIndex;
          }

          //setTimeout(() => {
          this.chartAnimate(dateIndex);
          //}, this.animateDelay);
        }
      })
      .add(() => {
        this.chart.hideLoadingIndicator();
      });
  }

  private playMovie() {
    if (this.summary.reportId) {
      this.summaryInDepth.retrieveRange().subscribe(
        range => {
          let jsonObj = JSON.parse(range);
          this.allDates = jsonObj.dates;
          this.xMax = jsonObj.xMax;
          this.xMin = jsonObj.xMin;
          this.yMax = jsonObj.yMax;
          this.yMin = jsonObj.yMin;
          this.maxNumDays = this.allDates.length - 1;

          let length = this.allDates.length;

          if (length > 100) {
            this.animateStep = Math.floor(length / 100);
          }

          if (length > 50) {
            //this.animateDelay = 30;
          } else if (length < 10) {
            //this.animateDelay = 100;
          }

          if (this.animateStep < 1) {
            this.animateStep = 1;
          }

          this.setFixVisualRange();
          this.chartAnimate(0);
        });
    }
  }

  onPlayClick(e: any) {
    this.playMovie();
  }

  private calculateDomainRange(domainRange: [number, number, number, number], x: number, y: number) {
    if (x != null && y != null) {
      if (isNaN(domainRange[0])) {
        domainRange[0] = x;
      } else {
        if (x < domainRange[0]) {
          domainRange[0] = x;
        }
      }

      if (isNaN(domainRange[1])) {
        domainRange[1] = x;
      } else {
        if (x > domainRange[1]) {
          domainRange[1] = x;
        }
      }

      if (isNaN(domainRange[2])) {
        domainRange[2] = y;
      } else {
        if (y < domainRange[2]) {
          domainRange[2] = y;
        }
      }

      if (isNaN(domainRange[3])) {
        domainRange[3] = y;
      } else {
        if (y > domainRange[3]) {
          domainRange[3] = y;
        }
      }
    }
  }

  private findDomainRange(data: any[]): [number, number, number, number] {
    let domainRange: [number, number, number, number] = [NaN, NaN, NaN, NaN];
    let visibleIndex = this.summary.inDepthSeriesVisibleIndexList;

    _.each(data, (item) => {
      if (visibleIndex[0]) {
        this.calculateDomainRange(domainRange, item.xAllocationHigh, item.yAllocationHigh);
      }

      if (visibleIndex[1]) {
        this.calculateDomainRange(domainRange, item.xAllocationLow, item.yAllocationLow);
      }

      if (visibleIndex[2]) {
        this.calculateDomainRange(domainRange, item.xSelectionEffect, item.ySelectionEffect);
      }

      if (visibleIndex[3]) {
        this.calculateDomainRange(domainRange, item.xFundResidual, item.yFundResidual);
      }

      if (visibleIndex[4]) {
        this.calculateDomainRange(domainRange, item.xActiveCurrencyReturn, item.yActiveCurrencyReturn);
      }
    });

    return domainRange;
  }

  /*
  private findDomain(data: any[]): [number, number] {
    let domain: [number, number] = [undefined, undefined];
    _.each(data, (item) => {
      let x = item.xAllocationHigh;
      let y = item.yAllocationHigh;
      if (!isNaN(x) && !isNaN(y)) {
        if (isNaN(domain[0]) {
          domain[0] = x;
        }

        if (isNaN(domain[1]) {
          domain[1] = x;
        }
      }
    });
    domain[0] = _.min(data, (item) => {
      return item.xAllocationHigh;
    }).xAllocationHigh;

    let temp = _.min(data, (item) => {
      return item.xAllocationLow;
    }).xAllocationLow;

    if (temp < domain[0]) {
      domain[0] = temp;
    }

    temp = _.min(data, (item) => {
      return item.xSelectionEffect;
    }).xSelectionEffect;

    if (temp < domain[0]) {
      domain[0] = temp;
    }

    temp = _.min(data, (item) => {
      return item.xFundResidual;
    }).xFundResidual;

    if (temp < domain[0]) {
      domain[0] = temp;
    }

    temp = _.min(data, (item) => {
      return item.xActiveCurrencyReturn;
    }).xActiveCurrencyReturn;

    if (temp < domain[0]) {
      domain[0] = temp;
    }

    domain[1] = _.max(data, (item) => {
      return item.xAllocationHigh;
    }).xAllocationHigh;

    temp = _.max(data, (item) => {
      return item.xAllocationLow;
    }).xAllocationLow;

    if (temp > domain[1]) {
      domain[1] = temp;
    }

    temp = _.max(data, (item) => {
      return item.xSelectionEffect;
    }).xSelectionEffect;

    if (temp > domain[1]) {
      domain[1] = temp;
    }

    temp = _.max(data, (item) => {
      return item.xFundResidual;
    }).xFundResidual;

    if (temp > domain[1]) {
      domain[1] = temp;
    }

    temp = _.max(data, (item) => {
      return item.xActiveCurrencyReturn;
    }).xActiveCurrencyReturn;

    if (temp > domain[1]) {
      domain[1] = temp;
    }

    return domain;
  }

  private findRange(data: any[]): [number, number] {
    let range: [number, number] = [undefined, undefined];
    range[0] = _.min(data, (item) => {
      return item.yAllocationHigh;
    }).yAllocationHigh;

    let temp = _.min(data, (item) => {
      return item.yAllocationLow;
    }).yAllocationLow;

    if (temp < range[0]) {
      range[0] = temp;
    }

    temp = _.min(data, (item) => {
      return item.ySelectionEffect;
    }).ySelectionEffect;

    if (temp < range[0]) {
      range[0] = temp;
    }

    temp = _.min(data, (item) => {
      return item.yFundResidual;
    }).yFundResidual;

    if (temp < range[0]) {
      range[0] = temp;
    }

    temp = _.min(data, (item) => {
      return item.yActiveCurrencyReturn;
    }).yActiveCurrencyReturn;

    if (temp < range[0]) {
      range[0] = temp;
    }

    range[1] = _.max(data, (item) => {
      return item.yAllocationHigh;
    }).yAllocationHigh;

    temp = _.max(data, (item) => {
      return item.yAllocationLow;
    }).yAllocationLow;

    if (temp > range[1]) {
      range[1] = temp;
    }

    temp = _.max(data, (item) => {
      return item.ySelectionEffect;
    }).ySelectionEffect;

    if (temp > range[1]) {
      range[1] = temp;
    }

    temp = _.max(data, (item) => {
      return item.yFundResidual;
    }).yFundResidual;

    if (temp > range[1]) {
      range[1] = temp;
    }

    temp = _.max(data, (item) => {
      return item.yActiveCurrencyReturn;
    }).yActiveCurrencyReturn;

    if (temp > range[1]) {
      range[1] = temp;
    }

    return range;
  }
  */
  onValueChanged(e: any) {
    if (this.enableSliderValueEvt && this.summary.reportId && this.allDates) {
      let dateSelected: Date = this.allDates[e.value];
      if (dateSelected) {
        this.chart.showLoadingIndicator();
        this.summaryInDepth.retrieve(dateSelected).subscribe(
          data => {
            let jsonObj = JSON.parse(data);
            this.currData = jsonObj;
            this.chart.dataSource = jsonObj;
            this.resetVisualRange(this.findDomainRange(jsonObj));
          })
          .add(() => {
            this.chart.hideLoadingIndicator();
          });
      }
    }
  }
}
