<ax-drill-down-bar (onDrill)="drill()"></ax-drill-down-bar>
<dx-tree-map #treeMapId
             [dataSource]=dataSource
             resolveLabelOverflow="ellipsis"
             idField="id"
             parentField="parentId"
             (onClick)="nodeClick($event)">
  <!--(onDrill)="drill($event)">-->
  <dxo-tooltip [enabled]="true"
               format="thousands"
               [customizeTooltip]="customizeTooltipFunc"></dxo-tooltip>
  <dxo-size [height]="500"></dxo-size>
  <!--<dxo-loading-indicator [enabled]="true"></dxo-loading-indicator>-->
</dx-tree-map>
<dx-load-panel #loadPanel
               shadingColor="rgba(0,0,0,0.4)"
               [position]="{ of: '#treeMapId' }"
               [(visible)]="loadingVisible"
               [showIndicator]="true"
               [showPane]="true"
               [shading]="false"
               [closeOnOutsideClick]="false">
</dx-load-panel>
