<dx-chart [dataSource]="dataSource"
          [series]="series"
          [argumentAxis]="argumentAxis"
          [valueAxis]="valueAxis"
          palette="soft"
          [rotated]="rotated"
          (onPointClick)="pointClicked($event)"
          (onPointHoverChanged)="onPointHoverChanged($event)"
          (onSeriesClick)="onSeriesClick($event)"
          (onLegendClick)="onLegendClick($event)"
          (onDrawn)="onDrawn($event)">
  <dxo-margin [bottom]="20"></dxo-margin>
  <dxo-loading-indicator [enabled]="true"></dxo-loading-indicator>
  <dxo-legend verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="bottom">
  </dxo-legend>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-tooltip [enabled]="true"
               [customizeTooltip]="customizeTooltipFunc">
  </dxo-tooltip>
</dx-chart>
