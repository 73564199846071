import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import { map } from "rxjs/operators";
import { HttpEventType, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {

  constructor(
    private http: HttpClient,
    private appInfo: AppConfigService,
    private auth: AuthService
    ) { }

  public download(url: string, file: string) {
    let fileName = file;
    let checkFileType = fileName.split('.').pop();
    var fileType = 'text/plain';
    if (checkFileType == ".pdf") {
      fileType = "application/pdf";
    }
    if (checkFileType == ".doc") {
      fileType = "application/vnd.ms-word";
    }
    if (checkFileType == ".docx") {
      fileType = "application/vnd.ms-word";
    }
    if (checkFileType == ".xls") {
      fileType = "application/vnd.ms-excel";
    }
    if (checkFileType == ".png") {
      fileType = "image/png";
    }
    if (checkFileType == ".jpg") {
      fileType = "image/jpeg";
    }
    if (checkFileType == ".jpeg") {
      fileType = "image/jpeg";
    }
    if (checkFileType == ".gif") {
      fileType = "image/gif";
    }
    if (checkFileType == ".csv") {
      fileType = "text/csv";
    }
    this.downloadFile(url, fileName, fileType)
      .subscribe(
        success => {
          saveAs(success, fileName);
        },
        err => {
          alert("Server error while downloading file.");
        }
      );
  }

  private downloadFile(url: string, filePath: string, fileType: string): Observable<any> {

    let fileExtension = fileType;
    let input = filePath;

    let httpParams = new HttpParams()
      .set('reportFile', '' + filePath);

    return this.http.get(this.appInfo.apiUrl + url, { 
      params: httpParams, 
      headers: this.auth.getHeaders(),
      responseType: 'blob',
      observe: 'response'
    })
      .pipe(
        map((res: any) => {
          return new Blob([res.body], { type: fileExtension });
        })
      );
  }
}
