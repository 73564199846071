import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
//import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private errSub = new Subject<any>();
  public errObservable = this.errSub.asObservable();

  constructor(
    //public snackBar: MatSnackBar,
    //private zone: NgZone
  ) { }

  public showError(message: string) {
    this.errSub.next(message);
  }

  /*
  showSuccess(message: string): void {
    this.notifySubject.next(message);
  }

  showError(message: string): void {
    this.notifySubject.next(message);
  }
  */
}

