import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RptParamService } from '../../services';

@Injectable()
export class SideNavigationMenuService {
  public readonly REPORT_LIBRARY_PATH: string = '/report-library';
  public readonly RV_SUMMARY_PATH: string = '/rv-summary';
  public readonly RV_GROUP_SUMMARY_PATH: string = '/rv-group-summary';
  public readonly RV_SUMMARY_IN_DEPTH_PATH: string = '/rv-in-depth';
  public readonly RV_DETAIL_PATH: string = '/rv-detail/0/0/0';
  public readonly GFPA_SUMMARY_PATH: string = '/gfpa-summary';
  public readonly GFPA_DETAIL_PATH: string = '/gfpa-detail/0/0/0';
  public readonly GFPA_PARCOORDS_PATH: string = '/gfpa-parcoords/0/0/0';
  public readonly GFPA_FACTOR_PATH: string = '/gfpa-factor/0/0';
  public readonly GFR_SUMMARY_PATH: string = '/gfr-summary';
  public readonly GFR_PARCOORDS_PATH: string = '/gfr-parcoords/0/0/0';
  public readonly GFR_FACTOR_PATH: string = '/gfr-factor/0/0';
  public readonly GFR_DETAIL_PATH: string = '/gfr-detail/0/0/0';
  public readonly FOF_SUMMARY_PATH: string = '/fof-summary';
  public readonly DATA_LOAD_SUMMARY_PATH: string = '/data-load-summary/0/0';
  public readonly DATA_LOAD_DETAIL_PATH: string = '/data-load-detail/0/0';
  public readonly CASH_FLOW_SUMMARY_PATH: string = '/cash-flow-summary';
  public readonly SECURITY_LOOKUP_PATH: string = '/security-lookup';
  public readonly REPORT_RUNNER_PATH: string = '/report-runner';
  
  public readonly rvIconClass = 'dx-icon-rv';
  public readonly gfpaIconClass = 'dx-icon-gfpa';
  public readonly gfrIconClass = 'dx-icon-gfr';
  public readonly fofIconClass = 'dx-icon-fof';
  public readonly dlsIconClass = 'dx-icon-dls';

  public readonly MENU_DETAIL_PATH: string = '/menu-detail';

  public menuItems = [
    {
      text: 'Report Library',
      path: '/report-library',
      icon: 'home'
    },
    {
      text: 'Returns Variance',
      icon: 'rv',
      items: [
        {
          text: 'Summary',
          path: this.RV_SUMMARY_PATH
        },
        {
          text: 'Group Summary',
          path: this.RV_GROUP_SUMMARY_PATH
        },
        {
          text: 'Summary In-Depth',
          path: this.RV_SUMMARY_IN_DEPTH_PATH
        },
        {
          text: 'Detail',
          path: this.RV_DETAIL_PATH
        }
      ]
    },
    {
      text: 'Global Factor Perf. Attr.',
      icon: 'gfpa',
      items: [
        {
          text: 'Summary',
          path: this.GFPA_SUMMARY_PATH
        },
        {
          text: 'Detail',
          path: this.GFPA_DETAIL_PATH
        },
        /*
        {
          text: 'Parallel Coordinates',
          path: this.GFPA_PARCOORDS_PATH
        },
        */
        {
          text: 'Factor',
          path: this.GFPA_FACTOR_PATH
        }
      ]
    },
    {
      text: 'Global Factor Risk',
      icon: 'gfr',
      items: [
        {
          text: 'Summary',
          path: this.GFR_SUMMARY_PATH
        },
        /*
        {
          text: 'Parallel Coordinates',
          path: this.GFR_PARCOORDS_PATH
        },
        */
        {
          text: 'Factor',
          path: this.GFR_FACTOR_PATH
        }
      ]
    },
    {
      text: 'Risk Summary',
      icon: 'fof',
      items: [
        {
          text: 'Summary',
          path: this.FOF_SUMMARY_PATH
        }
      ]
    },
    {
      text: 'Data Load Status',
      icon: 'dls',
      items: [
        {
          text: 'Summary',
          path: this.DATA_LOAD_SUMMARY_PATH
        },
        {
          text: 'Detail',
          path: this.DATA_LOAD_DETAIL_PATH
        }
      ]
    },
    {
      text: 'Security Lookup',
      icon: 'search',
      path: this.SECURITY_LOOKUP_PATH
    },
    {
      text: 'Report Runner',
      icon: 'runner',
      path: this.REPORT_RUNNER_PATH
    }
  ];

  private homeItem = {
    text: 'Report Library',
    path: '/report-library',
    icon: 'home'
  };

  private rvItem = {
    text: 'Returns Variance',
    icon: 'rv',
    items: [
      {
        text: 'Summary',
        path: this.RV_SUMMARY_PATH
      },
      {
        text: 'Group Summary',
        path: this.RV_GROUP_SUMMARY_PATH
      },
      {
        text: 'Summary In-Depth',
        path: this.RV_SUMMARY_IN_DEPTH_PATH
      },
      {
        text: 'Detail',
        path: this.RV_DETAIL_PATH
      }
    ]
  };

  private gfpaItem = {
    text: 'Global Factor Perf. Attr.',
    icon: 'gfpa',
    items: [
      {
        text: 'Summary',
        path: this.GFPA_SUMMARY_PATH
      },
      {
        text: 'Detail',
        path: this.GFPA_DETAIL_PATH
      },/*
      {
        text: 'Parallel Coordinates',
        path: this.GFPA_PARCOORDS_PATH
      },*/
      {
        text: 'Factor',
        path: this.GFPA_FACTOR_PATH
      }
    ]
  };

  private gfrItem = {
    text: 'Global Factor Risk',
    icon: 'gfr',
    items: [
      {
        text: 'Summary',
        path: this.GFR_SUMMARY_PATH
      },/*
      {
        text: 'Parallel Coordinates',
        path: this.GFR_PARCOORDS_PATH
      },*/
      {
        text: 'Factor',
        path: this.GFR_FACTOR_PATH
      }
    ]
  };

  private fofItem = {
    text: 'Risk Summary',
    icon: 'fof',
    items: [
      {
        text: 'Summary',
        path: this.FOF_SUMMARY_PATH
      }
    ]
  };

  private dlsItem = {
    text: 'Data Load Status',
    icon: 'dls',
    items: [
      {
        text: 'Summary',
        path: this.DATA_LOAD_SUMMARY_PATH
      },
      {
        text: 'Detail',
        path: this.DATA_LOAD_DETAIL_PATH
      }
    ]
  };

  private cfItem = {
    text: 'Cash Flow',
    icon: 'cf',
    items: [
      {
        text: 'Summary',
        path: this.CASH_FLOW_SUMMARY_PATH
      }
    ]
  };

  private slItem = {
    text: 'Security Lookup',
    icon: 'search',
    path: this.SECURITY_LOOKUP_PATH
  }

  private rrItem = {
    text: 'Report Runner',
    icon: 'runner',
    path: this.REPORT_RUNNER_PATH
  }
  
  private menuSub = new Subject<any>();

  get menuObservable() {
    return this.menuSub.asObservable();
  }

  constructor(
    private rptParam: RptParamService
  ) {
    // init menu items
    this.fillMenuItems();

    this.menuItems.forEach((ii:any) => {
      // this assume icon name is same as the report type name
      let reportType = ii.icon.toLowerCase();
      if (this.rptParam.isReportType(reportType)) {
        // check if the report has entry in browser storage
        // if it does, we need to enable the report on the menu
        let reportId = this.rptParam.getReportId(reportType);
        if (reportId > 0) {
          ii['disabled'] = false;
        } else {
          ii['disabled'] = false;
        }
      }
    });
  }

  public getDefaultPath(path: string): string {
    if (path.includes('/rv-summary')) {
      return this.RV_SUMMARY_PATH;
    } else if (path.includes('/rv-group-summary')) {
      return this.RV_GROUP_SUMMARY_PATH;
    } else if (path.includes('/rv-in-depth')) {
      return this.RV_SUMMARY_IN_DEPTH_PATH;
    } else if (path.includes('/rv-detail')) {
      return this.RV_DETAIL_PATH;
    } else if (path.includes('/gfpa-summary')) {
      return this.GFPA_SUMMARY_PATH;
    } else if (path.includes('/gfpa-detail')) {
      return this.GFPA_DETAIL_PATH;
    } else if (path.includes('/gfpa-parcoords')) {
      return this.GFPA_PARCOORDS_PATH;
    } else if (path.includes('/gfpa-factor')) {
      return this.GFPA_FACTOR_PATH;
    } else if (path.includes('/gfr-summary')) {
      return this.GFR_SUMMARY_PATH;
    } else if (path.includes('/gfr-detail')) {
      return this.GFR_DETAIL_PATH;
    } else if (path.includes('/gfr-parcoords')) {
      return this.GFR_PARCOORDS_PATH;
    } else if (path.includes('/gfr-factor')) {
      return this.GFR_FACTOR_PATH;
    } else if (path.includes('/data-load-summary')) {
      return this.DATA_LOAD_SUMMARY_PATH;
    } else if (path.includes('/data-load-detail')) {
      return this.DATA_LOAD_DETAIL_PATH;
    } else if (path.includes('/cash-flow-summary')) {
      return this.CASH_FLOW_SUMMARY_PATH;
    } else if (path.includes('/menu-detail')) {
      return this.MENU_DETAIL_PATH;
    } else if (path.includes('/security-lookup')) {
      return this.SECURITY_LOOKUP_PATH;
    } else if (path.includes('/report-runner')) {
      return this.REPORT_RUNNER_PATH;
    } else {
      return this.REPORT_LIBRARY_PATH;
    }
  }

  private fillMenuItems() {
    this.menuItems = [
      this.homeItem,
      this.rvItem,
      this.gfpaItem,
      this.gfrItem,
      this.fofItem,
      this.dlsItem,
      this.slItem,
      this.rrItem
      // cash flow was for POC testing only 
      // this.cfItem
    ];
  }

  public getMenuItems() {
    return this.menuItems;
  }

  public getReportIconClass(path: string): string | undefined {
    let iconClass : string | undefined;
    switch (path) {
      case this.RV_SUMMARY_PATH:
      case this.RV_GROUP_SUMMARY_PATH:
      case this.RV_SUMMARY_IN_DEPTH_PATH:
      case this.RV_DETAIL_PATH:
        iconClass = this.rvIconClass;
        break;
      case this.GFPA_SUMMARY_PATH:
      case this.GFPA_DETAIL_PATH:
        case this.GFPA_FACTOR_PATH:
        iconClass = this.gfpaIconClass;
        break;
      case this.GFR_SUMMARY_PATH:
        case this.GFR_FACTOR_PATH:
          iconClass = this.gfrIconClass;
          break;
      case this.FOF_SUMMARY_PATH:
          iconClass = this.fofIconClass;
          break;
      default:
        break;
    }

    return iconClass;
  }
  
  public enableReportMenuItem(reportType: string) {
    /*
    let iconClass : string | undefined;
    switch (reportType.toLowerCase()) {
      case this.rptParam.RV:
        iconClass = this.rvIconClass;
        break;
      case this.rptParam.GFPA:
        iconClass = this.gfpaIconClass;
        break;
      case this.rptParam.GFR:
        iconClass = this.gfrIconClass;
        break;
      case this.rptParam.FOF:
        iconClass = this.fofIconClass;
        break;
      default:
        return;
    }
    if (iconClass) {
      let disabledClass = '.dx-state-disabled';
      let selectedNode = element.querySelector(`.dx-icon.${iconClass}`);
      while (selectedNode && selectedNode.parentElement) {
        if (selectedNode.classList.contains(disabledClass)) {
          selectedNode.classList.remove(disabledClass);
        }
  
        selectedNode = selectedNode.parentElement;
      }
    }
    

    let ii = this.menuItems.find(ii => ii.icon == item.icon);
    if (ii) {
      item['disabled'] = false;
      //this.fillMenuItems();
      //this.menuSub.next(this.menuItems);
    }
    */
  }
}
