<!--
<div class="container content-block">
  <div class="row">
    <div class="col-sm-6"><h6>Global Factor Performance Attribution</h6></div>
    <div class="col-sm-2 align-text-bottom">
      Report ID: <strong>{{this.objId}}</strong>
    </div>
    <div class="col-sm-2 align-text-bottom">
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{this.objName}}: <strong>{{this.objValue}}</strong></span>
    </div>
    <div class="col-sm-2  align-bottom">
      <dx-button stylingMode="contained"
                 type="normal"
                 text="Clear"
                 width="100"
                 (onClick)="clearClicked($event)"></dx-button>
    </div>
  </div>
</div>
-->

<h6 class="content-block">Global Factor Performance Attribution</h6>
<p>
  Report ID: <strong>{{this.objId}}</strong>
  <span *ngIf="isActive; else notActive">
    &nbsp;&nbsp;&nbsp; <strong>Active</strong>
    &nbsp;&nbsp;&nbsp; {{this.rptParam.PORTFOLIO}}: <strong>{{this.summary.portfolioName}}</strong>
    &nbsp;&nbsp;&nbsp; {{this.rptParam.BENCHMARK}}: <strong>{{this.summary.benchmarkName}}</strong>
  </span>
  <dx-button stylingMode="contained"
             type="normal"
             text="Reset Brushes"
             width="120"
             height="30"
             (onClick)="resetBrushClicked($event)"></dx-button>
  <dx-button stylingMode="contained"
             type="normal"
             text="Select Columns"
             width="120"
             height="30"
             (onClick)="selectColumnsClicked($event)"></dx-button>
</p>
<ng-template #notActive>
  <span>&nbsp;&nbsp;&nbsp;{{this.objName}}: <strong>{{this.objValue}}</strong></span>
</ng-template>
<app-parcoords></app-parcoords>
<dx-popup [width]="1000"
          [height]="500"
          [showTitle]="true"
          [showCloseButton]="true"
          title="Available Columns &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Selected Columns"
          [dragEnabled]="true"
          [closeOnOutsideClick]="true"
          [(visible)]="popupVisible"
          (onHidden)="popup_hidden($event)">
  <!--
  <div class="container">
    <div class="row">
      <div class="col">
        Available Columns
      </div>
      <div class="col">
        Selected Columns
      </div>
    </div>
  </div>
  -->
  <div class="widget-container">
    <dx-list [items]="this.axParService.availCols"
             [repaintChangesOnly]="true"
             keyExpr="id">
      <dxo-item-dragging group="tasks"
                         [data]="this.axParService.availCols"
                         [allowReordering]="true"
                         [onDragStart]="onDragStart"
                         [onAdd]="onAdd"
                         [onRemove]="onRemove">
      </dxo-item-dragging>
    </dx-list>
    <dx-list [items]="this.axParService.selCols"
             [repaintChangesOnly]="true"
             keyExpr="id">
      <dxo-item-dragging group="tasks"
                         [data]="this.axParService.selCols"
                         [allowReordering]="true"
                         [onDragStart]="onDragStart"
                         [onAdd]="onAdd"
                         [onRemove]="onRemove">
      </dxo-item-dragging>
    </dx-list>
  </div>
</dx-popup>

