import { Component, OnInit } from '@angular/core';
/*
import * as d3 from 'd3';
import ParCoords from 'parcoord-es';
import * as _ from 'underscore';
*/

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    /*
    var parcoords = ParCoords()("#nutrients");
    d3.csv('/assets/csv/nutrients.csv').then(function (data) {
      var colorList = ['#3b73b9', '#aa71aa', '#ee3224', '#00aeef', '#f8981d',
        '#80bb42', '#aacdef', '#cacec2', '#aaa6ce', '#df9e9d', '#6ab2e7',
        '#ffdd00', '#9ac2b9', '#a7a9ac', '#bbbb42', '#e6a6ce'];

      var groups = _(data).chain()
        .pluck('group')
        .uniq().value();

      var colorScale = d3.scaleOrdinal().domain(groups).range(colorList);
      var color = function (d) {
        return colorScale(d.group);
      };

      parcoords
        .data(data)
        .color(color)
        .hideAxis(['name'])
        .margin({ top: 24, left: 60, bottom: 12, right: 0 })
        .mode("queue")
        .alpha(.7)
        .render()
        .brushMode("1D-axes");  // enable brushing
  });
  */
  }
}
