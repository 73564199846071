import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { DxDataGridModule, DxFormModule, DxSelectBoxModule } from 'devextreme-angular';
import { AxGridModule } from './shared/components';
import { AuthGuardService, AppFormatService, AppConfigService } from './shared/services';
import { ReportConfigComponent } from './pages/report-config/report-config.component';
import { ReportConfigService } from './pages/report-config/report-config.service';
import { RvModule } from './pages/rv/rv.module';
import { SummaryComponent } from './pages/rv/summary/summary.component';
import { SummaryInDepthComponent } from './pages/rv/summary-in-depth/summary-in-depth.component';
import { DetailComponent } from './pages/rv/detail/detail.component';
import { GfpaModule } from './pages/gfpa/gfpa.module';
import { SummaryComponent as GfpaSummaryComponent } from './pages/gfpa/summary/summary.component';
import { DetailComponent as GfpaDetailComponent } from './pages/gfpa/detail/detail.component';
import { ParcoordsComponent as GfpaParcoordsComponent } from './pages/gfpa/parcoords/parcoords.component';
import { FactorComponent as GfpaFactorComponent } from './pages/gfpa/factor/factor.component';
import { GfrModule } from './pages/gfr/gfr.module';
import { SummaryComponent as GfrSummaryComponent } from './pages/gfr/summary/summary.component';
import { ParcoordsComponent as GfrParcoordsComponent } from './pages/gfr/parcoords/parcoords.component';
import { FactorComponent as GfrFactorComponent } from './pages/gfr/factor/factor.component';
import { DataLoadModule } from './pages/data-load/data-load.module';
import { SummaryComponent as DataLoadSummaryComponent } from './pages/data-load/summary/summary.component';
import { DetailComponent as DataLoadDetailComponent } from './pages/data-load/detail/detail.component';
import { MenuModule } from './pages/menu/menu.module';
import { SummaryPivotComponent } from './pages/rv/summary-pivot/summary-pivot.component';
import { SummaryTabComponent as FofSummaryTabComponent } from './pages/fof/summary-tab/summary-tab.component';
import { FofModule } from './pages/fof/fof.module';
import { SummaryComponent as CashFlowSummaryComponent } from './pages/cash-flow/summary/summary.component';
import { CashFlowModule } from './pages/cash-flow/cash-flow.module';
import { SecurityLookupComponent } from './pages/security-lookup-master/security-lookup/security-lookup.component';
import { SecurityLookupModule } from './pages/security-lookup-master/security-lookup-master.module';
import { ReportRunnerModule } from './pages/report-runner/report-runner.module';
import { TtyaxiomRunnerComponent } from './pages/report-runner/ttyaxiom-runner/ttyaxiom-runner.component';

const routes: Routes = [
  {
    path: 'report-library',
    component: ReportConfigComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'rv-summary',
    component: SummaryComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'rv-group-summary',
    component: SummaryPivotComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'rv-in-depth',
    component: SummaryInDepthComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'rv-detail/:reportId/:date/:seriesName',
    component: DetailComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'gfpa-summary',
    component: GfpaSummaryComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'gfpa-detail/:reportId/:date/:seriesName',
    component: GfpaDetailComponent,
    canActivate: [AuthGuardService]
  },
  /* 2023-10-25 james: Parallel coordinates not working after latest Angular upgraded
  {
    path: 'gfpa-parcoords/:reportId/:date/:seriesName',
    component: GfpaParcoordsComponent,
    canActivate: [AuthGuardService]
  },
  */
  {
    path: 'gfpa-factor/:reportId/:date',
    component: GfpaFactorComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'gfr-summary',
    component: GfrSummaryComponent,
    canActivate: [AuthGuardService]
  },
  /* 2023-10-25 james: Parallel coordinates not working after latest Angular upgraded
  {
    path: 'gfr-parcoords/:reportId/:date/:seriesName',
    component: GfrParcoordsComponent,
    canActivate: [AuthGuardService]
  },
  */
  {
    path: 'gfr-factor/:reportId/:date',
    component: GfrFactorComponent,
    canActivate: [AuthGuardService]
  },/*
  {
    path: 'gfr-detail/:id/:date/:seriesName',
    component: GfrDetailComponent,
    canActivate: [AuthGuardService]
  },*/
  {
    path: 'fof-summary',
    component: FofSummaryTabComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'data-load-summary/:beginDate/:endDate',
    component: DataLoadSummaryComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'data-load-detail/:beginDate/:endDate',
    component: DataLoadDetailComponent,
    canActivate: [AuthGuardService]
  },
  /* poc cash flow summary disabled
  {
    path: 'cash-flow-summary',
    component: CashFlowSummaryComponent,
    canActivate: [AuthGuardService]
  },
  */
  /*
  {
    path: 'menu-detail',
    component: MenuDetailComponent,
    canActivate: [AuthGuardService]
  },
  */
  {
    path: 'security-lookup',
    component: SecurityLookupComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'report-runner',
    component: TtyaxiomRunnerComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: '**',
    redirectTo: 'report-library'
  }
];

/*
let appInfoObj: AppConfigService;
export function getToken(): string {
    return appInfoObj.getItem('jwt');
}
*/

@NgModule({
  declarations: [
    ReportConfigComponent
  ],
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    HttpClientModule,
    /*
    JwtModule.forRoot({
        config: {
            tokenGetter: getToken
        }
    }),
    */
    DxDataGridModule,
    DxFormModule,
    //DxChartModule,
    DxSelectBoxModule,
    //DxTreeMapModule,
    AxGridModule,
    RvModule,
    GfpaModule,
    GfrModule,
    FofModule,
    DataLoadModule,
    CashFlowModule,
    MenuModule,
    SecurityLookupModule,
    ReportRunnerModule
  ],
  providers: [
    AuthGuardService,
    ReportConfigService,
    AppFormatService
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
  /*
  constructor(private appInfo: AppConfigService) {
      appInfoObj = this.appInfo;
  }
  */
}
