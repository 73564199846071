<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#lookup' }"
    [(visible)]="this.securityLookupService.loading" [showIndicator]="true" [showPane]="true" [shading]="true"
    [hideOnOutsideClick]="false">
</dx-load-panel>
<div class="container content-block" id="lookup">
    <div class="row">
        <div class="col-sm-3">
            <h6>Security Lookup</h6>
        </div>
        <div class="col-sm-3">
            <div class="dx-field">
                <div class="dx-field-label">Security ID</div>
                <div class="dx-field-value">
                    <dx-text-box [value]="cusip" (onValueChanged)="handleCusipChange($event)">
                    </dx-text-box>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="dx-field">
                <div class="dx-field-label">Date</div>
                <div class="dx-field-value">
                    <dx-date-box [value]="date" type="date" (onValueChanged)="handleDateChange($event)">
                    </dx-date-box>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <dx-button stylingMode="contained" type="normal" text="Look up" [width]="120" [height]="30"
                (click)="securityLookup()"></dx-button>
        </div>
    </div>

    <br />

    <div class="stats">
        <div>
            <div class="sub-title">Security ID</div>
            <div class="stat-value">{{cusip}}</div>
        </div>
        <div>
            <div class="sub-title">Asset Type</div>
            <div class="stat-value">{{cusip_type}}</div>
        </div>
        <div>
            <div class="sub-title">Pricing Date</div>
            <div class="stat-value">{{date | date:'shortDate'}}</div>
        </div>
    </div>
    <dx-multi-view #multiview [selectedIndex]="this.selectedIndex" [loop]="false" [animationEnabled]="false">
        <dxi-item>
            <app-cds></app-cds>
        </dxi-item>
        <dxi-item>
            <app-bond></app-bond>
        </dxi-item>
    </dx-multi-view>
</div>