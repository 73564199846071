<h6 class="content-block">Global Factor Performance Attribution</h6>
<p>
  Report ID: <strong>{{this.objId}}</strong>
  <span *ngIf="isActive; else notActive">
    &nbsp;&nbsp;&nbsp; <strong>Active</strong>
    &nbsp;&nbsp;&nbsp; {{this.rptParam.PORTFOLIO}}: <strong>{{this.summary.portfolioName}}</strong>
    &nbsp;&nbsp;&nbsp; {{this.rptParam.BENCHMARK}}: <strong>{{this.summary.benchmarkName}}</strong>
  </span>
  <!--&nbsp;&nbsp;&nbsp;Date: <strong>{{this.analysisDate | date: 'shortDate'}}</strong>-->
</p>
<ng-template #notActive>
  <span>&nbsp;&nbsp;&nbsp;{{this.objName}}: <strong>{{this.objValue}}</strong></span>
</ng-template>
<div>
  <div class="ax-panel">
    <app-tree-map (onDrill)="drill()">
    </app-tree-map>
  </div>
  <div class="ax-panel">
    <app-grid name="Global Factor Performance Attribution Detail"
              [height]="500">
    </app-grid>
  </div>
  <!--
  <dx-data-grid id="gridContainer"
                [dataSource]="dataSource"
                [showBorders]="true"
                [remoteOperations]="true"
                [columns]="columns">
    <dxi-column dataField="report_id"
                dataType="number">
    </dxi-column>
    <dxi-column dataField="begin_date"
                dataType="date">
    </dxi-column>
    <dxi-column dataField="axsid"
                dataType="string">
    </dxi-column>
    <dxi-column dataField="security_id"
                dataType="string">
    </dxi-column>
    <dxi-column dataField="holdings"
                dataType="string">
    </dxi-column>
    <dxi-column dataField="security_ret"
                dataType="number">
    </dxi-column>
    <dxo-paging [pageSize]="12"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[8, 12, 20]"></dxo-pager>
  </dx-data-grid>
  -->
</div>
