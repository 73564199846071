import { Component, ViewChild, ViewChildren, AfterViewInit, QueryList } from '@angular/core';
import { DxDateBoxComponent, DxButtonComponent } from "devextreme-angular";
import { AxGridComponent, AxChartComponent, SeriesData } from '../../../shared/components';
import { SummaryService, Tab } from './summary.service';
import { AppFormatService } from '../../../shared/services';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements AfterViewInit {
  @ViewChildren(DxDateBoxComponent) dates!: QueryList<DxDateBoxComponent>;
  @ViewChild(AxChartComponent) chart!: AxChartComponent;
  @ViewChild(AxGridComponent) grid!: AxGridComponent;

  private _tabs: Tab[];
  get tabs(): Tab[] {
    return this._tabs;
  }

  private _beginDate: Date = new Date();
  get beginDate(): Date {
    return this._beginDate;
  }

  set beginDate(date: Date) {
    this._beginDate = date;
    this.summary.beginDate = date;
  }

  private _endDate: Date = new Date();
  get endDate(): Date {
    return this._endDate;
  }

  set endDate(date: Date) {
    this._endDate = date;
    this.summary.endDate = date;
  }

  private _showTotal: boolean = true;
  get showTotal(): boolean {
    return this._showTotal;
  }

  private _showRejected: boolean = false;
  get showRejected(): boolean {
    return this._showRejected;
  }

  private _showLoaded: boolean = false;
  get showLoaded(): boolean {
    return this._showLoaded;
  }

  constructor(private summary: SummaryService, private appFormat: AppFormatService) {
    this._tabs = summary.getTabs();
    if (this.summary.beginDate) {
      this._beginDate = this.summary.beginDate;
    }
    
    if (this.summary.endDate) {
      this._endDate = this.summary.endDate;
    }
    
  }

  ngAfterViewInit() {
    this.chart.customizeTooltipFunc = (arg) => {
      return {
        text: this.appFormat.GuiInt(arg.value)
      }
    };

    if (this.summary.beginDate) {
      this._beginDate = this.summary.beginDate;
    }
    
    if (this.summary.endDate) {
      this._endDate = this.summary.endDate;
    }
    
    this.generate();
  }

  generateClicked($event:any) {
    this.beginDate = this.dates.first.value as Date;
    this.endDate = this.dates.last.value as Date;
    this.generate();
  }

  selectTab(e:any) {
    this._showTotal = false;
    this._showRejected = false;
    this._showLoaded = false;
    switch (e.itemIndex) {
      case 1:
        this._showRejected = true;
        break;
      case 2:
        this._showLoaded = true;
        break;
      case 0:
      default:
        this._showTotal = true;
        break;
    }

    this.showTotalChart();
    this.showRejectedChart();
    this.showLoadedChart();
  }

  private argumentAxis() {
    this.chart.argumentAxis = {
      argumentType: 'datetime',
      tickInterval: {
        days: 1
      },
      label: {
        format: {
          type: 'shortDate'
        }
      }
    };
  }

  private generate() {
    this.summary.generate(this.beginDate!, this.endDate!).subscribe(() => {
      this.showTotalGrid();
      this.showTotalChart();
      this.showRejectedChart();
      this.showLoadedChart();
    });
  }

  private showTotalGrid() {
    this.grid.columns = [
      {
        dataField: 'fileName',
        caption: 'File Name',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'portfolioName',
        caption: 'Portfolio Name',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'portfolioDate',
        dataType: 'date',
        //width: 150,
        caption: 'Portfolio Date',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'totalCount',
        //width: 90,
        caption: 'Total Count',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'rejectedCount',
        //width: 90,
        caption: 'Rejected Count',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'startTime',
        dataType: 'date',
        format: 'MM/dd/yyyy hh:mm:ss a',
        //width: 250,
        caption: 'Start Time',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'beginTime',
        dataType: 'date',
        format: 'MM/dd/yyyy hh:mm:ss a',
        //width: 250,
        caption: 'File Begin Time',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'endTime',
        dataType: 'date',
        format: 'MM/dd/yyyy hh:mm:ss a',
        //width: 250,
        caption: 'File End Time',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'totalTimeMinutes',
        dataType: 'number',
        //width: 150,
        caption: 'Total Time Minutes',
        format: '#,##0.000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'loadFileId',
        //width: 90,
        caption: 'Load File ID',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'headerId',
        //width: 90,
        caption: 'Header ID',
        headerCellTemplate: 'titleHeaderTemplate'
      }
    ];

    this.grid.showLoadingIndicator();
    // we need to retrieve total grid here if showTotal chart flag is off
    this.summary.retrieveGrid(this.beginDate!, this.endDate!).subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.grid.dataSource = jsonObj;
      })
      .add(() => {
        this.grid.hideLoadingIndicator();
      });
  }

  private showTotalChart() {
    if (!this._showTotal) {
      return;
    }

    this.chart.showLoadingIndicator();
    this.summary.retrieveGrid(this.beginDate!, this.endDate!).subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.chart.series = [
          {
            type: 'line',
            argumentField: 'portfolioDate',
            valueField: 'totalCount',
            name: 'Total Count',
            aggregation: {
              enabled: true,
              method: 'sum'
            }
          },
          {
            type: 'line',
            argumentField: 'portfolioDate',
            valueField: 'rejectedCount',
            name: 'Rejected Count',
            aggregation: {
              enabled: true,
              method: 'sum'
            }
          }
        ];

        this.argumentAxis();
        //this.grid.dataSource = data;
        this.chart.dataSource = jsonObj;
      })
      .add(() => {
        this.chart.hideLoadingIndicator();
      });
  }

  private showRejectedChart() {
    if (!this._showRejected) {
      return;
    }

    this.summary.retrieveRejectedSeries(this.beginDate!, this.endDate!).subscribe(
      sdata => {
        let jsonObj = JSON.parse(sdata);
        let sd: SeriesData[] = jsonObj as SeriesData[];
        this.argumentAxis();
        this.chart.showLoadingIndicator();
        this.summary.retrieveRejected(this.beginDate!, this.endDate!).subscribe(
          rdata => {
            let jsObj = JSON.parse(rdata);
            this.chart.updateSeriesValueFieldFromDataSource(jsObj, sd);
            this.chart.createSeries(sd);
            this.chart.dataSource = jsObj;
          })
          .add(() => {
            this.chart.hideLoadingIndicator();
          });
      });
  }

  private showLoadedChart() {
    if (!this._showLoaded) {
      return;
    }

    this.summary.retrieveLoadedSeries(this.beginDate!, this.endDate!).subscribe(
      sdata => {
        let jsonObj = JSON.parse(sdata);
        let sd: SeriesData[] = jsonObj as SeriesData[];
        this.argumentAxis();
        this.chart.showLoadingIndicator();
        this.summary.retrieveLoaded(this.beginDate!, this.endDate!).subscribe(
          rdata => {
            let jsObj = JSON.parse(rdata);
            this.chart.updateSeriesValueFieldFromDataSource(jsObj, sd);
            this.chart.createSeries(sd);
            this.chart.dataSource = jsObj;
          })
          .add(() => {
            this.chart.hideLoadingIndicator();
          });
      });
  }
}


