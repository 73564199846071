import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { AuthService } from '../../../shared/services';
import { AppUtilService } from '../../../shared/services';
import { faL } from '@fortawesome/free-solid-svg-icons';

/* Models */

export interface IDropdownValues {
    sector: string | null;
    pmtBasis: string | null;
    pmtFreq: string | null;
    sandp: string;
    moody: string;
}

export interface IDropdownMaps {
    sectorDescName: Map<string, string>;
    sectorNameDesc: Map<string, string>;
    pmtFreqCodeName: Map<number, string>;
    pmtFreqNameCode: Map<string, number>;
    pmtBasisCodeName: Map<number, string>;
    pmtBasisNameCode: Map<string, number>;
}

export interface IDropdownCodes {
    sectorNames: string[];
    sectorDesc: string[];
    moodyQualities: string[];
    sandpQualities: string[];
    pmtBasisNames: string[];
    pmtBasisCodes: number[];
    pmtFreqNames: string[];
    pmtFreqCodes: number[];
}

export interface IIncomingParams {
    securityModel: ISecurityModel;
    dropdownCodes: IDropdownCodes;
}

export interface ISecurityModel {
    cusip_type: number;
    description: string | null;
    coupon: number;
    maturity: number | null;
    recovery_rate: number;
    sector: string | null;
    sector2: string;
    moody: string;
    sandp: string;
    default_date: number | null;
    dated_date: number | null;
    first_coupdate: number | null;
    pmt_freq: number | null;
    pmt_basis: number | null; // double check if string or number
    recovery_amount: number;
    contract_size: number;
    country: number;
    prin_currency: number;
    underlying: string;
    use_reference_data: number;
    price: number;
    price2: number;

    refDescription: string;
    refCoup: string;
    refMaturity: string;
    refSector: string;
    refPrice: string;
    refMoody: string;
    refSandp: string;
}

export interface ISecurityParams {
    date: number;
    cusip: string;
    securityModel: ISecurityModel;
}

@Injectable()
export class SecurityLookupService {
    /* service instanced model objects */

    private _securityModel: ISecurityModel;
    private _securityParams: ISecurityParams;
    private _incomingParams: IIncomingParams;
    private _dropdownMaps: IDropdownMaps;
    private _loading: boolean = false;

    get securityModel(): ISecurityModel {
        return this._securityModel;
    }

    get securityParams(): ISecurityParams {
        return this._securityParams;
    }

    get incomingParams(): IIncomingParams {
        return this._incomingParams;
    }

    /* Getters and setters for
    - cusip
    - date
    - cdsFormModel
    - dropdownCodes
     */

    get loading(): boolean {
        return this._loading;
    }

    set loading(loading: boolean) {
        this._loading = loading;
    }

    get cusip(): string {
        return this.auth.getItem('securityLookupCusip')!;
    }

    set cusip(cusip: string) {
        this.auth.setItem('securityLookupCusip', cusip);
    }

    get date(): Date | null {
        let dateString = this.auth.getItem('securityLookupDate');
        if (dateString) {
            return new Date(dateString);
        } else {
            return null
        }
    }

    set date(date: Date | null) {
        this.auth.setItem('securityLookupDate', date!.toISOString());
    }

    get dropdownCodes() {
        return this._incomingParams.dropdownCodes;
    }

    get dropdownMaps() {
        return this._dropdownMaps;
    }

    constructor(
        private auth: AuthService,
        private appUtilService: AppUtilService
    ) {
        this._securityModel = {} as ISecurityModel;
        this._securityParams = {} as ISecurityParams;

        this._incomingParams = {} as IIncomingParams;
        this._dropdownMaps = {} as IDropdownMaps;

    }

    /* Helper methods to error check values from API */

    public dateToNum(date: Date): number | null {
        if (date == null) return date;
        let day: string = '' + date.getDate();
        let month: string = '' + (date.getMonth() + 1);
        let year: string = '' + date.getFullYear();

        if (day.length < 2) day = '0' + day;
        if (month.length < 2) month = '0' + month;

        return Number(year + month + day);
    }

    public numToDate(dateNum: number): Date | null {
        let dateString: string = dateNum.toString();
        if (dateString.length > 8) return null;

        let date = new Date();
        date.setFullYear(Number(dateString.slice(0, 4)));
        date.setMonth(Number(dateString.slice(4, 6)) - 1);
        date.setDate(Number(dateString.slice(6)));
        return date;
    }

    parseSecurityModel(data: any) {
        this._securityModel = JSON.parse(data) as ISecurityModel;
    }

    /*
    Returns pad value Number.MIN_SAFE_INTEGER if number is pad, otherwise returns number
    */
    public validNum(num: number): number | null {
        if (this.appUtilService.isPad(num) || this.appUtilService.isEmpty(num)) {

            return null;
        }
        return num;
    }

    /* Send and receive data from API */

    getSecurityModel(): Observable<any> {
        let httpParams = new HttpParams()
            .set('date', '' + this.dateToNum(this.date!))
            .set('cusip', '' + this.cusip);

        return this.auth.get('/api/SecurityLookup/GetSecurity', httpParams);
    }

    retrieveSecurityData(): Observable<any> {
        let httpParams = new HttpParams()
            .set('date', '' + this.dateToNum(this.date!))
            .set('cusip', '' + this.cusip);

        return this.auth.get('/api/SecurityLookup/Get', httpParams);
    }
}
