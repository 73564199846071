<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)"
    [(visible)]="this.cdsService.loading" [showIndicator]="true" [showPane]="true" [shading]="true"
    [hideOnOutsideClick]="false">
</dx-load-panel>
<div *ngIf="this.cdsService.showCds" id="cds">
    <div class="ax-panel">
        <dx-form id="form" [formData]="cdsFormModel">
            <dxi-item itemType="group" [colCount]="3">
                <dxi-item itemType="group">
                    <dxi-item dataField="description" [label]="{ text: 'Description' }" ></dxi-item>
                    <dxi-item dataField="sector" [label]="{ text: 'Sector Code' }" editorType="dxSelectBox"
                        [editorOptions]="{ items: cdsService.dropdownCodes.sectorDesc, value: cdsService.dropdownValues.sector }"></dxi-item>
                    <dxi-item dataField="sector2" [label]="{ text: 'Client Sector' }"></dxi-item>
                    <dxi-item dataField="dated_date" [label]="{ text: 'Dated Date' }" editorType="dxDateBox"></dxi-item>
                    <dxi-item dataField="first_coupdate" [label]="{ text: 'First Coupon' }"
                        editorType="dxDateBox"></dxi-item>
                    <dxi-item dataField="underlying" [label]="{ text: 'Reference Security' }"></dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dxi-item dataField="coupon" [label]="{ text: 'Deal Spread (bps)' }"></dxi-item>
                    <dxi-item dataField="moody" [label]="{ text: 'Moody Quality' }" editorType="dxSelectBox"
                        [editorOptions]="{ items: cdsService.dropdownCodes.moodyQualities, value: cdsService.dropdownValues.moody }"></dxi-item>
                    <dxi-item dataField="pmt_freq" [label]="{ text: 'Pmt Frequency' }" editorType="dxSelectBox"
                        [editorOptions]="{ items: cdsService.dropdownCodes.pmtFreqNames, value: cdsService.dropdownValues.pmtFreq }"></dxi-item>
                    <dxi-item dataField="maturity" [label]="{ text: 'Expiration Date' }" editorType="dxDateBox"></dxi-item>
                    <dxi-item dataField="sandp" [label]="{ text: 'S & P Quality' }" editorType="dxSelectBox"
                        [editorOptions]="{ items: cdsService.dropdownCodes.sandpQualities, value: cdsService.dropdownValues.sandp }"></dxi-item>
                    <dxi-item dataField="pmt_basis" [label]="{ text: 'Accrual Basis' }" editorType="dxSelectBox"
                        [editorOptions]="{ items: cdsService.dropdownCodes.pmtBasisNames, value: cdsService.dropdownValues.pmtBasis }"></dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dxi-item dataField="price" [label]="{ text: 'Price in Points' }"></dxi-item>
                    <dxi-item dataField="recovery_rate" [label]="{ text: 'Recovery Rate (def 40%)' }"></dxi-item>
                    <dxi-item dataField="default_date" [label]="{ text: 'Default Date' }" editorType="dxDateBox"></dxi-item>
                    <dxi-item dataField="recovery_amount" [label]="{ text: 'Recovery Amount' }"></dxi-item>
                    <dxi-item dataField="price2" [label]="{ text: 'Price in Spread (bps)' }"></dxi-item>
                    <dxi-item dataField="use_reference_data" [label]="{ text: 'Use Reference Data' }"
                        editorType="dxCheckBox"></dxi-item>
                </dxi-item>
            </dxi-item>
        </dx-form>
    </div>

    <div class="ax-panel">
        <div class="col-sm-3">
            <h6>Reference Data</h6>
        </div>
        <div class="stats-2">
            <div>
                <div class="sub-title"><b>Description</b></div>
                <div class="stat-value">{{ cdsService.securityModel.refDescription }}</div>
            </div>
            <div>
                <div class="sub-title"><b>Deal Spread (bps)</b></div>
                <div class="stat-value">{{ cdsService.securityModel.refCoup }}</div>
            </div>
            <div>
                <div class="sub-title"><b>Maturity</b></div>
                <div class="stat-value">{{ cdsService.securityModel.refMaturity }}</div>
            </div>
            <div>
                <div class="sub-title"><b>Client Sector</b></div>
                <div class="stat-value">{{ cdsService.securityModel.refSector }}</div>
            </div>

        </div>
        <div class="stats-2">
            <div>
                <div class="sub-title"><b>Price in Points</b></div>
                <div class="stat-value">{{ cdsService.securityModel.refPrice }}</div>
            </div>
            <div>
                <div class="sub-title"><b>Moody Quality</b></div>
                <div class="stat-value">{{ cdsService.securityModel.refMoody }}</div>
            </div>
            <div>
                <div class="sub-title"><b>S & P Quality</b></div>
                <div class="stat-value">{{ cdsService.securityModel.refSandp }}</div>
            </div>
        </div>
    </div>


    <div *ngIf="showResults">
        <div class="ax-panel">
            <div class="col-sm-3">
                <h6>Results</h6>
            </div>
            <div class="stats-2">
                <div>
                    <div class="sub-title"><b>YTE</b></div>
                    <div class="stat-value">{{ cdsResults.yte }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>EDUR</b></div>
                    <div class="stat-value">{{ cdsResults.edur }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>ED2</b></div>
                    <div class="stat-value">{{ cdsResults.ed2 }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>ED3</b></div>
                    <div class="stat-value">{{ cdsResults.ed3 }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>SDUR</b></div>
                    <div class="stat-value">{{ cdsResults.spd_edur }}</div>
                </div>
            </div>
            <div class="stats-2">
                <div>
                    <div class="sub-title"><b>YTM</b></div>
                    <div class="stat-value">{{ cdsResults.ytm }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>DTM</b></div>
                    <div class="stat-value">{{ cdsResults.dtm }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>Convexity</b></div>
                    <div class="stat-value">{{ cdsResults.convex }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>Espread</b></div>
                    <div class="stat-value">{{ cdsResults.spread }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>AveLife</b></div>
                    <div class="stat-value">{{ cdsResults.avg_life }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="buttons">
        <dx-button stylingMode="contained" type="normal" text="Calculate" [width]="120" (click)="calculate()"></dx-button>
        <dx-button stylingMode="contained" type="normal" text="Save" [width]="120" (click)="save()"></dx-button>
    </div>
</div>