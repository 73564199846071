import { NgModule, Component, ViewChild, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { DxChartModule, DxChartComponent } from 'devextreme-angular';

@Component({
  selector: 'app-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.scss']
})
export class AxBubbleChartComponent implements AfterViewInit {
  @ViewChild(DxChartComponent) chart!: DxChartComponent;

  @Input() dataSource!: any[];
  @Input() title!: string;
  @Input() series!: any[];
  @Input() legend: any;
  @Input() argumentAxis: any;
  @Input() valueAxis: any;
  @Input() customizeTooltip!: Function;
  @Input() customizePoint!: Function;

  @Output() onLegendClick = new EventEmitter();
  @Output() onSeriesChange = new EventEmitter();

  constructor() { }

  ngAfterViewInit() {
    //this.chart.palette = 'Ocean';
    //this.chart.palette = 'Bright';
  }

  public showLoadingIndicator() {
    this.chart.instance.showLoadingIndicator();
  }

  public hideLoadingIndicator() {
    this.chart.instance.hideLoadingIndicator();
  }

  legendClicked(e: any) {
    let series = this.chart.instance.getSeriesByName(e.target.name);
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }

    this.onLegendClick.emit(e);
    this.onSeriesChange.emit(this.chart.instance.getAllSeries());
  }
}


@NgModule({
  declarations: [AxBubbleChartComponent],
  imports: [
    DxChartModule
  ],
  exports: [AxBubbleChartComponent]
})
export class AxBubbleChartModule { }
