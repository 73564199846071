import { Injectable, EventEmitter, InjectionToken, Inject } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { fromEvent, Observable, Subscription, Subject } from "rxjs";

export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
  providedIn: 'root',
  factory: () => localStorage
});


@Injectable({
  providedIn: 'root'
})

export class BrowserService {
  private _resizeSubject: Subject<Window>;
  public get onResize$(): Observable<Window> {
    return this._resizeSubject.asObservable();
  }
  private resizeObservable$: Observable<Event>;
  private resizeSubscription$: Subscription;

  constructor(
    eventManager: EventManager,
    @Inject(BROWSER_STORAGE) private storage: Storage
  ) {
    this._resizeSubject = new Subject();

    /* 2023-10-19 james: After Angular upgraded, windows resize evt code was replaced with the new code above
    eventManager.addGlobalEventListener('window', 'resize', this.onResize.bind(this));
    */

    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      this._resizeSubject.next(<Window>evt.target);
    })
  }

  public getItem(itemName: string): string | null {
    return this.storage.getItem(itemName);
  }

  public setItem(itemName: string, itemValue: string) {
    this.storage.setItem(itemName, itemValue);
  }

  public removeItem(itemName: string) {
    this.storage.removeItem(itemName);
  }

  /* 2023-10-19 james: After Angular upgraded, windows resize evt code was replaced with the new code above
  private onResize(event: UIEvent) {
    this._resizeSubject.next(<Window>event.target);
  }
  */
}
