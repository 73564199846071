<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="this.bondService.loading" [showIndicator]="true"
    [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>

<div *ngIf="this.bondService.showBond" id="bond">
    <div class="ax-panel">
        <dx-form id="form" [formData]="bondFormModel">
            <dxi-item itemType="group" cssClass="second-group" [colCount]="3">
                <dxi-item itemType="group">
                    <dxi-item dataField="description" [label]="{ text: 'Description' }"></dxi-item>
                    <dxi-item dataField="sector" [label]="{ text: 'Sector Code (dropdown)' }" editorType="dxSelectBox"
                        [editorOptions]="{ items: bondService.dropdownCodes.sectorDesc, value: bondService.dropdownValues.sector }"></dxi-item>
                    <dxi-item dataField="dated_date" [label]="{ text: 'Dated Date' }" editorType="dxDateBox"></dxi-item>
                    <dxi-item dataField="first_coupdate" [label]="{ text: 'First Coupon' }"
                        editorType="dxDateBox"></dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dxi-item dataField="moody" [label]="{ text: 'Moody Quality (dropdown)' }" editorType="dxSelectBox"
                        [editorOptions]="{ items: bondService.dropdownCodes.moodyQualities, value: bondService.dropdownValues.moody }"></dxi-item>
                    <dxi-item dataField="pmt_freq" [label]="{ text: 'Pmt Frequency (dropdown)' }"
                        editorType="dxSelectBox"
                        [editorOptions]="{ items: bondService.dropdownCodes.pmtFreqNames, value: bondService.dropdownValues.pmtFreq }"></dxi-item>
                    <dxi-item dataField="maturity" [label]="{ text: 'Maturity Date' }"
                        editorType="dxDateBox"></dxi-item>
                    <dxi-item dataField="sandp" [label]="{ text: 'S & P Quality (dropdown)' }" editorType="dxSelectBox"
                        [editorOptions]="{ items: bondService.dropdownCodes.sandpQualities, value: bondService.dropdownValues.sandp }"></dxi-item>
                </dxi-item>
                <dxi-item itemType="group">
                    <dxi-item dataField="coupon" [label]="{ text: 'Coupon' }"></dxi-item>
                    <dxi-item dataField="pmt_basis" [label]="{ text: 'Accrual Basis (dropdown)' }"
                        editorType="dxSelectBox"
                        [editorOptions]="{ items: bondService.dropdownCodes.pmtBasisNames, value: bondService.dropdownValues.pmtBasis }"></dxi-item>
                    <dxi-item dataField="price" [label]="{ text: 'Market Price' }"></dxi-item>
                </dxi-item>
            </dxi-item>
        </dx-form>
    </div>

    <div *ngIf="this.bondService.showResults">
        <div class="ax-panel">
            <div class="col-sm-3">
                <h6>Results</h6>
            </div>
            <div class="stats-2">
                <div>
                    <div class="sub-title"><b>YTE</b></div>
                    <div class="stat-value">{{ this.bondService.bondResults.yte }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>EDUR</b></div>
                    <div class="stat-value">{{ this.bondService.bondResults.edur }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>ED2</b></div>
                    <div class="stat-value">{{ this.bondService.bondResults.ed2 }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>ED3</b></div>
                    <div class="stat-value">{{ this.bondService.bondResults.ed3 }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>SDUR</b></div>
                    <div class="stat-value">{{ this.bondService.bondResults.spd_edur }}</div>
                </div>
            </div>
            <div class="stats-2">
                <div>
                    <div class="sub-title"><b>YTM</b></div>
                    <div class="stat-value">{{ this.bondService.bondResults.ytm }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>DTM</b></div>
                    <div class="stat-value">{{ this.bondService.bondResults.dtm }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>Convexity</b></div>
                    <div class="stat-value">{{ this.bondService.bondResults.convex }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>Espread</b></div>
                    <div class="stat-value">{{ this.bondService.bondResults.spread }}</div>
                </div>
                <div>
                    <div class="sub-title"><b>AveLife</b></div>
                    <div class="stat-value">{{ this.bondService.bondResults.avg_life }}</div>
                </div>
            </div>
        </div>
    </div>

    <dx-button stylingMode="contained" type="normal" text="Calculate" [width]="120" (click)="calculate()"></dx-button>
        <dx-button stylingMode="contained" type="normal" text="Save" [width]="120" (click)="save()"></dx-button>
</div>