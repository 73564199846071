import { HistoryItem } from './history-item';
import DataSource from 'devextreme/data/data_source';

export const FACTOR_NAME_TIME_SERIES: string = 'FACTOR_NAME_TIME_SERIES';
export const FACTOR_SECURITY_TIME_SERIES: string = 'FACTOR_SECURITY_TIME_SERIES';

export class HistoryTab {
  id!: number;
  name!: string;
  reportId!: number;
  historyItems!: HistoryItem[];
  dataSource!: DataSource;
  series!: any[];
}
