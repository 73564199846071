<div class="container content-block">
  <div class="row">
    <div class="col-sm-3"><h6>Data Load Status</h6></div>
    <div class="col-sm-3">
      <div class="dx-field">
        <div class="dx-field-label">Begin Date</div>
        <div class="dx-field-value">
          <dx-date-box [value]="beginDate"
                       type="date">
          </dx-date-box>
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="dx-field">
        <div class="dx-field-label">End Date</div>
        <div class="dx-field-value">
          <dx-date-box [value]="endDate"
                       type="date">
          </dx-date-box>
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <dx-button stylingMode="contained"
                 type="normal"
                 text="Generate"
                 width="120"
                 (onClick)="generateClicked($event)"></dx-button>
    </div>
  </div>
</div>
  <!--
  <h2 class="content-block">Data Load Status
  <div class="dx-field">
    <div class="dx-field-label">Begin Date</div>
    <div class="dx-field-value">
      <dx-date-box [value]="now"
                   type="date">
      </dx-date-box>
    </div>
  </div>
  </h2>

  <p>Begin Date: {{this.beginDate | date:'shortDate'}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; End Date: {{this.endDate | date:'shortDate'}}</p>
  -->

  <div>
    <dx-tabs #apiTabs
             [dataSource]="tabs"
             [selectedIndex]="0"
             (onItemClick)="selectTab($event)">
    </dx-tabs>
    <app-chart #totalChart *ngIf="showTotal"></app-chart>
    <app-chart #rejectedChart *ngIf="showRejected"></app-chart>
    <app-chart #loadedChart *ngIf="showLoaded"></app-chart>
    <app-grid name="Data Load Status Summary"></app-grid>
  </div>
