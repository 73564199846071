import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService, AuthService } from '../../shared/services';

@Injectable({
    providedIn: 'root'
})

export class ReportConfigService {
    constructor(private auth: AuthService) { }

    retrieveGrid(): Observable<any> {
        return this.auth.get('/api/ReportLibrary/Get');
    }
}
