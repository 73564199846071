import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryTabComponent } from './summary-tab/summary-tab.component';
import { SummaryService } from './summary-tab/summary.service';
import { DxTabPanelModule } from 'devextreme-angular';
import { AxPivotGridModule } from '../../shared/components/pivot-grid/pivot-grid.component';

@NgModule({
  declarations: [
    //SummaryPivotComponent,
    SummaryTabComponent],
  imports: [
    CommonModule,
    DxTabPanelModule,
    AxPivotGridModule
  ],
  providers: [
    SummaryService
  ]
})
export class FofModule { }
