<dx-chart id="chart"
          [animation]="false"
          [dataSource]="dataSource"
          [title]="title"
          [series]="series"
          [legend]="legend"
          [argumentAxis]="argumentAxis"
          [valueAxis]="valueAxis"
          palette="Bright"
          [customizePoint]="customizePoint"
          (onLegendClick)="legendClicked($event)">
  <dxo-common-series-settings type="bubble"></dxo-common-series-settings>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-legend position="inside"
              horizontalAlignment="left">
    <dxo-border [visible]="true"></dxo-border>
  </dxo-legend>
  <dxo-tooltip [enabled]="true"
               location="edge"
               [customizeTooltip]="customizeTooltip">
  </dxo-tooltip>
</dx-chart>
