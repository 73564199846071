import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryComponent } from './summary/summary.component';
import { DetailComponent } from './detail/detail.component';
import { SummaryService } from './summary/summary.service';
import { DetailService } from './detail/detail.service';
import { AxGridModule, AxTreeMapModule, AxChartModule, AxBubbleChartModule } from '../../shared/components';
import { SummaryInDepthComponent } from './summary-in-depth/summary-in-depth.component';
import { SummaryInDepthService } from 'src/app/pages/rv/summary-in-depth/summary-in-depth.service';
import { DxSliderModule, DxButtonModule, DxTemplateModule, DxPopupModule, DxDataGridModule, DxTooltipModule, DxTabsModule } from 'devextreme-angular';
import { AxPivotGridModule } from 'src/app/shared/components/pivot-grid/pivot-grid.component';
import { SummaryPivotComponent } from './summary-pivot/summary-pivot.component';
import { SummaryPivotService } from './summary-pivot/summary-pivot.service';


@NgModule({
  declarations: [
    SummaryComponent,
    DetailComponent,
    SummaryInDepthComponent,
    SummaryPivotComponent
  ],
  imports: [
    CommonModule,
    AxGridModule,
    AxTreeMapModule,
    AxChartModule,
    AxPivotGridModule,
    AxBubbleChartModule,
    DxSliderModule,
    DxButtonModule,
    DxTemplateModule,
    DxPopupModule,
    DxTooltipModule,
    DxTabsModule
  ],
  providers: [
    SummaryService,
    DetailService,
    SummaryInDepthService
  ]
})

export class RvModule { }

/* heatmap testing 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryComponent } from './summary/summary.component';
import { DetailComponent } from './detail/detail.component';
import { SummaryService } from './summary/summary.service';
import { DetailService } from './detail/detail.service';
import { AxGridModule, AxTreeMapModule, AxChartModule, AxBubbleChartModule } from '../../shared/components';
import { SummaryInDepthComponent } from './summary-in-depth/summary-in-depth.component';
import { SummaryInDepthService } from 'src/app/pages/rv/summary-in-depth/summary-in-depth.service';
import { DxSliderModule, DxButtonModule } from 'devextreme-angular';
import { AxHeatmapModule } from 'src/app/shared/components/heatmap/heatmap.component';


@NgModule({
  declarations: [
    SummaryComponent,
    DetailComponent,
    SummaryInDepthComponent
  ],
  imports: [
    CommonModule,
    AxGridModule,
    AxHeatmapModule,
    AxTreeMapModule,
    AxChartModule,
    AxBubbleChartModule,
    DxSliderModule,
    DxButtonModule
  ],
  providers: [ SummaryService, DetailService, SummaryInDepthService]
})

export class RvModule { }
*/
