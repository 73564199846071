import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { AxPivotGridComponent, ChartLocation } from 'src/app/shared/components/pivot-grid/pivot-grid.component';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { SummaryPivotService, Tab } from './summary-pivot.service';
import { SummaryService } from '../summary/summary.service';
import { NGXLogger } from 'ngx-logger';
import { AxGridComponent } from '../../../shared/components';
import { DetailService } from '../detail/detail.service';

@Component({
  selector: 'app-summary-pivot',
  templateUrl: './summary-pivot.component.html',
  styleUrls: ['./summary-pivot.component.scss'],
  providers: [SummaryPivotService]
})
export class SummaryPivotComponent implements AfterViewInit {
  @ViewChild(AxPivotGridComponent) axPivotGrid!: AxPivotGridComponent;
  @ViewChild(AxGridComponent) drillDownDataGrid!: AxGridComponent;

  private initExpanded = true;
  private initPopup = true;
  private currDataSet!: string;
  private currRowPathArray!: string[];
  private currDataField!: string | undefined;

  drillDownDataSource: any;
  drillDownVisible = false;
  drillDownTitle = '';
  dataSource!: PivotGridDataSource;
  tabsIndex = 0;
  toolTipVisible = false;

  get tabs(): Tab[] {
    return this.summaryPivot.getTabs();
  }

  public get chartLocation() {
    return ChartLocation.Up;
  }

  constructor(
    public summary: SummaryService,
    private detail: DetailService,
    private summaryPivot: SummaryPivotService,
    private logger: NGXLogger
  ) {
  }

  ngAfterViewInit() {
    this.axPivotGrid.showLoadingIndicator();
    this.axPivotGrid.pivotGrid.fieldChooser.enabled = false;
    this.logger.debug('rv group summary loading starts at ' + new Date().toUTCString());
    var start = window.performance.now();
    this.summaryPivot.retrieve()
      .subscribe(
        data => {
          let jsonObj = JSON.parse(data);
          this.summaryPivot.build(jsonObj);
          this.dataSource = this.summaryPivot.pivotDataSource;
         
        })
      .add(() => {
        this.axPivotGrid.hideLoadingIndicator();
        this.logger.debug('rv group summary ends at ' + new Date().toUTCString());
        var end = window.performance.now();
        this.logger.debug(`Execution time: ${end - start} ms`);
      });
  }

  toggleToolTipVisible() {
    this.toolTipVisible = !this.toolTipVisible;
  }

  onCellPrepared(evt:any) {
    if (evt.area == 'row') {
      if (!this.summaryPivot.isGroupRowPath(evt.cell.path)) {
        // set cursor to default and remove expand button
        let parent = evt.cellElement;
        parent.style.cursor = 'default';
        let children:any[] = parent.querySelectorAll('.dx-expand-icon-container');
        children.forEach(e => e.parentNode.removeChild(e));
      }  
    }

    if (evt.area == 'data') {
      if (this.summaryPivot.getDataField(this.summaryPivot.getPath(this.summaryPivot.removeDateFromColPath(evt.cell.columnPath)))) {
        evt.cellElement.style.cursor = 'pointer';
      }
    }
  }

  onCellClick(evt:any) {
    if (evt.area == 'row') {
      if (!this.summaryPivot.isGroupRowPath(evt.cell.path)) {
        // disable click
        evt.cancel = true;
      }
    }

    if (evt.area == "data") {
      let rowPath = evt.cell.rowPath;
      let colPath = evt.cell.columnPath;
      this.currDataField = this.summaryPivot.getDataField(this.summaryPivot.getPath(this.summaryPivot.removeDateFromColPath(colPath)));
      this.currRowPathArray = this.summaryPivot.removeRowidFromPath(rowPath);
      this.currDataSet = this.summaryPivot.seriesNameByColumn(this.currDataField!);
      this.tabsIndex = 0;
      this.drillDownDataSource = null;

      if (this.currDataField) {
        let rowTitle = this.summaryPivot.getDrillDownTitle(rowPath);
        let colTitle = this.summaryPivot.getDrillDownTitle(colPath);
        this.drillDownTitle = `${rowTitle} : ${colTitle}`;
        this.drillDownVisible = true;
      }
    }
  }

  onPopupClose() {
    this.drillDownVisible = false;
    this.toolTipVisible = false;
  }

  onPopupResize(evt:any) {
    let height = evt.actionValue[0].height - 150;
    this.drillDownDataGrid.height = height;
  }

  onPopupShown(evt:any) {
    if (this.initPopup) {
      this.initPopup = false;
    }

    this.drillDownDataGrid.updateDimensions();
    this.showSecurityDetailGrid();
  }

  onContentReady(evt:any) {
    let ds = this.axPivotGrid.pivotGrid.instance.getDataSource();
    let data = ds.getData();
    if (this.initExpanded) {
      if (data != null
        && data.columns != null
        && data.columns.length > 0) {
        this.initExpanded = false;
        ds.expandHeaderItem('column', this.summaryPivot.expandedColumnPath)
      }
    }
  }

  onSelectTab(evt:any) {
    switch (evt.itemIndex) {
      case 1:
        this.showValueGrid();
        break;
      case 0:
      default:
        this.showSecurityDetailGrid();
        break;
    }
  }

  private showSecurityDetailGrid() {
    this.drillDownDataGrid.columns = [
      {
        dataField: 'description',
        caption: 'Description',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'securityId',
        caption: 'Security',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'dataSet',
        caption: 'Data Set',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'investWeight',
        dataType: 'number',
        caption: 'Invest Weight',
        format: '#,##0.00000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'coupon',
        dataType: 'number',
        caption: 'Coupon Rate',
        format: '#,##0.00000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'maturityDate',
        dataType: 'date',
        caption: 'Maturity',
        format: 'MM/dd/yyyy',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'price',
        dataType: 'number',
        caption: 'Price',
        format: '#,##0.00000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'edur',
        dataType: 'number',
        caption: 'Eff Dur',
        format: '#,##0.00000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'yte',
        dataType: 'number',
        caption: 'Csh flw Yield',
        format: '#,##0.00000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'baseReturn',
        dataType: 'number',
        caption: 'Return',
        format: '#,##0.00000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'localReturn',
        dataType: 'number',
        caption: 'Local Ret',
        format: '#,##0.00000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'contribution',
        dataType: 'number',
        caption: 'Ret Contribution',
        format: '#,##0.00000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'allocationHigh',
        dataType: 'number',
        caption: 'Alloc High',
        format: '#,##0.00000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'allocationLow',
        dataType: 'number',
        caption: 'Alloc Low',
        format: '#,##0.00000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'selectionEffect',
        dataType: 'number',
        caption: 'Sel',
        format: '#,##0.00000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'fundResidual',
        dataType: 'number',
        caption: 'Fund Res',
        format: '#,##0.00000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'currencyReturn',
        dataType: 'number',
        caption: 'Currency Ret',
        format: '#,##0.00000',
        headerCellTemplate: 'titleHeaderTemplate'
      }
    ];

    this.detail.retrieveByGroup(this.currDataSet, this.currRowPathArray).subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.drillDownDataSource = jsonObj;
      });
  }

  private showValueGrid() {
    this.drillDownDataGrid.columns = [
      {
        dataField: 'date',
        dataType: 'date',
        caption: 'Date',
        format: 'MM/dd/yyyy',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'value',
        dataType: 'number',
        caption: 'Value',
        format: '#,##0.00000',
        headerCellTemplate: 'titleHeaderTemplate'
      }
    ];

    this.summaryPivot.retrieveDrillDown(this.currRowPathArray, this.currDataField!).subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.drillDownDataSource = jsonObj;
      });
  }
}
