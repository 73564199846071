import { Component, AfterViewInit, Input, EventEmitter, Output, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ax-drill-down-bar',
  templateUrl: './drill-down-bar.component.html',
  styleUrls: ['./drill-down-bar.component.scss']
})
export class AxDrillDownBarComponent implements AfterViewInit {

  @Input() root: string = '';
  @Input() currentPath: string[] = [];
  @Input() path: string[] = [];

  @Output() onDrill = new EventEmitter();

  constructor() { }

  ngAfterViewInit() {
    this.path = [];
    this.currentPath = [];
  }

  drillUpClick(name:any) {
    if (name) {
      let ii = this.path.indexOf(name);
      if (ii >= 0) {
        this.path.splice(ii, this.path.length - ii, name);
        this.currentPath = this.path.slice();
        this.onDrill.emit();
      }
    } else {
      this.path = [];
      this.currentPath = [];
      this.onDrill.emit();
    }
  }
}

@NgModule({
  declarations: [AxDrillDownBarComponent],
  imports: [
    CommonModule
  ],
  exports: [AxDrillDownBarComponent]
})

export class AxDrillDownBarpModule { }
