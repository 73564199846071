import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services';
import { DownloadFileService } from 'src/app/shared/services/download-file.service';

export class TtyaxiomParam {
  rspFile: string = '';
}


@Injectable({
  providedIn: 'root'
})
export class TtyaxiomRunnerService {

  private _loading: boolean = false;
  private _ttyaxiomParam: TtyaxiomParam = {
    rspFile: ''
  }

  public get ttyaxiomParam() : TtyaxiomParam {
    return this._ttyaxiomParam;
  }

  public set ttyaxiomParam(param: TtyaxiomParam) {
    this._ttyaxiomParam = param;
  }

  public get loading(): boolean {
    return this._loading;
  }

  public set loading(ld: boolean) {
    this._loading = ld;
  }

  constructor(
    private auth: AuthService,
    private downloadSvr: DownloadFileService,
  ) { }

  public run(): Observable<any> {
    let httpParams = new HttpParams()
      .set('rspFile', '' + this._ttyaxiomParam.rspFile);

    return this.auth.get('/api/Ttyaxiom/Run', httpParams);
  }

  public download(reportFile: string) {
    this.downloadSvr.download('/api/Ttyaxiom/DownloadReport', reportFile);
  }

  public retrieveDownloadList() {
    return this.auth.get('/api/Ttyaxiom/ReportDownloadList');
  }
}

export class FilePropData {
  name: string = '';
  lastModified: Date = new Date()
}
