import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AxParcoordsComponent } from '../../../shared/components';
import { SummaryService } from '../summary/summary.service';
import { DetailService } from '../detail/detail.service';
import { ParcoordsService } from './parcoords.service';
import { RptParamService } from 'src/app/shared/services';
import { AxParcoordsService } from 'src/app/shared/components/parcoords/parcoords.service';
import { ListItem } from 'src/app/shared/utils';

@Component({
  selector: 'gfr-parcoords',
  templateUrl: './parcoords.component.html',
  styleUrls: ['./parcoords.component.scss'],
  providers: [AxParcoordsService]
})

export class ParcoordsComponent implements AfterViewInit {
  @ViewChild(AxParcoordsComponent) parcoords!: AxParcoordsComponent;

  objId: any;
  objName: string;
  objValue: string;
  isActive: boolean;
  popupVisible!: boolean;

  public analysisDate: Date;
  private seriesName: any;
  private _data: any;
  private _initStrSelCols: string[];

  constructor(
    private parccordsService: ParcoordsService,
    private route: ActivatedRoute,
    public summary: SummaryService,
    private detail: DetailService,
    public rptParam: RptParamService,
    public axParService: AxParcoordsService
  ) {
    // default dimensions
    this._initStrSelCols = [
      'Security',
      'Holdings',
      'Market Value',
      'Notional Weight',
      'Security Risk Contrib',
      'Currency Risk Contrib'
    ];

    this.objId = this.route.snapshot.paramMap.get('reportId');
    this.seriesName = this.route.snapshot.paramMap.get('seriesName');
    let dateStr = this.route.snapshot.paramMap.get('date');

    if (dateStr == '0') {
      this.analysisDate = this.summary.analysisDate;
    } else {
      this.analysisDate = new Date(dateStr!);
    }

    if (this.seriesName == '0') {
      this.seriesName = this.summary.seriesName;
    }

    this.isActive = false;
    this.objId = this.summary.reportId + '';
    this.objName = '';
    this.objValue = '';
    if (this.seriesName == this.rptParam.PORTFOLIO) {
      this.objName = this.rptParam.PORTFOLIO;
      this.objValue = this.summary.portfolioName;
    } else if (this.seriesName == this.rptParam.BENCHMARK) {
      this.objName = this.rptParam.BENCHMARK;
      this.objValue = this.summary.benchmarkName;
    } else if (this.seriesName == this.rptParam.ACTIVE) {
      this.isActive = true;
    }
  }

  ngAfterViewInit() {
    this.popupVisible = false;
    this.getAvailSelLists();

    //this.parcoords.hideColumns = ['begin_date', 'end_date'];
    this.parcoords.showLoadingIndicator();
    this.parccordsService.retrieve(+this.objId, this.analysisDate, this.seriesName).subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        if (jsonObj && Array.isArray(jsonObj) && jsonObj.length > 0) {
          this._data = jsonObj;
          this.parcoords.dataSource = this._data;
        }
      })
      .add(() => {
        this.parcoords.hideLoadingIndicator();
      });
  }

  resetBrushClicked($event:any) {
    this.parcoords.brushReset();
  }

  selectColumnsClicked($event:any) {
    this.popupVisible = true;
  }

  onDragStart(e:any) {
    e.itemData = e.fromData[e.fromIndex];
  }

  onAdd(e:any) {
    e.toData.splice(e.toIndex, 0, e.itemData);
  }

  onRemove(e:any) {
    e.fromData.splice(e.fromIndex, 1);
  }

  popup_hidden(e:any) {
    this.axParService.setDimensions(this.rptParam.GFR, this.parcoords, this._data);
  }

  private getAvailSelLists() {
    this.detail.retrieveGroupGridColumns().subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.axParService.getAvailSelLists(this.rptParam.GFR, this._initStrSelCols, jsonObj);
        this.axParService.setDimensions(this.rptParam.GFR, this.parcoords, this._data);
      });
  }
}
