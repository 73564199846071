<div class="user-panel">
  <div class="user-info">
    <!--
    <div class="image-container">
      <div class="user-image">
      </div>
    </div>
    -->
    <i class="dx-icon-user"></i>
    <div class="user-name">{{authService.userId}}</div>
  </div>
  <dx-context-menu *ngIf="menuMode === 'context'"
                   [items]="menuItems"
                   target=".user-button"
                   showEvent="dxclick"
                   width="170px"
                   [position]="{ my: 'top', at: 'bottom' }"
                   cssClass="user-menu">
  </dx-context-menu>
  <dx-list *ngIf="menuMode === 'list'"
           class="dx-toolbar-menu-action"
           [items]="menuItems">
  </dx-list>
</div>
