import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxDateBoxModule, DxButtonModule, DxTextBoxModule  } from 'devextreme-angular';
import { SummaryComponent } from './summary/summary.component';
import { SummaryService } from './summary/summary.service';
import { AxGridModule, AxChartModule } from '../../shared/components';

@NgModule({
  declarations: [SummaryComponent ],
  imports: [
    CommonModule,
    DxDateBoxModule,
    DxButtonModule,
    AxGridModule,
    AxChartModule,
    DxTextBoxModule
  ],
  providers: [SummaryService ]
})
export class CashFlowModule { }
