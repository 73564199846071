import { Injectable } from '@angular/core';
import { ListItem } from './list-item';

@Injectable({
  providedIn: 'root'
})

export class ListItemService {
  constructor() { }

  getAvailSelLists(sel: string[], all: string[]): [ListItem[], ListItem[]] {
    let availItems: ListItem[] = [];
    let selItems: ListItem[] = [];
    let selMap = new Map();
    let strUpper: string;

    for (let str of sel) {
      selMap.set(str.toUpperCase(), null);
    }

    let counter: number = 0;
    for (let str of all) {
      strUpper = str.toUpperCase();
      if (selMap.has(strUpper)) {
        let item: ListItem = { id: counter++, text: str };
        selMap.set(strUpper, item);
      } else {
        availItems.push({ id: counter++, text: str } as ListItem)
      }
    }

    for (let str of sel) {
      strUpper = str.toUpperCase();
      if (selMap.has(strUpper)) {
        selItems.push(selMap.get(strUpper));
      }
    }

    return [selItems, availItems];
  }
}
