import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TtyaxiomRunnerComponent } from './ttyaxiom-runner/ttyaxiom-runner.component';
import { TtyaxiomRunnerService } from './ttyaxiom-runner/ttyaxiom-runner.service';
import { DxButtonModule, DxFormModule, DxLoadPanelModule } from 'devextreme-angular';
import { AxGridModule } from 'src/app/shared/components/grid/grid.component';


@NgModule({
  declarations: [
    TtyaxiomRunnerComponent
  ],
  imports: [
    CommonModule,
    AxGridModule,
    DxButtonModule,
    DxFormModule,
    DxLoadPanelModule
  ],
  providers: [
    TtyaxiomRunnerService
  ]
})
export class ReportRunnerModule { }
