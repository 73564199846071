<h6 class="content-block">Returns Variance Group Summary</h6>
<p>
  Report ID: <strong>{{this.summary.reportId}}</strong>
  &nbsp;&nbsp;&nbsp; Portfolio: <strong>{{this.summary.portfolioName}}</strong>
  &nbsp;&nbsp;&nbsp; Benchmark: <strong>{{this.summary.benchmarkName}}</strong>
</p>

<div>
  <div class="ax-panel">
    <app-pivot-grid name="Returns Variance Group Summary"
                    [dataSource]="dataSource"
                    [fieldChooserEnabled]="false"
                    [chartLocation]="chartLocation"
                    (onCellPrepared)="onCellPrepared($event)"
                    (onCellClick)="onCellClick($event)"
                    (onContentReady)="onContentReady($event)">
    </app-pivot-grid>
  </div>
  <dx-popup [(visible)]="drillDownVisible"
            titleTemplate="title"
            [showCloseButton]="true"
            [width]="1000"
            [height]="500"
            [resizeEnabled]="true"
            (onResize)="onPopupResize($event)"
            (onShown)="onPopupShown($event)">
    <div *dxTemplate="let data of 'title'">
      <div class="dx-toolbar dx-widget dx-visibility-change-handler dx-collection dx-popup-title" role="toolbar">
        <div class="dx-toolbar-items-container">
          <div class="dx-toolbar-before">
            <div class="dx-item dx-toolbar-item dx-toolbar-label" style="max-width: 1400px;">
              <div id="drillDownpopupTitle"
                   class="dx-item-content dx-toolbar-item-content"
                   (mouseenter)="toggleToolTipVisible()"
                   (mouseleave)="toggleToolTipVisible()">
                <div>{{this.drillDownTitle}}</div>
              </div>
            </div>
          </div>
          <dx-tooltip target="#drillDownpopupTitle"
                      [(visible)]="toolTipVisible"
                      [closeOnOutsideClick]="false">
            <div *dxTemplate="let data = data of 'content'">
              {{this.drillDownTitle}}
            </div>
          </dx-tooltip>
          <div class="dx-toolbar-center" style="margin: 0px 51px 0px 532px; float: none;"></div>
          <div class="dx-toolbar-after">
            <div class="dx-item dx-toolbar-item dx-toolbar-button">
              <div class="dx-item-content dx-toolbar-item-content">
                <dx-button icon="close"
                           (onClick)="onPopupClose()">
                </dx-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *dxTemplate="let data of 'content'">
      <dx-tabs #apiTabs
               [dataSource]="tabs"
               [(selectedIndex)]="tabsIndex"
               (onItemClick)="onSelectTab($event)">
      </dx-tabs>
      <app-grid name="Returns Variance Drill Down"
                [height]="350"
                [dataSource]="drillDownDataSource">
      </app-grid>
    </div>
  </dx-popup>
</div>
