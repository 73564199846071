import { Injectable } from '@angular/core';
import { SummaryService } from '../summary/summary.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { AuthService, AppUtilService, AppFormatService } from 'src/app/shared/services';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { dxPivotGridSummaryCell } from 'devextreme/ui/pivot_grid';


@Injectable()
export class SummaryPivotService {
  public readonly typePort = 'Portfolio';
  public readonly typeBench = 'Benchmark';
  public readonly typeActive = 'Active';

  private readonly pathSep = '|';
  private readonly displayPathSep = '/';
  private readonly rowidSep = '!*';
  private readonly header1: string[] = ['percent invested as of', 'base returns', 'local returns', 'management effects', 'local returns contributions', 'currency returns contributions', 'base returns contributions'];
  private readonly header2: string[] = ['portfolio', 'benchmark', 'difference', 'active', 'alloc high', 'alloc low', 'selection', 'fund res', 'currency', 'total'];
  private readonly fields: string[] = [
    'port_invest_weight',
    'bench_invest_weight',
    'port_total_return',
    'bench_total_return',
    'port_local_return',
    'bench_local_return',
    'allocation_high',
    'allocation_low',
    'selection_effect',
    'fund_residual',
    'active_currency_return',
    'active_total_return',
    'port_local_return_contrib',
    'bench_local_return_contrib',
    'port_currency_return_contrib',
    'bench_currency_return_contrib',
    'port_total_return_contrib',
    'bench_total_return_contrib'
  ];

  private dataSource!: PivotGridDataSource ;
  // groupCellMap maps all the parent nodes to a summary value.
  // It will be used for all the summary value in the pivot table
  private groupCellMap = new Map<string, number>();
  // groupRowSet Set will indicate all the parent nodes which contain children nodes.
  private groupRowSet = new Set<string>();
  // rowidMap will map row group to rowid, it's for the sorting by rowid
  private rowidMap = new Map<string, number>();
  // map the column to data field in the postgres db
  private dataFieldMap = new Map<string, string>();

  public dRowCells: RvPivotCell[] = [];
  private expandColPath: string[] = [];

  public get pivotDataSource(): PivotGridDataSource {
    return this.dataSource;
  }

  // this indicate which column path should be expanded initially
  public get expandedColumnPath(): string[] {
    return this.expandColPath;
  }

  constructor(
    private auth: AuthService,
    private summary: SummaryService,
    private appUtil: AppUtilService,
    private appFormat: AppFormatService
  ) {
    this.createDataFieldMap();
  }

  public getDataField(colPath: string) : string | undefined {
    colPath = colPath.toLowerCase();
    if (this.dataFieldMap.has(colPath)) {
      return this.dataFieldMap.get(colPath);
    } else {
      return undefined;
    }
  }

  private createDataFieldMap() {
    this.dataFieldMap.set(`${this.header1[0]}${this.pathSep}${this.header2[0]}`, this.fields[0]);
    this.dataFieldMap.set(`${this.header1[0]}${this.pathSep}${this.header2[1]}`, this.fields[1]);
    this.dataFieldMap.set(`${this.header1[1]}${this.pathSep}${this.header2[0]}`, this.fields[2]);
    this.dataFieldMap.set(`${this.header1[1]}${this.pathSep}${this.header2[1]}`, this.fields[3]);
    this.dataFieldMap.set(`${this.header1[2]}${this.pathSep}${this.header2[0]}`, this.fields[4]);
    this.dataFieldMap.set(`${this.header1[2]}${this.pathSep}${this.header2[1]}`, this.fields[5]);
    this.dataFieldMap.set(`${this.header1[3]}${this.pathSep}${this.header2[4]}`, this.fields[6]);
    this.dataFieldMap.set(`${this.header1[3]}${this.pathSep}${this.header2[5]}`, this.fields[7]);
    this.dataFieldMap.set(`${this.header1[3]}${this.pathSep}${this.header2[6]}`, this.fields[8]);
    this.dataFieldMap.set(`${this.header1[3]}${this.pathSep}${this.header2[7]}`, this.fields[9]);
    this.dataFieldMap.set(`${this.header1[3]}${this.pathSep}${this.header2[8]}`, this.fields[10]);
    this.dataFieldMap.set(`${this.header1[3]}${this.pathSep}${this.header2[9]}`, this.fields[11]);
    this.dataFieldMap.set(`${this.header1[4]}${this.pathSep}${this.header2[0]}`, this.fields[12]);
    this.dataFieldMap.set(`${this.header1[4]}${this.pathSep}${this.header2[1]}`, this.fields[13]);
    this.dataFieldMap.set(`${this.header1[5]}${this.pathSep}${this.header2[0]}`, this.fields[14]);
    this.dataFieldMap.set(`${this.header1[5]}${this.pathSep}${this.header2[1]}`, this.fields[15]);
    this.dataFieldMap.set(`${this.header1[6]}${this.pathSep}${this.header2[0]}`, this.fields[16]);
    this.dataFieldMap.set(`${this.header1[6]}${this.pathSep}${this.header2[1]}`, this.fields[17]);
  }

  public retrieve(): Observable<any> {
    let httpParams = new HttpParams()
      .set('reportId', '' + this.summary.reportId);

    return this.auth.get('/api/RvSummaryPivot/Get', httpParams);
  }

  public retrieveDrillDown(pathArray: string[], dataField: string): Observable<any> {
    let httpParams = new HttpParams()
      .set('reportId', '' + this.summary.reportId)
      .set('rowPath', pathArray.join(','))
      .set('dataField', dataField);

    return this.auth.get('/api/RvSummaryPivot/GetDrillDRow', httpParams);
  }

  public getDrillDownTitle(pathArray: string[]): string {
    pathArray = this.removeRowidFromPath(pathArray);
    let path = this.getPath(pathArray);
    return path.split(this.pathSep).join(this.displayPathSep);
  }

  public isGroupRowPath(strArray: string[]) {
    let pathArray = this.removeRowidFromPath(strArray);
    let path = this.getPath(pathArray);
    if (this.groupRowSet.has(path)) {
      return true;
    } else {
      return false;
    }
  }

  public build(data: any) {
    // build T rows map and D rows cells for pivot table
    this.buildCells(data);

    this.dataSource = new PivotGridDataSource({
      remoteOperations: false,
      fields: [{
        dataField: 'cg1',
        sortingMethod: this.customColumnSort1.bind(this),
        wordWrapEnabled: false,
        area: 'column'
      }, {
        dataField: 'cg2',
        sortingMethod: this.customColumnSort2.bind(this),
        wordWrapEnabled: false,
        area: 'column'
      }, {
        caption: 'rg1',
        dataField: 'rg1',
        selector: this.selector1.bind(this),
        customizeText: this.customizeText.bind(this),
        sortingMethod: this.customRowSort.bind(this),
        wordWrapEnabled: false,
        area: 'row'
      }, {
        caption: 'rg2',
        dataField: 'rg2',
        selector: this.selector2.bind(this),
        customizeText: this.customizeText.bind(this),
        sortingMethod: this.customRowSort.bind(this),
        wordWrapEnabled: false,
        area: 'row'
      }, {
        caption: 'rg3',
        dataField: 'rg3',
        selector: this.selector3.bind(this),
        customizeText: this.customizeText.bind(this),
        sortingMethod: this.customRowSort.bind(this),
        wordWrapEnabled: false,
        area: 'row'
      }, {
        caption: 'rg4',
        dataField: 'rg4',
        selector: this.selector4.bind(this),
        customizeText: this.customizeText.bind(this),
        sortingMethod: this.customRowSort.bind(this),
        wordWrapEnabled: false,
        area: 'row'
      }, {
        caption: 'rg5',
        dataField: 'rg5',
        selector: this.selector5.bind(this),
        customizeText: this.customizeText.bind(this),
        sortingMethod: this.customRowSort.bind(this),
        wordWrapEnabled: false,
        area: 'row'
      },/* {
        caption: 'rg6',
        dataField: 'rg6',
        dataType: 'date',
        format: this.appFormat.GuiDate,
        wordWrapEnabled: false,
        area: 'row'
      },*/
      {
        caption: 'Total',
        dataField: 'val',
        dataType: 'number',
        summaryType: 'custom',
        format: '#,##0.00000',
        calculateCustomSummary: this.customSummary,
        calculateSummaryValue: this.calcSummaryValue.bind(this),
        area: 'data'
      }],
      store: this.dRowCells
    });
  }

  public removeRowid(text: string) {
    if (this.appUtil.isEmpty(text)) {
      return text;
    } else {
      let str = text.split(this.rowidSep);
      return str[0];
    }
  }

  public removeRowidFromPath(path: string[]): string[] {
    if (this.appUtil.isEmpty(path)) {
      return path;
    } else {
      var strArray:any[] = [];
      path.forEach(item => {
        strArray.push(this.removeRowid(item));
      });
      return strArray;
    }
  }

  public removeDateFromColPath(path: string[]): string[] {
    if (this.appUtil.isEmpty(path)) {
      return path;
    } else {
      var strArray:any[] = [];
      path.forEach(item => {
        if (item.toLowerCase().includes(this.header1[0])) {
          item = this.header1[0];
        }
        strArray.push(item);
      });
      return strArray;
    }
  }

  private buildCells(data: any[]) {
    this.groupCellMap.clear();
    this.dRowCells = [];

    data.forEach(item => {
      if (this.expandColPath.length === 0
        && item.cg1.toLowerCase().includes(this.header1[0])) {
        this.expandColPath = [item.cg1];
      }

      // add all nodes to rowidMap, so we can sort with the rowid
      let path = this.getGroupRowPath(item);
      if (!this.rowidMap.has(path)) {
        this.rowidMap.set(path, item.rowid);
      }

      if (item.isParent) {
        // for parent rows, add to the map
        if (!this.groupRowSet.has(path)) {
          // groupRowMap is using to check if it's in a group row,
          // we set the value to null because we never need to use the value
          this.groupRowSet.add(path);
        }

        path = this.getGroupCellPath(item);
        if (this.groupCellMap.has(path)) {
          return;
        } else {
          this.groupCellMap.set(path, item.val);
        }
      } else {
        //this.moveUpBeginDateGroup(item);
        this.dRowCells.push(item);
      }
    });
  }

  public seriesNameByColumn(colName: string) {
    switch (colName) {
      case this.fields[0]:
      case this.fields[2]:
      case this.fields[4]:
      case this.fields[12]:
      case this.fields[14]:
      case this.fields[16]:
        return this.typePort;
      case this.fields[1]:
      case this.fields[3]:
      case this.fields[5]:
      case this.fields[13]:
      case this.fields[15]:
      case this.fields[17]:
        return this.typeBench;
      case this.fields[6]:
      case this.fields[7]:
      case this.fields[8]:
      case this.fields[9]:
      case this.fields[10]:
      case this.fields[11]:
      default:
        return this.typeActive;
    }
  }

  private customColumnSort(a:any, b:any, hdrs: string[]): number {
    let aIdx = hdrs.findIndex((e) => e.includes(a.value.toLowerCase()));
    let bIdx = hdrs.findIndex((e) => e.includes(b.value.toLowerCase()));
    return aIdx - bIdx;
  }

  private customizeText(e:any) {
    let text: string = e.valueText;
    var str = text.split(this.rowidSep);
    return str[0];
  }

  private selector(rg: string, strArray: string[]) {
    let rowPath = this.getPath(strArray);
    if (this.rowidMap.has(rowPath)) {
      return rg + this.rowidSep + this.rowidMap.get(rowPath);
    } else {
      return rg;
    }
  }

  private selector1(data: RvPivotCell) {
    return this.selector(data.rg1, [data.rg1]);
  }

  private selector2(data: RvPivotCell) {
    return this.selector(data.rg2, [data.rg1, data.rg2]);
  }

  private selector3(data: RvPivotCell) {
    return this.selector(data.rg3, [data.rg1, data.rg2, data.rg3]);
  }

  private selector4(data: RvPivotCell) {
    return this.selector(data.rg4, [data.rg1, data.rg2, data.rg3, data.rg4]);
  }

  private selector5(data: RvPivotCell) {
    return this.selector(data.rg5, [data.rg1, data.rg2, data.rg3, data.rg4, data.rg5]);
  }

  private customColumnSort1(a:any, b:any): number {
    return this.customColumnSort(a, b, this.header1);
  }

  private customColumnSort2(a:any, b:any): number {
    return this.customColumnSort(a, b, this.header2);
  }

  private customRowSort(a:any, b:any): number {
    let str, aaRowid: number, bbRowid: number;
    let aa: string = a.value;
    let bb: string = b.value;

    if (this.appUtil.isEmpty(aa)) {
      return 1;
    }

    if (this.appUtil.isEmpty(bb)) {
      return -1;
    }

    str = aa.split(this.rowidSep);
    if (str.length > 1) {
      aaRowid = +str[1];
    }

    str = bb.split(this.rowidSep);
    if (str.length > 1) {
      bbRowid = +str[1];
    }

    if (this.appUtil.isEmpty(aaRowid!)) {
      return 1;
    }

    if (this.appUtil.isEmpty(bbRowid!)) {
      return -1;
    }

    return aaRowid! - bbRowid!;
  }

  /*
  private customRowSort1(a, b): number {
    let aa: string = a.value;
    let bb: string = b.value;

    if (this.appUtil.isEmpty(aa)) {
      return text;
    } else {
      let str = text.split(this.rowidSep);
      return str[0];
    }

    if (this.totalHeader === aa) {
      return 1;
    } else if (this.totalHeader === bb) {
      return -1;
    } else {
      return this.customRowSort(a, b, this.rg1RowidMap);
    }
  }

  private customRowSort2(a, b): number {
    return this.customRowSort(a, b, this.rg2RowidMap);
  }

  private customRowSort3(a, b): number {
    return this.customRowSort(a, b, this.rg3RowidMap);
  }

  private customRowSort4(a, b): number {
    return this.customRowSort(a, b, this.rg4RowidMap);
  }

  private customRowSort5(a, b): number {
    return this.customRowSort(a, b, this.rg5RowidMap);
  }
    */

  private calcSummaryValue(cell:dxPivotGridSummaryCell) : number | null {
    let rvCell: RvPivotCell = {
      cg1: cell.value('cg1', false),
      cg2: cell.value('cg2', false),
      rg1: cell.value('rg1', false),
      rg2: cell.value('rg2', false),
      rg3: cell.value('rg3', false),
      rg4: cell.value('rg4', false),
      rg5: cell.value('rg5', false),
      //rg6: cell.value('rg6', false),
      val: cell.value('val', false),
      rowid: cell.value('rowid', false)
    };

    let tRowPath = this.getGroupCellPath(rvCell);
    if (this.groupCellMap.has(tRowPath)) {
      let cc = this.groupCellMap.get(tRowPath);
      if (cc == undefined) {
        return null;
      } else {
        return cc;
      }
    } else {
      return rvCell.val;
    }
  }

  private customSummary(options:any) {
    var obj = this;
    switch (options.summaryProcess) {
      case 'start':
        // Initializing 'totalValue' here
        break;
      case 'calculate':
        options.totalValue = options.value;
        break;
      case 'finalize':
        // Assigning the final value to 'totalValue' here
        break;
    }
  }

  public getPath(strArray: string[]) {
    let path = '';

    if (this.appUtil.isEmpty(strArray)) {
      return path;
    }

    for (var ii = 0; ii < strArray.length; ii++) {
      if (ii !== 0) {
        path += this.pathSep;
      }

      path += strArray[ii];
    }

    return path;
  }

  private getRowPath(cell:any, strArray: string[]): string {
    if (this.appUtil.isEmpty(cell)) {
      return this.getPath(strArray);
    }

    let r1 = this.removeRowid(cell.rg1);
    let r2 = this.removeRowid(cell.rg2);
    let r3 = this.removeRowid(cell.rg3);
    let r4 = this.removeRowid(cell.rg4);
    let r5 = this.removeRowid(cell.rg5);

    if (this.appUtil.isNotEmpty(r1)) {
      strArray.push(r1);
    } else {
      return this.getPath(strArray);
    }

    if (this.appUtil.isNotEmpty(r2)) {
      strArray.push(r2);
    } else {
      return this.getPath(strArray);
    }

    if (this.appUtil.isNotEmpty(r3)) {
      strArray.push(r3);
    } else {
      return this.getPath(strArray);
    }

    if (this.appUtil.isNotEmpty(r4)) {
      strArray.push(r4);
    } else {
      return this.getPath(strArray);
    }

    if (this.appUtil.isNotEmpty(r5)) {
      strArray.push(r5);
    }

    return this.getPath(strArray);
  }

  // only check the group path for row area
  private getGroupRowPath(cell:any) {
    return this.getRowPath(cell, []);
  }

  private getGroupCellPath(cell:any) {
    let strArray = [];
    if (this.appUtil.isNotEmpty(cell.cg1)) {
      strArray.push(cell.cg1);
    } else {
      strArray.push('');
    }
    if (this.appUtil.isNotEmpty(cell.cg2)) {
      strArray.push(cell.cg2);
    } else {
      strArray.push('');
    }

    return this.getRowPath(cell, strArray);
  }

  /*
  // rg6 is the begin_date, there might be blank between rg6 and the rg1, rg2,..rg5,
  // If there are blanks, begin_date should be moved up to be next to a non-blank group
  private moveUpBeginDateGroup(cell: RvPivotCell) {
    if (!this.appUtil.isNotEmpty(cell.rg1)) {
      cell.rg1 = this.appFormat.GuiDate(cell.rg6);
      cell.rg6 = undefined;
      return;
    }

    if (!this.appUtil.isNotEmpty(cell.rg2)) {
      cell.rg2 = this.appFormat.GuiDate(cell.rg6);
      cell.rg6 = undefined;
      return;
    }

    if (!this.appUtil.isNotEmpty(cell.rg3)) {
      cell.rg3 = this.appFormat.GuiDate(cell.rg6);
      cell.rg6 = undefined;
      return;
    }

    if (!this.appUtil.isNotEmpty(cell.rg4)) {
      cell.rg4 = this.appFormat.GuiDate(cell.rg6);
      cell.rg6 = undefined;
      return;
    }

    if (!this.appUtil.isNotEmpty(cell.rg5)) {
      cell.rg5 = this.appFormat.GuiDate(cell.rg6);
      cell.rg6 = undefined;
      return;
    }
  }
  */

  private isSamePath(p1: string[], p2: string[]): boolean {
    if (p1.length !== p2.length) {
      return false;
    }

    for (let ii = 0; ii < p1.length; ii++) {
      if (p1[ii] !== p2[ii]) {
        return false;
      }
    }

    return true;
  }

  public getTabs(): Tab[] {
    return tabs;
  }
}

let tabs: Tab[] = [
  {
    id: 0,
    text: "Security Detail",
    //icon: "user",
    //content: "Total Count"
  },
  {
    id: 1,
    text: "By Date",
    //icon: "comment",
    //content: "Portfolio Rejected"
  }
];

export class Tab {
  id!: number;
  text!: string;
  //icon: string;
  //content: string;
}

class RvPivotCell {
  cg1!: string;
  cg2!: string;
  rg1!: string;
  rg2!: string;
  rg3!: string;
  rg4!: string;
  rg5!: string;
  //rg6: Date;
  val!: number;
  rowid!: number;
}
