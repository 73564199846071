import { Component, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { SummaryService } from '../../summary/summary.service';
import { FactorService } from '../factor.service';
import { NGXLogger } from 'ngx-logger';
import { AxGridComponent } from 'src/app/shared/components';
import { HistoryTabService } from '../history-tab/history-tab.service';

@Component({
  selector: 'app-factor-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss']
})
export class NameComponent implements AfterViewInit {
  @ViewChild(AxGridComponent) grid!: AxGridComponent;

  @Output() onClick = new EventEmitter();
  @Output() onLoad = new EventEmitter();

  private seriesName!: string;
  private category!: string;
  private currency!: string;

  constructor(
    public summary: SummaryService,
    private factor: FactorService,
    private history: HistoryTabService,
    private logger: NGXLogger
  ) { }

  ngAfterViewInit() {
    this.factor.retrieveNameColumns().subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.grid.setColumns(jsonObj);
      });
  }

  cellButtonClickHandler(evt:any) {
    this.onClick.emit(evt);
  }

  cellDragHandler(evt:any) {
    let cellInfoStr = evt.dataTransfer.getData('text');
    let cellInfoObj = JSON.parse(cellInfoStr);
    let col = this.grid.columns[cellInfoObj.columnIndex];
    let tabObj = this.history.createNameHistoryTab(this.summary.reportId, this.seriesName, this.category, this.currency, cellInfoObj.rowData.factor_name, col.dataField, col.caption);
    let tabStr = '';
    if (tabObj) {
      tabStr = JSON.stringify(tabObj);
    }
    
    evt.dataTransfer.setData("text", tabStr);
  }

  public retrieve(seriesName: string, category: string, currency: string) {
    this.seriesName = seriesName;
    this.category = category;
    this.currency = currency;

    this.logger.debug("GFPA Factor retrieveName started");
    this.grid.showLoadingIndicator();
    this.onLoad.emit();
    this.factor.retrieveName(seriesName, category, currency).subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.grid.dataSource = jsonObj;
        this.logger.debug("GFPA Factor retrieveName finished");
      })
      .add(() => {
        this.grid.hideLoadingIndicator();
      });
  }
}
