
import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AppConfigService } from './app-config.service';
import { BrowserService } from './browser.service';

export interface IUser {
  userName: string;
  email: string;
  emailVerified: boolean,
  familyName: string;
  givenName: string;
  name: string;
}

const defaultPath = '/';

@Injectable()
export class AuthService {
  private accessToken: string = '';
  private idToken: string = '';
  private refreshToken: string = '';
  private groups = [];
  private _user: IUser = {
    userName: '',
    email: '',
    emailVerified: false,
    familyName: '',
    givenName: '',
    name: ''
  };


  public get userId() {
    return this._user.email;
  }

  public get company() {
    return '';
  }

  private get authCurrentUrl(): string | null {
    return this.browserServ.getItem('authCurrentUrl');
  }

  private set authCurrentUrl(itemValue: string) {
    this.browserServ.setItem('authCurrentUrl', itemValue);
  }

  constructor(
    private http: HttpClient,
    private appInfo: AppConfigService,
    private router: Router,
    private browserServ: BrowserService,
    //private appUtil: AppUtilService
  ) {
    Amplify.configure({
      Auth: {
        // Amazon Cognito User Pool ID
        userPoolId: environment.COGNITO_USERPOOL_ID, // UAT - 'us-west-2_ta2BiTxNU',

        // Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: environment.COGNITO_CLIENT_ID, //UAT - '125k8ds6bkebcotm5k45pifk2l'

        // Account UI configuration
        oauth: {
          domain: environment.COGNITO_APP_DOMAIN, // UAT - account.test.wilshire.com
          scope: ['phone', 'email', 'profile', 'openid'],
          redirectSignIn: environment.COGNITO_REDIRECT_URI,
          redirectSignOut: environment.COGNITO_REDIRECT_URI_SIGNOUT,
          responseType: 'code'
        }
      }
    });
    /*
    this.manager.events.addAccessTokenExpiring(() => {
      this.manager.signinSilent()
        .then((user) => {
          this.user = user; // This function just set the current user
        })
        .catch((error: Error) => {
          this.manager.getUser()
            .then(user => {
              this.user = user;
            });
        });
    });
    */



    /*
    this.manager.events.addSilentRenewError(function (e) {
    });
    */
    //this.manager.startSilentRenew();
    /*
    this.manager.events.addUserLoaded(user => {
      this.manager.getUser().then(user => {
        this.manager.events.removeAccessTokenExpired(this.tokenExpiredEvtHandler);
        this.user = user;
        this.manager.events.addAccessTokenExpired(this.tokenExpiredEvtHandler);
      });
      
    });
    */

    /*
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((event: any) => {
      if (event.url.startsWith("/auth-callback")) {
        return;
      } else {
        this.authCurrentUrl = event.url;
      }
    });
    */

    /* 2023-10-19 james: after rxjs upgraded, the following code was replace with the code above
    this.router
      .events
      .filter(event => event instanceof NavigationStart)
      .subscribe((event: NavigationStart) => {
        if (event.url.startsWith("/auth-callback")) {
          return;
        } else {
          this.authCurrentUrl = event.url;
        }
      });
      */
  }

  public getUser(): IUser {
    return this._user;
  }

  public get isLoggedIn() {
    if (this._user && this._user.email !== '') {
      return true;
    } else {
      return false;
    }
  }

  public resetUser() {
    this._user = {
      userName:'',
      email: '',
      emailVerified: false,
      familyName:'',
      givenName: '',
      name:''
    };
  }
  public startSilentAuthentication() {

  }

  public getClaims(): any {
    return {};
  }

  public getAuthorizationHeaderValue(): string {
    if (this.isLoggedIn) {
      // this will keep the login active
      this.startSilentAuthentication();
    }

    if (this._user) {
      return `Bearer ${this.accessToken}`;
    } else {
      return '';
    }
  }

  public startAuthentication() {
    //Get logged in user session
    Auth.currentSession().then(session=>{
      let cognitoIdToken = session.getIdToken();
      this.accessToken = session.getAccessToken().getJwtToken();
      this.idToken = cognitoIdToken.getJwtToken();
      this.refreshToken = session.getRefreshToken().getToken();
      this.groups = session.getAccessToken().payload['cognito:groups'];
      this._user.email = cognitoIdToken['payload']['email'];
      this._user.emailVerified = cognitoIdToken['payload']['email_verified'];
      this._user.givenName = cognitoIdToken['payload']['given_name'];
      this._user.familyName = cognitoIdToken['payload']['family_name'];
      this._user.name = cognitoIdToken['payload']['name'];
    }).catch(() => {
      console.log('Not signed in')
      Auth.federatedSignIn()
    });
  }

  public completeAuthentication(): Promise<void> | null {
    return null;
  }

  public logOut() {
    Auth.signOut();
    this.resetUser();
  }

  public get(url: string, httpParams: HttpParams | undefined = undefined): Observable<any> {
    return this.http.get(this.appInfo.apiUrl + url, { params: httpParams, headers: this.getHeaders() });
    /*
      .pipe(catchError((error) => {
        //this.user.h.handleError(error);
        return throwError(error);
      }));
      */
  }

  public put(url: string, data: any): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.put(this.appInfo.apiUrl + url, body, { headers: this.getHeaders() });
    /*
      .pipe(catchError((error) => {
        //this.oidcSecurityService.handleError(error);
        return throwError(error);
      }));
    */
  }

  public delete(url: string): Observable<any> {
    return this.http.delete(this.appInfo.apiUrl + url, { headers: this.getHeaders() });
    /*
      .pipe(catchError((error) => {
        //this.oidcSecurityService.handleError(error);
        return throwError(error);
      }));
    */
  }

  // public post(url: string, httpParams: HttpParams): Observable<any> {
  //   return this.http.post(this.appInfo.apiUrl + url, null, { params: httpParams, headers: this.getHeaders() });
  //   /*
  //     .pipe(catchError((error) => {
  //       //this.oidcSecurityService.handleError(error);
  //       return throwError(error);
  //     }));
  //   */
  // }

  public post(url: string, data: any): Observable<any> {
    return this.http.post(this.appInfo.apiUrl + url, data, {
      headers: new HttpHeaders({
        'Authorization' : this.getAuthorizationHeaderValue(),
        'Content-Type' : 'application/json',
        'Email' : this._user.email
      })
    })
  }

  public getHeaders() {
    return new HttpHeaders()
      .set('Authorization', this.getAuthorizationHeaderValue())
      .set('Content-Type', 'application/json')
      .set('Email', this._user.email);
  }

  public getItem(itemName: string): string | null {
    itemName = this.userId + '_' + this.company + '_' + itemName;
    return this.browserServ.getItem(itemName);
  }

  public setItem(itemName: string, itemValue: string) {
    itemName = this.userId + '_' + this.company + '_' + itemName;
    this.browserServ.setItem(itemName, itemValue);
  }

  public removeItem(itemName: string) {
    itemName = this.userId + '_' + this.company + '_' + itemName;
    // this.browserServ.removeItem(itemName);
    return null;
  }
}
