import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { AuthService, AppFormatService } from '../../../shared/services';
import { SummaryService } from '../summary/summary.service';
//import CustomStore from 'devextreme/data/custom_store';

@Injectable()

export class DetailService {

  constructor(
    private auth: AuthService,
    private summary: SummaryService,
    private appFormat: AppFormatService
  ) { }

  retrieveGroupGridColumns(): Observable<any> {
    return this.auth.get('/api/GfpaDetail/GetGroupGridColumns', undefined);
  }

  retrieveGrid(id: number, date: Date, seriesName: string): Observable<any> {
    if (id == 0) {
      id = this.summary.reportId;
    }

    if (!date && this.summary.analysisDate) {
      date = this.summary.analysisDate;
    }

    if (seriesName == '0') {
      seriesName = this.summary.seriesName;
    }

    let httpParams = new HttpParams()
      .set('reportId', '' + id)
      .set('date', this.appFormat.DateIntStr(date))
      .set('seriesName', seriesName);

    return this.auth.get('/api/GfpaDetail/Get', httpParams);
  }

  /*
  createGridStore(id: number, date: Date, seriesName: string): CustomStore {
    if (id == 0) {
      id = this.summary.reportId;
    }

    if (!date) {
      date = this.summary.analysisDate;
    }

    if (seriesName == '0') {
      seriesName = this.summary.seriesName;
    }

    let httpParams = new HttpParams()
      .set('reportId', '' + id)
      .set('date', this.appFormat.DateIntStr(date))
      .set('seriesName', seriesName);

    return this.auth.createStore('/api/GfpaDetail/Get', httpParams);
  }
  */

  retrieveTreeMap(id: number, date: Date, seriesName: string, path: string[], grpNames: string[]): Observable<any> {
    if (id == 0) {
      id = this.summary.reportId;
    }

    if (!date && this.summary.analysisDate) {
      date = this.summary.analysisDate;
    }

    if (seriesName == '0') {
      seriesName = this.summary.seriesName;
    }

    let pathStr: string = '';
    if (path && path.length > 0) {
      pathStr = path.join(',');
    }

    let httpParams = new HttpParams()
      .set('reportId', '' + id)
      .set('date', this.appFormat.DateIntStr(date))
      .set('seriesName', seriesName)
      .set('itemName', 'security_ret_contrib')
      .set('itemDesc', 'Security Perf. Attr. Contrib')
      .set('treeMapPath', pathStr)
      .set('grpNames', grpNames.join(','));

    return this.auth.get('/api/GfpaDetail/GetTreeMap', httpParams);
  }
}
