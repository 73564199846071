import { AfterViewInit, Component } from '@angular/core';
import { IDropdownCodes, IDropdownMaps, ISecurityModel, SecurityLookupService } from '../security-lookup/security-lookup.service';
import { BondService, IBondFormModel } from './bond.service';

@Component({
  selector: 'app-bond',
  templateUrl: './bond.component.html',
  styleUrls: ['./bond.component.scss']
})
export class BondComponent implements AfterViewInit {
  bondFormModel!: IBondFormModel;
  securityModel!: ISecurityModel;
  dropdownMaps!: IDropdownMaps;
  dropdownCodes!: IDropdownCodes;

  constructor(
    public securityLookupService: SecurityLookupService,
    public bondService: BondService
  ) {

  }

  save() {
    this.bondService.loading = true;
    this.bondService.save().subscribe().add(() => {
      this.bondService.loading = false;
    });
  }

  calculate() {
      this.bondService.calculate();
  }

  display(securityModel: ISecurityModel, cusip: string, date: Date) {
    this.bondService.display(securityModel, cusip, date);
    this.bondFormModel = this.bondService.bondFormModel;
  }

  ngAfterViewInit() {

  }
}