import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { SummaryService } from '../summary/summary.service';
import { AuthService, AppFormatService } from 'src/app/shared/services';

@Injectable()

export class SummaryInDepthService {
  constructor(
    private auth: AuthService,
    private summary: SummaryService,
    private appFormat: AppFormatService
  ) { }

  retrieve(date: Date | null = null): Observable<any> {
    let dateStr: string = '0';

    if (date) {
      dateStr = this.appFormat.DateIntStr(date);
    }

    let httpParams = new HttpParams()
      .set('reportId', '' + this.summary.reportId)
      .set('date', dateStr);

    return this.auth.get('/api/RvSummaryInDepth/Get', httpParams);
  }

  retrieveRange(): Observable<any> {
    let httpParams = new HttpParams()
      .set('reportId', '' + this.summary.reportId)
      .set('selectedSeries', this.summary.inDepthSeriesVisibleIndexList.join(','));

    return this.auth.get('/api/RvSummaryInDepth/GetRange', httpParams);
  }
}
