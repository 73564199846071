import { Directive, Input, HostListener, EventEmitter, Output } from '@angular/core';
import { HistoryTab } from 'src/app/shared/models/factor-history/history-tab';

@Directive({
  selector: '[appHistoryTabObj]'
})
export class HistoryTabDirective {

  @Input('appHistoryTabObj') tabItem!: HistoryTab;

  @Output() onDragOver = new EventEmitter<{ event: any, tabId: number }>();

  constructor() { }

  //(dragover) = "tabAllowDrop($event)"
  @HostListener('dragover', ['$event']) tabAllowDrop(evt:any) {
    evt.preventDefault();
    this.onDragOver.emit({ event: evt, tabId: this.tabItem.id });
  }
}
