import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportConfigService } from './report-config.service';
import { AxGridComponent } from '../../shared/components';
import { SummaryService as RvSummaryService } from '../rv/summary/summary.service';
import { SummaryService as GfpaSummaryService } from '../gfpa/summary/summary.service';
import { SummaryService as GfrSummaryService } from '../gfr/summary/summary.service';
import { SummaryService as FofSummaryService } from '../fof/summary-tab/summary.service';
import { RptParamService } from 'src/app/shared/services';
import { SideNavigationMenuService } from '../../shared/components/side-navigation-menu/side-navigation-menu.service';
//import * as $ from 'jquery';

@Component({
  selector: 'app-report-config',
  templateUrl: './report-config.component.html',
  styleUrls: ['./report-config.component.scss']
})

export class ReportConfigComponent implements AfterViewInit {
  @ViewChild(AxGridComponent) grid!: AxGridComponent;

  constructor(
    private router: Router,
    private reportConfig: ReportConfigService,
    private rvSummary: RvSummaryService,
    private gfpaSummary: GfpaSummaryService,
    private gfrSummary: GfrSummaryService,
    private fofSummary: FofSummaryService,
    private rptParam: RptParamService,
    private navServ: SideNavigationMenuService
  ) {
  }

  ngAfterViewInit() {
    this.grid.columns = [
      {
        dataField: 'reportId',
        //width: 90,
        caption: 'Report ID',
        headerCellTemplate: 'titleHeaderTemplate',
        sortInex: 0,
        sortOrder: 'desc',
        cellTemplate: 'buttonCellTemplate',
        alignment: 'center'
      },
      {
        dataField: 'reportBeginTime',
        dataType: 'datetime',
        //width: 250,
        caption: 'Report Begin Time',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'beginDate',
        dataType: 'date',
        //width: 150,
        caption: 'Begin Date',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'endDate',
        dataType: 'date',
        //width: 150,
        caption: 'End Date',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'reportType',
        width: 150,
        caption: 'Report Type',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'portfolioName',
        //width: 190,
        caption: 'Portfolio',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'portfolioLongName',
        //width: 190,
        caption: 'Title',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'benchmarkName',
        //width: 190,
        caption: 'Benchmark',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'templateName',
        //width: 190,
        caption: 'Tempate',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'matrixName',
        //width: 190,
        caption: 'Matrix',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'matrixDate',
        dataType: 'date',
        width: 150,
        caption: 'Matrix Date',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'hedgingPolicy',
        //width: 150,
        caption: 'Hedging Policy',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'messageText',
        //width: 200,
        caption: 'Status',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'userName',
        //width: 200,
        caption: 'User',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'storeReportData',
        //width: 200,
        caption: 'Save to Database',
        headerCellTemplate: 'titleHeaderTemplate'
      }
    ];

    this.grid.showLoadingIndicator();
    this.reportConfig.retrieveGrid()
      .subscribe(
        data => {
          let jsonObj = JSON.parse(data);
          this.grid.dataSource = jsonObj as ReportConfigData[];
        })
      .add(() => {
        this.grid.hideLoadingIndicator();
      });

    this.grid.cellButtonClicked.subscribe(evt => {
      this.onCellButtonClick(evt);
    });
  }

  public load(data:any) {
    let dataObj = JSON.parse(data);
    this.grid.dataSource = dataObj;
    this.grid.hideLoadingIndicator();
  }

  /* instead of show portfolio long name when available, we are going to add
   * a new "Title" column just for the long name
  onCellPrepared(evt) {
    if (evt.rowType === "data" && evt.column.dataField === "portfolioName") {
      if (evt.data.portfolioLongName) {
        evt.cellElement.innerHTML = evt.data.portfolioLongName;
      }
    }
  }
  */

  onCellButtonClick(event:any) {
    let rptData: ReportConfigData = event as ReportConfigData;
    let pName: string = '';
    let bName: string = '';
    let rptType: string = ''
    let beginDate: Date = new Date();
    let endDate: Date = new Date();
    var rid = rptData.reportId;
    this.grid.dataSource.forEach(data => {
      if (data.reportId == rid) {
        pName = data.portfolioLongName ? data.portfolioName + ' - ' + data.portfolioLongName : data.portfolioName;
        bName = data.benchmarkName;
        rptType = data.reportType;
        beginDate = data.beginDate;
        endDate = data.endDate;
      }
    })

    this.navServ.enableReportMenuItem(rptType);
    switch (rptType.toLowerCase()) {
      case this.rptParam.RV:
        this.rvSummary.reportId = +rid;
        this.rvSummary.portfolioName = pName;
        this.rvSummary.benchmarkName = bName;
        this.rvSummary.beginDate = new Date(beginDate);
        this.rvSummary.endDate = new Date(endDate);
        this.router.navigate(['/rv-summary']);
        break;
      case this.rptParam.GFPA:
        this.gfpaSummary.reportId = +rid;
        this.gfpaSummary.portfolioName = pName;
        this.gfpaSummary.benchmarkName = bName;
        this.gfpaSummary.beginDate = new Date(beginDate);
        this.gfpaSummary.endDate = new Date(endDate);
        this.router.navigate(['/gfpa-summary']);
        break;
      case this.rptParam.GFR:
        this.gfrSummary.reportId = +rid;
        this.gfrSummary.portfolioName = pName;
        this.gfrSummary.benchmarkName = bName;
        this.gfrSummary.beginDate = new Date(beginDate);
        this.gfrSummary.endDate = new Date(endDate);
        this.router.navigate(['/gfr-summary']);
        break;
      case this.rptParam.FOF:
        this.fofSummary.reportId = +rid;
        this.fofSummary.portfolioName = pName;
        this.fofSummary.benchmarkName = bName;
        this.fofSummary.analysisDate = new Date(beginDate);
        this.router.navigate(['/fof-summary']);
        break;
      default:
        break;
    }
  }
}

class ReportConfigData {
  reportId: string = '';
  reportBeginTime: string = '';
  reportEndTime: string = '';
  beginDate: string = '';
  endDate: string = '';
  reportType: string = '';
  portfolioName: string = '';
  portfolioLongName: string = '';
  benchmarkName: string = '';
  templateName: string = '';
  matrixName: string = '';
  matrixDate: string = '';
  hedgingPolicy: string = '';
  messageText: string = '';
}


/* 2021-07-1 james: the code below is for testing SignalR *********************************
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ReportConfigService } from './report-config.service';
import { AxGridComponent } from '../../shared/components';
import { SummaryService as RvSummaryService } from '../rv/summary/summary.service';
import { SummaryService as GfpaSummaryService } from '../gfpa/summary/summary.service';
import { SummaryService as GfrSummaryService } from '../gfr/summary/summary.service';
import { RptParamService } from 'src/app/shared/services';
import { SideNavigationMenuService } from '../../shared/components/side-navigation-menu/side-navigation-menu.service';
import { SignalRService } from 'src/app/shared/services/signal-r/signal-r.service';
//import * as $ from 'jquery';

@Component({
  selector: 'app-report-config',
  templateUrl: './report-config.component.html',
  styleUrls: ['./report-config.component.scss']
})

export class ReportConfigComponent implements AfterViewInit, OnDestroy {
  @ViewChild(AxGridComponent) grid: AxGridComponent;

  constructor(
    private router: Router,
    private reportConfig: ReportConfigService,
    private rvSummary: RvSummaryService,
    private gfpaSummary: GfpaSummaryService,
    private gfrSummary: GfrSummaryService,
    private rptParam: RptParamService,
    private navServ: SideNavigationMenuService,
    private signal_r: SignalRService
  ) {
  }

  ngAfterViewInit() {
    this.signal_r.loadReportLibrary = this.load.bind(this);
    //this.addDataSourceListener(this);
    this.grid.columns = [
      {
        dataField: 'reportId',
        //width: 90,
        caption: 'Report ID',
        headerCellTemplate: 'titleHeaderTemplate',
        sortInex: 0,
        sortOrder: 'desc',
        cellTemplate: 'buttonCellTemplate'
      },
      {
        dataField: 'reportBeginTime',
        dataType: 'datetime',
        //width: 250,
        caption: 'Report Begin Time',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'beginDate',
        dataType: 'date',
        //width: 150,
        caption: 'Begin Date',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'endDate',
        dataType: 'date',
        //width: 150,
        caption: 'End Date',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'reportType',
        width: 150,
        caption: 'Report Type',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'portfolioName',
        //width: 190,
        caption: 'Portfolio',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'benchmarkName',
        //width: 190,
        caption: 'Benchmark',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'templateName',
        //width: 190,
        caption: 'Tempate',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'matrixName',
        //width: 190,
        caption: 'Matrix',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'matrixDate',
        dataType: 'date',
        width: 150,
        caption: 'Matrix Date',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'hedgingPolicy',
        //width: 150,
        caption: 'Hedging Policy',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'messageText',
        //width: 200,
        caption: 'Status',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'storeReportData',
        //width: 200,
        caption: 'Save to Database',
        headerCellTemplate: 'titleHeaderTemplate'
      }
    ];

    this.grid.showLoadingIndicator();
    this.reportConfig.retrieveGrid()
      .subscribe(
      data => {
          //this.grid.dataSource = data as ReportConfigData[];
        })
      .add(() => {
        //this.grid.hideLoadingIndicator();
      });

    this.grid.cellButtonClicked.subscribe(evt => {
      this.onCellButtonClick(evt);
    });
  }
 
  public load(data) {
    let dataObj = JSON.parse(data);
    this.grid.dataSource = dataObj;
    this.grid.hideLoadingIndicator();
  }

  onCellButtonClick(event) {
    let pName: string, bName: string, rptType: string, beginDate: Date, endDate: Date;
    var rid = event.target.innerText;
    this.grid.dataSource.forEach(data => {
      if (data.reportId == rid) {
        pName = data.portfolioName;
        bName = data.benchmarkName;
        rptType = data.reportType;
        beginDate = data.beginDate;
        endDate = data.endDate;
      }
    })

    this.navServ.enableReportMenuItem(rptType);
    switch (rptType.toLowerCase()) {
      case this.rptParam.RV:
        this.rvSummary.reportId = +rid;
        this.rvSummary.portfolioName = pName;
        this.rvSummary.benchmarkName = bName;
        this.rvSummary.beginDate = new Date(beginDate);
        this.rvSummary.endDate = new Date(endDate);
        this.router.navigate(['/rv-summary']);
        break;
      case this.rptParam.GFPA:
        this.gfpaSummary.reportId = +rid;
        this.gfpaSummary.portfolioName = pName;
        this.gfpaSummary.benchmarkName = bName;
        this.gfpaSummary.beginDate = new Date(beginDate);
        this.gfpaSummary.endDate = new Date(endDate);
        this.router.navigate(['/gfpa-summary']);
        break;
      case this.rptParam.GFR:
        this.gfrSummary.reportId = +rid;
        this.gfrSummary.portfolioName = pName;
        this.gfrSummary.benchmarkName = bName;
        this.gfrSummary.beginDate = new Date(beginDate);
        this.gfrSummary.endDate = new Date(endDate);
        this.router.navigate(['/gfr-summary']);
        break;
      default:
        break;
    }
  }
}

class ReportConfigData {
  reportId: string;
  reportBeginTime: string;
  reportEndTime: string;
  beginDate: string;
  endDate: string;
  reportType: string;
  portfolioName: string;
  benchmarkName: string;
  templateName: string;
  matrixName: string;
  matrixDate: string;
  hedgingPolicy: string;
  messageText: string;
}
*******************************************************************************************/
