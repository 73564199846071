<div id="parcoords" class="parcoords"></div>
<dx-load-panel #loadPanel
               shadingColor="rgba(0,0,0,0.4)"
               [position]="{ of: '#parcoords' }"
               [(visible)]="loadingVisible"
               [showIndicator]="true"
               [showPane]="true"
               [shading]="false"
               [closeOnOutsideClick]="false">
</dx-load-panel>
