import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  getClientErrorMessage(error: Error): string {
    return error.message ?
      error.message :
      error.toString();
  }

  getServerErrorMessage(error: Error): string {
    return navigator.onLine ?
      // error.message :
      // show a general message
      'Server Error: This page is temporarily unavailable.' :
      'There may be a problem in your internet connection. Please check your connection and try again.';
  }
}
