import { Component, AfterViewInit, NgModule, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxPivotGridModule, DxChartModule, DxChartComponent, DxPivotGridComponent } from 'devextreme-angular';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { AppFormatService } from '../../services';

export enum ChartLocation {
  None = 0,
  Up = 1,
  Down = 2
}

@Component({
  selector: 'app-pivot-grid',
  templateUrl: './pivot-grid.component.html',
  styleUrls: ['./pivot-grid.component.scss']
})
export class AxPivotGridComponent implements AfterViewInit {
  @ViewChild(DxPivotGridComponent) pivotGrid!: DxPivotGridComponent;
  @ViewChild(DxChartComponent) chart!: DxChartComponent;

  @Input() name: string = '';
  @Input() dataSource!: PivotGridDataSource;
  @Input() fieldChooserEnabled: boolean = false;
  @Input() chartLocation: number = 0;
  @Input() showRowGrandTotals = false;
  @Input() processChartCell!: Function;

  @Output() onCellPrepared = new EventEmitter();
  @Output() onCellClick = new EventEmitter();
  @Output() onContentReady = new EventEmitter();
  @Output() onInitialized = new EventEmitter();

  constructor(
    private appFormat: AppFormatService
  ) {
    this.customizeTooltip = this.customizeTooltip.bind(this);
  }

  ngAfterViewInit() {
    if (this.chartLocation !== ChartLocation.None) {
      this.pivotGrid.instance.bindChart(this.chart.instance, {
        dataFieldsDisplayMode: 'splitPanes',
        alternateDataFields: false,
        processCell: this.processChartCell
      });
    }
  }
 
  customizeTooltip(args:any) {
    return {
      html: args.seriesName + ': ' + this.appFormat.GuiNumber(args.value)
    };
  }

  cellPreparedHandler(event:any) {
    this.onCellPrepared.emit(event);
  }

  cellClickHandler(event:any) {
    this.onCellClick.emit(event);
  }

  exportingHandler(event:any) {
    event.fileName = this.name;
  }

  contentReadyHandler(event:any) {
    this.onContentReady.emit(event);
  }

  initializedHandler(event:any) {
    this.onInitialized.emit(event);
  }

  public showLoadingIndicator() {
    this.pivotGrid.loadPanel.showIndicator = true;
  }

  public hideLoadingIndicator() {
    this.pivotGrid.loadPanel.showIndicator = false;
  }
}



@NgModule({
  declarations: [AxPivotGridComponent],
  imports: [
    DxPivotGridModule,
    DxChartModule,
    CommonModule
  ],
  exports: [AxPivotGridComponent]
})
export class AxPivotGridModule { }
