import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { SummaryService } from '../summary/summary.service';
import { AxGridComponent, AxChartComponent } from '../../../shared/components';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements AfterViewInit {
  @ViewChild(AxChartComponent) chart!: AxChartComponent;
  @ViewChild(AxGridComponent) grid!: AxGridComponent;

  constructor(
    public summary: SummaryService,
    private router: Router
  ) { }

  ngAfterViewInit() {
    this.chart.series = [
      {
        type: 'line',
        argumentField: 'date_col',
        valueField: 'p',
        name: 'Portfolio'
      },
      {
        type: 'line',
        argumentField: 'date_col',
        valueField: 'b',
        name: 'Benchmark'
      },
      {
        type: 'line',
        argumentField: 'date_col',
        valueField: 'd',
        name: 'Active'
      }
    ];

    this.chart.argumentAxis = {
      argumentType: 'datetime',
      tickInterval: {
        days: 1
      },
      label: {
        format: {
          type: 'shortDate'
        }
      }
    };

    this.grid.columns = [
      {
        dataField: 'date_col',
        dataType: 'date',
        caption: 'Date',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'p',
        dataType: 'number',
        caption: 'Portfolio',
        format: '#,##0.000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'b',
        dataType: 'number',
        caption: 'Benchmark',
        format: '#,##0.000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'd',
        dataType: 'number',
        caption: 'Active',
        format: '#,##0.000',
        headerCellTemplate: 'titleHeaderTemplate'
      }
    ];

    //let id = this.route.snapshot.paramMap.get('id');
    this.chart.showLoadingIndicator();
    this.grid.showLoadingIndicator();
    this.summary.retrieve().subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.chart.dataSource = jsonObj;
        this.grid.dataSource = jsonObj;
        let bdate = jsonObj.slice(-1)[0].date_col;
        this.summary.analysisDate = new Date(bdate);
      })
      .add(() => {
        this.chart.hideLoadingIndicator();
        this.grid.hideLoadingIndicator();
      });
  }

  /* 2020-10-05 james: detail view would be always in total period, so there is no need to
   * click on the specific chart point on specific date. It should allow user to click on any points
   * in the series
  pointClickedHandler(e: any) {
    var point = e.target;
    this.summary.analysisDate = new Date(point.argument);
    this.summary.seriesName = point.series.name;
    this.router.navigate(['/gfpa-detail', '' + this.summary.reportId, this.summary.analysisDate.toISOString(), point.series.name]);
  }
  */

  seriesClicked(e: any) {
    let series = e.target;
    this.summary.seriesName = series.name;
    // the analysis date will be ignore becuase it's only showing the T rows
    this.router.navigate(['/gfpa-detail', '' + this.summary.reportId, this.summary.analysisDate!.toISOString(), series.name]);
  }
}
