import { Injectable } from '@angular/core';
import { ListItem, ListItemService } from '../../utils';
import { AuthService } from '../../services';

@Injectable()

export class AxParcoordsService {
  availCols: ListItem[] = [];
  selCols: ListItem[] = [];

  private _strSelCols: string[] = [];
  private _strAllCols: string[] = [];
  private _columnsMap: Map<string, any>;

  constructor(
    private listItemService: ListItemService,
    private auth: AuthService
  ) {
    this._columnsMap = new Map();
  }

  public getAvailSelLists(rptType: string, initStrSelCols: string[], data: any) {
    if (!this._strSelCols || this._strSelCols.length == 0) {
      // check in the browser storage
      let str = this.auth.getItem(rptType + 'ParcoordsCols');
      if(str) {
        this._strSelCols = str.split(',');
      }
    }

    if (!this._strSelCols || this._strSelCols.length == 0) {
      // get the init cols from the caller
      this._strSelCols = initStrSelCols;
    }

    for (let obj of data) {
      this._columnsMap.set(obj.header, obj);
      this._strAllCols.push(obj.header);
    }

    let tup = this.listItemService.getAvailSelLists(this._strSelCols, this._strAllCols);
    this.selCols = tup[0];
    this.availCols = tup[1];
  }

  private getColType(type: number): string {
    let typeStr: string;
    switch (type) {
      case 2: // integer
      case 3: { // decimal
        return 'number';
      }
      case 1: // string
      case 4: // date
      case 5: // time
      default: {
        return 'string';
      }
    }
  }

  private getDecimalPlace(type: number): number {
    let typeStr: string;
    switch (type) {
      case 3: { // decimal
        return 3;
      }
      case 1: // string
      case 2: // integer
      case 4: // date
      case 5: // time
      default: {
        return 0;
      }
    }
  }

  public setDimensions(rptType: string, parcoords: any, data: any) {
    let same = true;
    let dim = parcoords.dimensions;
    if (dim) {
      if (this._strSelCols.length === this.selCols.length) {
        for (let ii = 0; ii < this.selCols.length; ii++) {
          if (this._strSelCols[ii] !== this.selCols[ii].text) {
            same = false;
            break;
          }
        }
      } else {
        same = false;
      }
    } else {
      dim = {};
      same = false;
    }

    if (same) {
      // do nothing if dimensions are the same
      return;
    }

    let firstPropName = '';
    dim = {};
    this._strSelCols = [];
    for (let item of this.selCols) {
      let key = item.text;
      if (this._columnsMap.has(key)) {
        let obj = this._columnsMap.get(key);
        if (!firstPropName) {
          firstPropName = obj.name;
        }

        dim[obj.name] = {
          title: obj.header,
          type: this.getColType(obj.type),
          decimalPlace: this.getDecimalPlace(obj.type)
        }

        this._strSelCols.push(obj.header);
      }
    }

    this.auth.setItem(rptType + 'ParcoordsCols', this._strSelCols.toString());
    parcoords.dimensions = dim;
    parcoords.nameColumn = [firstPropName, dim[firstPropName].title];
    parcoords.dataSource = data;
  }
}
