import { Component, ViewChildren, ViewChild, AfterViewInit, QueryList } from '@angular/core';
import { DxDateBoxComponent, DxButtonComponent } from "devextreme-angular";
import { AxGridComponent, AxTreeMapComponent, AxTreeMapItem } from '../../../shared/components';
import { SummaryService } from '../summary/summary.service';
import { DetailService } from './detail.service';
import { AppFormatService } from '../../../shared/services';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements AfterViewInit {
  @ViewChildren(DxDateBoxComponent) dates!: QueryList<DxDateBoxComponent>;
  @ViewChild(AxGridComponent) grid!: AxGridComponent;
  @ViewChild(AxTreeMapComponent) treeMap!: AxTreeMapComponent;

  private treeMapList!: AxTreeMapItem[];
  private treeMapPath!: string[];

  private _beginDate: Date = new Date();
  get beginDate(): Date {
    return this._beginDate;
  }

  set beginDate(date: Date) {
    this._beginDate = date;
    this.summary.beginDate = date;
  }

  private _endDate: Date = new Date();
  get endDate(): Date {
    return this._endDate;
  }

  set endDate(date: Date) {
    this._endDate = date;
    this.summary.endDate = date;
  }

  constructor(private summary: SummaryService, private detail: DetailService, private appFormat: AppFormatService) {
    if (this.summary.beginDate) {
      this._beginDate = this.summary.beginDate;
    }

    if (this.summary.endDate) {
      this._endDate = this.summary.endDate;
    }
    
  }

  ngAfterViewInit() {
    this.treeMap.customizeTooltipFunc = (arg) => {
      let val = 0;
      if (arg.node.data.items) {
        for (let ii = 0; ii < arg.node.data.items.length; ii++) {
          val += arg.node.data.items[ii].realValue;
        }
      } else if (arg.node.data.realValue) {
        val = arg.node.data.realValue;
      } else {
        val = arg.value;
      }

      return {
        html: '<span><strong>' + arg.node.data.id + ':   </strong>' + this.appFormat.GuiInt(val) + '</span>'
        //text: arg.node.data.realValue.toFixed(3)
      }
    };

    if (this.summary.beginDate) {
      this._beginDate = this.summary.beginDate;
    }
    
    if (this.summary.endDate) {
      this._endDate = this.summary.endDate;
    }
    
    this.grid.columns = [
      {
        dataField: 'file_name',
        caption: 'File Name',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'portfolio_name',
        groupIndex: 2,
        caption: 'Portfolio Name',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'portfolio_date',
        dataType: 'date',
        //width: 150,
        caption: 'Portfolio Date',
        groupIndex: 1,
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'total_count',
        //width: 90,
        caption: 'Total Count',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'rejected_count_1',
        //width: 90,
        caption: 'Rejected Count',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'start_time',
        dataType: 'date',
        format: 'MM/dd/yyyy hh:mm:ss a',
        //width: 250,
        caption: 'Start Time',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'message_txt',
        //width: 90,
        caption: 'Message Text',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'load_file_id',
        //width: 90,
        caption: 'Load File ID',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'header_id',
        //width: 90,
        caption: 'Header ID',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'line_number',
        //width: 90,
        caption: 'Line Number',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'security_id',
        //width: 90,
        caption: 'Security ID',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'message_code',
        //width: 90,
        caption: 'Message Code',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'begin_time',
        dataType: 'date',
        format: 'MM/dd/yyyy hh:mm:ss a',
        //width: 250,
        caption: 'Begin Time',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'end_time',
        dataType: 'date',
        format: 'MM/dd/yyyy hh:mm:ss a',
        //width: 250,
        caption: 'End Time',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'totalTimeMinutes',
        dataType: 'number',
        //width: 150,
        caption: 'Total Time Minutes',
        format: '#,##0.000',
        headerCellTemplate: 'titleHeaderTemplate'
      }
    ];
    /*
    this.grid.summary = {
      groupItems: [
        {
          column: 'contribution',
          summaryType: 'sum',
          showInGroupFooter: false,
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'allocationHigh',
          summaryType: 'sum',
          showInGroupFooter: false,
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'allocationLow',
          summaryType: 'sum',
          showInGroupFooter: false,
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'selectionEffect',
          summaryType: 'sum',
          showInGroupFooter: false,
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'fundResidual',
          summaryType: 'sum',
          showInGroupFooter: false,
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'currencyReturn',
          summaryType: 'sum',
          showInGroupFooter: false,
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        }
      ],
      totalItems: [
        {
          column: 'contribution',
          summaryType: 'sum',
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'allocationHigh',
          summaryType: 'sum',
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'allocationLow',
          summaryType: 'sum',
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'selectionEffect',
          summaryType: 'sum',
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'fundResidual',
          summaryType: 'sum',
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'currencyReturn',
          summaryType: 'sum',
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        }
      ]
    }
    */

    this.generate();
  }

  private generate() {
    this.grid.showLoadingIndicator();
    this.detail.retrieveGrid(this.beginDate!, this.endDate!).subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.grid.dataSource = jsonObj;
      })
      .add(() => {
        this.grid.hideLoadingIndicator();
      });

    this.treeMap.groupNames = this.grid.getGroupNames();
    this.retrieveTreeMap();
  }

  generateClicked($event:any) {
    this.beginDate = this.dates.first.value as Date;
    this.endDate = this.dates.last.value as Date;
    this.generate();
  }

  drill() {
    this.retrieveTreeMap();
  }

  private retrieveTreeMap() {
    this.treeMap.showLoadingIndicator();
    this.detail.retrieveTreeMap(this.beginDate!, this.endDate!, this.treeMap.path, this.treeMap.groupNames).subscribe(
      data => {
        if (data) {
          let jsonObj = JSON.parse(data);
          this.treeMapList = jsonObj as AxTreeMapItem[];
          let item: AxTreeMapItem;
          let maxValue = 0;
          for (let ii = 0; ii < this.treeMapList.length; ii++) {
            item = this.treeMapList[ii];
            if (item.value > maxValue) {
              maxValue = item.value;
            }
          }

          this.treeMap.dataSource = this.treeMapList;
          // adjust range for colorizer
          //this.treeMap.colorizer.range = [0, maxValue];
        }
      })
      .add(() => {
        this.treeMap.hideLoadingIndicator();
      });
  }
}
