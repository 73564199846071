<div class="container content-block">
  <div class="row">
    <div class="col-sm-3"><h6>Cash Flow</h6></div>
    <div class="col-sm-3">
      <div class="dx-field">
        <div class="dx-field-label">Analysis Date</div>
        <div class="dx-field-value">
          <dx-date-box [value]="analysisDate"
                       type="date">
          </dx-date-box>
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="dx-field">
        <div class="dx-field-label">CUSIP</div>
        <div class="dx-field-value">
          <dx-text-box value=""
                       [showClearButton]="true"
                       valueChangeEvent="keyup"
                       (onValueChanged)="cusipChanged($event)">
            </dx-text-box>
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <dx-button stylingMode="contained"
                 type="normal"
                 text="Generate"
                 width="120"
                 (onClick)="generateClicked($event)"></dx-button>
    </div>
  </div>
</div>
<!--
<h2 class="content-block">Data Load Status
<div class="dx-field">
  <div class="dx-field-label">Begin Date</div>
  <div class="dx-field-value">
    <dx-date-box [value]="now"
                 type="date">
    </dx-date-box>
  </div>
</div>
</h2>

<p>Begin Date: {{this.beginDate | date:'shortDate'}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; End Date: {{this.endDate | date:'shortDate'}}</p>
-->

<div>
  <!--<app-chart></app-chart>-->
  <app-grid name="Cash Flow Summary"></app-grid>
</div>
