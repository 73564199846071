import { Component, ViewChild, ViewChildren, AfterViewInit, QueryList } from '@angular/core';
import { DxDateBoxComponent } from "devextreme-angular";
import { AxGridComponent, AxChartComponent, SeriesData } from '../../../shared/components';
import { SummaryService } from './summary.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements AfterViewInit {
  @ViewChild(DxDateBoxComponent) date!: DxDateBoxComponent;
  //@ViewChild(AxChartComponent) chart: AxChartComponent;
  @ViewChild(AxGridComponent) grid!: AxGridComponent;

  _date!: Date;
  _cusip!: string;
  get analysisDate(): Date {
    return this._date;
  }

  set analysisDate(date: Date) {
    this._date = date;
  }

  get cusip(): string {
    return this._cusip;
  }

  set cusip(cc: string) {
    this._cusip = cc;
  }

  constructor(
    public summary: SummaryService
  ) { }

  ngAfterViewInit() {
    /*
    this.chart.series = [
      {
        type: 'line',
        argumentField: 'date',
        valueField: 'val',
        name: 'Cash Flow'
      }
    ];

    this.chart.argumentAxis = {
      argumentType: 'datetime',
      tickInterval: {
        days: 1
      },
      label: {
        format: {
          type: 'shortDate'
        }
      }
    };
    */

    this.grid.columns = [
      {
        dataField: 'name',
        dataType: 'string',
        caption: 'CUSIP',
        format: '#,##0.000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'date',
        dataType: 'date',
        caption: 'Date',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'val',
        dataType: 'number',
        caption: 'Value',
        format: '#,##0.000',
        headerCellTemplate: 'titleHeaderTemplate'
      }
    ];
  }

  cusipChanged(data:any) {
    this.cusip = data.value;
  }

  generateClicked($event:any) {
    this.analysisDate = this.date.value as Date;
    this.generate();
  }

  private generate() {
    //this.chart.showLoadingIndicator();
    this.grid.showLoadingIndicator();
    this.summary.generate(this.analysisDate, this.cusip).subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        let dataObj = JSON.parse(jsonObj.data);
        //this.chart.dataSource = dataObj;
        this.grid.dataSource = dataObj;
      })
      .add(() => {
        //this.chart.hideLoadingIndicator();
        this.grid.hideLoadingIndicator();
      });
  }
}
