import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService, AppFormatService } from '../../../shared/services';

@Injectable({
    providedIn: 'root'
})
export class DetailService {

    constructor(private auth: AuthService, private appFormat: AppFormatService) { }

    retrieveGrid(beginDate: Date, endDate: Date): Observable<any> {
        let beginDateStr: string = '0';
        let endDateStr: string = '0';

        if (beginDate) {
            beginDateStr = this.appFormat.DateIntStr(beginDate);
        }

        if (endDate) {
            endDateStr = this.appFormat.DateIntStr(endDate);
        }

        let httpParams = new HttpParams()
            .set('beginDate', beginDateStr)
            .set('endDate', endDateStr);

        return this.auth.get('/api/DataLoadDetail/Get', httpParams);
    }

    retrieveTreeMap(beginDate: Date, endDate: Date, path: string[], grpNames: string[]): Observable<any> {
        let beginDateStr: string = '0';
        let endDateStr: string = '0';

        if (beginDate) {
            beginDateStr = this.appFormat.DateIntStr(beginDate);
        }

        if (endDate) {
            endDateStr = this.appFormat.DateIntStr(endDate);
        }

        let pathStr: string = '';
        if (path && path.length > 0) {
            pathStr = path.join(',');
        }

        let httpParams = new HttpParams()
            .set('beginDate', beginDateStr)
            .set('endDate', endDateStr)
            .set('itemName', 'rejected_count_1')
            .set('itemDesc', 'Rejected Count')
            .set('treeMapPath', pathStr)
            .set('grpNames', grpNames.join(','));

        return this.auth.get('/api/DataLoadDetail/GetTreeMap', httpParams);
    }
}
