import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { SecurityLookupService } from '../../security-lookup-master/security-lookup/security-lookup.service';
import { CdsComponent } from '../cds/cds.component';
import { BondComponent } from '../bond/bond.component';

enum Cusip {
  CDS = 633,
  BOND = 1
}
enum CusipIndex {
  CDS = 0,
  BOND = 1
}
@Component({
  selector: 'app-security-lookup',
  templateUrl: './security-lookup.component.html',
  styleUrls: ['./security-lookup.component.scss']
})
export class SecurityLookupComponent implements AfterViewInit {
  @ViewChild(CdsComponent) cds!: CdsComponent;
  @ViewChild(BondComponent) bond!: BondComponent;

  showCdsReferenceData: boolean = false;
  date!: Date;
  cusip: string;
  selectedIndex: number = 0;
  cusip_type: string = '';

  securityLookup() {
    this.securityLookupService.loading = true;
    this.securityLookupService.cusip = this.cusip;
    this.securityLookupService.date = this.date;

    this.securityLookupService.getSecurityModel().subscribe(data => {
      this.securityLookupService.parseSecurityModel(data)
    }).add(() => {
      switch(this.securityLookupService.securityModel.cusip_type) {
        case Cusip.BOND:
          this.selectedIndex = CusipIndex.BOND;
          this.cusip_type = 'Bond';
          this.bond.display(this.securityLookupService.securityModel, this.cusip, this.date);
          break;
        case Cusip.CDS:
          this.selectedIndex = CusipIndex.CDS;
          this.cusip_type = 'Credit Default Swap';
          this.cds.display(this.securityLookupService.securityModel, this.cusip, this.date);
          break;
        default:
          console.log('Asset not implemented');
          break;
      }
      this.securityLookupService.loading = false;
    })
  }

  handleDateChange(e: any) {
    this.date = e.value;
  }

  handleCusipChange(e: any) {
    this.cusip = e.value;
  }

  constructor(
    public securityLookupService: SecurityLookupService
  ) {
    if (securityLookupService.date) {
      this.date = securityLookupService.date;
    }
    this.cusip = securityLookupService.cusip;
  }

  ngAfterViewInit() {
    
  }

}
