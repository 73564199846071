import { Component, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { AxChartComponent } from 'src/app/shared/components';
import { SummaryService } from '../../summary/summary.service';
import { NGXLogger } from 'ngx-logger';
import { FactorService } from '../factor.service';
import { AppFormatService } from 'src/app/shared/services';

@Component({
  selector: 'app-factor-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements AfterViewInit {
  @ViewChild(AxChartComponent) chart!: AxChartComponent;

  @Output() onClick = new EventEmitter();

  constructor(
    public summary: SummaryService,
    private factor: FactorService,
    private logger: NGXLogger,
    private appFormat: AppFormatService
  ) {
  }

  ngAfterViewInit() {
    this.chart.series = [
      {
        type: 'bar',
        argumentField: 'category',
        valueField: 'p',
        name: 'Portfolio'
      },
      {
        type: 'bar',
        argumentField: 'category',
        valueField: 'b',
        name: 'Benchmark'
      },
      {
        type: 'bar',
        argumentField: 'category',
        valueField: 'd',
        name: 'Active'
      }
    ];

    this.chart.argumentAxis = {
      argumentType: 'string',
      inverted: true
    };

    this.chart.rotated = true;
    this.chart.customizeTooltipFunc = (arg) => {
      return {
        text: arg.argument + '(' + arg.seriesName + '): ' + this.appFormat.GuiNumber(arg.value)
      }
    };

    this.retrieve();
  }

  pointClickedHandler(e: any) {
    this.onClick.emit(e);
  }

  private retrieve() {
    this.chart.showLoadingIndicator();
    this.logger.debug("GFR Factor retrieveCategory started");
    this.factor.retrieveCategory().subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.chart.dataSource = jsonObj;
        this.logger.debug("GFR Factor retrieveCategory finished");
      })
      .add(() => {
        this.chart.hideLoadingIndicator();
      });
  }
}
