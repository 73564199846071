import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule } from './shared/components';
import { AuthService, ScreenService, AppConfigService, AppFormatService, ColorService, AppUtilService } from './shared/services';
import { AppRoutingModule } from './app-routing.module';
import { NGXLogger, LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { GlobalErrorHandler } from './error/global-error-handler';
import { ServerErrorInterceptor } from './error/server-error-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AxNotifyModule } from './shared/components/notify/notify.component';
import { Router } from '@angular/router';

//export function getBaseUrl() {
//  return document.getElementsByTagName('base')[0].href;
//}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    AppRoutingModule,
    LoggerModule.forRoot({
      level: !environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.OFF,
      serverLogLevel: NgxLoggerLevel.OFF
    }),
    AxNotifyModule
  ],
  providers: [
    DatePipe,
    AuthService,
    ScreenService,
    AppConfigService,
    /*
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appInfo: AppConfigService) => {
        return () => {
          //Make sure to return a promise!
          return appInfo.loadAppConfig();
        };
      }
    },
    //{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    { provide: 'AUTH_URL', useValue: 'http://localhost:44370' },
    { provide: 'API_URL', useValue: 'http://localhost:44340' },
    */
    AppFormatService,
    ColorService,
    AppUtilService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function(router: Router, authService: AuthService) {
        return new ServerErrorInterceptor(router, authService);
      },
      multi: true,
      deps: [Router, AuthService]
   }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
