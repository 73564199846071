import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { TtyaxiomParam, TtyaxiomRunnerService, FilePropData } from './ttyaxiom-runner.service';
import { NGXLogger } from 'ngx-logger';
import { AxGridComponent } from 'src/app/shared/components';
import { SignalRService } from 'src/app/shared/services/signal-r/signal-r.service';

@Component({
  selector: 'app-ttyaxiom-runner',
  templateUrl: './ttyaxiom-runner.component.html',
  styleUrls: ['./ttyaxiom-runner.component.scss']
})
export class TtyaxiomRunnerComponent implements AfterViewInit {
  @ViewChild(AxGridComponent) grid!: AxGridComponent;

  ttyaxiomParam: TtyaxiomParam;

  constructor(
    public ttyaxiomRunner: TtyaxiomRunnerService,
    private logger: NGXLogger,
    private signal_r: SignalRService
  ) {
    this.ttyaxiomParam = new TtyaxiomParam;
  }

  ngAfterViewInit(): void {
    this.signal_r.loadFunc = this.retrieveReportList.bind(this);
    this.grid.columns = [
      {
        dataField: 'name',
        //width: 90,
        caption: 'Download Report',
        headerCellTemplate: 'titleHeaderTemplate',
        alignment: 'center',
        cellTemplate: 'buttonCellTemplate'
      },
      {
        dataField: 'lastModified',
        dataType: 'datetime',
        //width: 250,
        caption: 'Report File Time',
        headerCellTemplate: 'titleHeaderTemplate',
        sortInex: 0,
        sortOrder: 'desc',
      }
    ];

    this.grid.cellButtonClicked.subscribe(evt => {
      this.onCellButtonClick(evt);
    });

    this.retrieveReportList(null);
  }

  private onCellButtonClick(event:any) {
    let data: FilePropData = event as FilePropData;
    this.ttyaxiomRunner.download(data.name);
  }

  public ttyaxiomRun() {
    this.logger.info('RSP File: ' + this.ttyaxiomParam.rspFile);
    this.ttyaxiomRunner.ttyaxiomParam = this.ttyaxiomParam;
    this.ttyaxiomRunner.loading = true;
    this.ttyaxiomRunner.run().subscribe();
  }

  public retrieveReportList(msg: any) {
    this.ttyaxiomRunner.retrieveDownloadList()
      .subscribe(
        data => {
          let jsonObj = JSON.parse(data);
          this.grid.dataSource = jsonObj as FilePropData[];
        })
      .add(() => {
        this.ttyaxiomRunner.loading = false;
      });
  }
}
