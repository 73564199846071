<div *ngIf="chartLocation == 1">
  <dx-chart id="pivotChart">
    <dxo-common-series-settings type="bar"></dxo-common-series-settings>
    <dxo-size [height]="200"></dxo-size>
    <dxo-adaptive-layout [width]="450"></dxo-adaptive-layout>
    <dxo-tooltip [enabled]="true" format="currency" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
  </dx-chart>
</div>

<dx-pivot-grid id="pivotGrid"
               [allowExpandAll]="true"
               [allowFiltering]="true"
               [showBorders]="true"
               [showColumnGrandTotals]="false"
               [showRowGrandTotals]="showRowGrandTotals"
               [showRowTotals]="false"
               [showColumnTotals]="false"
               [dataSource]="dataSource"
               (onCellPrepared)="cellPreparedHandler($event)"
               (onCellClick)="cellClickHandler($event)"
               (onContentReady)="contentReadyHandler($event)"
               (onInitialized)="initializedHandler($event)"
               (onExporting)="exportingHandler($event)">
  <!--
                 [allowSortingBySummary]="true"
               -->
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-field-chooser [enabled]="false"></dxo-field-chooser>
  <dxo-scrolling mode="virtual"
                 showScrollbar="always">
  </dxo-scrolling>
  </dx-pivot-grid>

  <div *ngIf="chartLocation == 2">
    <dx-chart id="pivotChart">
      <dxo-common-series-settings type="bar"></dxo-common-series-settings>
      <dxo-size [height]="200"></dxo-size>
      <dxo-adaptive-layout [width]="450"></dxo-adaptive-layout>
      <dxo-tooltip [enabled]="true" format="currency" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
    </dx-chart>
  </div>
