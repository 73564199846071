export enum ColumnType {
  String = 1,
  Int,
  Decimal,
  Date,
  Time
}

export enum CalcType {
  Sum = 0,
  Min = 1,
  Max = 2,
  Count = 3,
  Average = 4,
  Custom = 5,
  None = 6
}

export enum DisplayType {
  None,
  Show,
  Hide
};
