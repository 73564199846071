<h6 class="content-block">Global Factor Risk</h6>
<p>
  Report ID: <strong>{{this.summary.reportId}}</strong>
  &nbsp;&nbsp;&nbsp; Portfolio: <strong>{{this.summary.portfolioName}}</strong>
  &nbsp;&nbsp;&nbsp; Benchmark: <strong>{{this.summary.benchmarkName}}</strong>
  &nbsp;&nbsp;&nbsp; Date: <strong>{{this.summary.analysisDate | date: 'shortDate'}}</strong>
</p>

<div>
  <ax-drill-down-bar (onDrill)="drill()"></ax-drill-down-bar>
  <dx-multi-view #multiview
                 [selectedIndex]="0"
                 [loop]="false"
                 [animationEnabled]="false"
                 (onSelectionChanged)="selectionChanged($event)">
    <dxi-item>
      <app-factor-category (onClick)="categoryClicked($event)"></app-factor-category>
    </dxi-item>
    <dxi-item>
      <app-factor-currency (onClick)="currencyClicked($event)"
                           (onLoad)="currencyLoaded()">
      </app-factor-currency>
    </dxi-item>
    <dxi-item>
      <app-factor-name (onClick)="nameClicked($event)"
                       (onLoad)="nameLoaded()">
      </app-factor-name>
    </dxi-item>
    <dxi-item>
      <app-factor-security (onClick)="securityClicked($event)"
                           (onLoad)="securityLoaded()"></app-factor-security>
    </dxi-item>
    <dxi-item>
      <app-factor-detail (onLoad)="detailLoaded()"></app-factor-detail>
    </dxi-item>
  </dx-multi-view>
  <app-factor-history [hidden]="selectedIndex < 2 || selectedIndex > 3"></app-factor-history>
</div>

<!--

<app-factor-history *ngIf="selectedIndex > 1"></app-factor-history>
    <dxi-item>
      <app-factor-category (onClick)="categoryClicked($event)"></app-factor-category>
    </dxi-item>
    <dxi-item *ngIf="hasCurrency">
      <h1> testing </h1>
    </dxi-item>
    <dxi-item *ngIf="hasName">
      <app-factor-name (onLoad)="nameLoaded()"></app-factor-name>
    </dxi-item>
    -->
<!--
       [dataSource]="viewItems"
        <div *dxTemplate="let vi of 'item'">
          <div *ngIf="vi.path.length == 1">
            <app-factor-category (onCategoryClick)="categoryClicked($event)"></app-factor-category>
          </div>
          <div *ngIf="vi.path.length == 2">
            <app-factor-currency></app-factor-currency>
          </div>
        </div>
  -->
