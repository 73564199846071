import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { formatNumber } from 'devextreme/localization';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { Observable } from 'rxjs';
import { ChartLocation } from '../../../shared/components/pivot-grid/pivot-grid.component';
import { AppUtilService, AuthService } from '../../../shared/services';
import { SummaryService } from './summary.service';

@Injectable()
export class SummaryPivotService {
  private readonly TYPE_FUND = 'fund';
  private readonly TYPE_COMPOSITE = 'composite';
  private readonly compNumSep = '^|';
  private readonly expandedColumnPath = ['Marginal Contributions To Portfolio Risk'];

  // header1 is for sorting only
  private readonly header1: string[] = [
    'market value',
    'component portfolio weight',
    'component portfolio risk',
    'component benchmark risk',
    'component benchmark tracking error',
    'total benchmark tracking error',
    'marginal contributions to portfolio risk',
    'marginal contributions to benchmark risk',
    'marginal contributions to tracking error',
    'contributions to total risk(%)',
    'contributions to tracking error(%)',
    'daily relative var vs. benchmark(95%)'
  ];

  private readonly noTotalColumns: string[] = [
    'component portfolio risk',
    'component benchmark risk',
    'component benchmark tracking error',
    'total benchmark tracking error',
    'daily relative var vs. benchmark(95%)'
  ];

  public tabs: FundCompTab[] = [];
  private fundTab: FundCompTab;
  private compositeTab: FundCompTab;
  private initExpandedFund: boolean = true;
  private initExpandedComposite: boolean = true;

  constructor(
    private auth: AuthService,
    private summary: SummaryService
  ) {
    this.fundTab = {
      name: 'Fund',
      dataSource: this.buildDataSource([]),
      onCellPrepared: this.onCellPreparedFund.bind(this),
      onCellClick: this.onCellClickFund.bind(this),
      onContentReady: this.onContentReadyFund.bind(this),
      chartLocation: ChartLocation.Down
    };

    this.compositeTab = {
      name: 'Composite',
      dataSource: this.buildDataSource([]),
      onCellPrepared: this.onCellPreparedComposite.bind(this),
      onCellClick: this.onCellClickComposite.bind(this),
      onContentReady: this.onContentReadyComposite.bind(this),
      chartLocation: ChartLocation.Down
    };

    this.tabs.push(this.fundTab);
    this.tabs.push(this.compositeTab);
  }

  public retrieve(): Observable<any> {
    let httpParams = new HttpParams()
      .set('reportId', '' + this.summary.reportId);

    return this.auth.get('/api/FofSummaryPivot/Get', httpParams);
  }

  private buildDataSource(cells: FofPivotCell[]) {
    return new PivotGridDataSource({
      remoteOperations: false,
      fields: [{
        dataField: 'cg1',
        sortingMethod: this.customColumnSort1.bind(this),
        wordWrapEnabled: true,
        area: 'column'
      }, {
        dataField: 'cg2',
        //sortingMethod: this.customColumnSort2.bind(this),
        wordWrapEnabled: true,
        customizeText: this.customizeText.bind(this),
        area: 'column'
      }, {
        caption: 'rg1',
        dataField: 'rg1',
        //selector: this.selector1.bind(this),
        //customizeText: this.customizeText.bind(this),
        //sortingMethod: this.customRowSort.bind(this),
        customizeText: this.customizeText.bind(this),
        area: 'row'
      },
      {
        caption: 'Total',
        dataField: 'val',
        dataType: 'number',
        summaryType: 'sum',
        format: '#,##0.00000',
        //calculateCustomSummary: this.customSummary,
        //calculateSummaryValue: this.calcSummaryValue.bind(this),
        area: 'data'
      }],
      store: cells
    });
  }

  private customizeText(e:any) {
    let text: string = e.valueText;
    var str = text.split(this.compNumSep);
    return str[0];
  }

  public buildCells(data: any[]) {
    let fundCells: FofPivotCell[] = [];
    let compositeCells: FofPivotCell[] = [];

    data.forEach(item => {
      if (item.totBenchDataSet.toLowerCase() == this.TYPE_FUND.toLowerCase()) {
        fundCells.push(item);
      }
      if (item.totBenchDataSet.toLowerCase() == this.TYPE_COMPOSITE.toLowerCase()) {
        compositeCells.push(item);
      }
    });

    this.fundTab.dataSource = this.buildDataSource(fundCells);
    this.compositeTab.dataSource = this.buildDataSource(compositeCells);
  }

  private isGroupColumn(columnName: string, type: string): boolean {
    if (columnName.toLowerCase().includes('marginal contributions to portfolio risk')) {
      return true;
    } else if (columnName.toLowerCase().includes('marginal contributions to benchmark risk')) {
      if (type.toLowerCase() === this.TYPE_COMPOSITE) {
        return true;
      }
    }

    return false;
  }

  private isIntegerColumn(columnName: string): boolean {
    if (columnName.toLowerCase().includes('market value')) {
      return true;
    } else {
      return false;
    }
  }

  private hasNoTotalColumn(columnName: string): boolean {
    for (const item of this.noTotalColumns) {
      if (columnName.toLowerCase().includes(item)) {
        return true;
      }
    }

    return false;
  }

  private customColumnSort(a:any, b:any, hdrs: string[]): number {
    let aIdx = hdrs.findIndex((e) => e.includes(a.value.toLowerCase()));
    let bIdx = hdrs.findIndex((e) => e.includes(b.value.toLowerCase()));
    return aIdx - bIdx;
  }

  private customColumnSort1(a:any, b:any): number {
    return this.customColumnSort(a, b, this.header1);
  }

  private noColumnExpand(cell:any, type: string): boolean {
    if (cell == null || cell.path == null || cell.path.lenght == 0) {
      return true;
    }

    if (this.isGroupColumn(cell.path[0], type)) {
      return false;
    } else {
      return true;
    }
  }

  private initExpand(type: string) {
    switch (type.toLowerCase()) {
      case this.TYPE_FUND:
        if (this.initExpandedFund && this.fundTab && this.fundTab.dataSource) {
          this.initExpandedFund = false;
          this.fundTab.dataSource.expandHeaderItem('column', this.expandedColumnPath);
        }
        break;
      case this.TYPE_COMPOSITE:
        if (this.initExpandedComposite && this.compositeTab && this.compositeTab.dataSource) {
          this.initExpandedComposite = false;
          this.compositeTab.dataSource.expandHeaderItem('column', this.expandedColumnPath);
        }
        break;
      default:
        break;
    }
  }

  private onCellPreparedFund(evt:any) {
    this.onCellPrepared(evt, this.TYPE_FUND);
  }

  private onCellPreparedComposite(evt:any) {
    this.onCellPrepared(evt, this.TYPE_COMPOSITE);
  }

  private onCellClickFund(evt:any) {
    this.onCellClick(evt, this.TYPE_FUND);
  }

  private onCellClickComposite(evt:any) {
    this.onCellClick(evt, this.TYPE_COMPOSITE);
  }

  private onContentReadyFund(evt:any) {
    this.onContentReady(evt, this.TYPE_FUND);
  }

  private onContentReadyComposite(evt:any) {
    this.onContentReady(evt, this.TYPE_COMPOSITE);
  }

  private onCellPrepared(evt:any, type: string) {
    if (evt.area == 'column') {
      if (this.noColumnExpand(evt.cell, type)) {
        // set cursor to default and remove expand button
        let parent = evt.cellElement;
        parent.style.cursor = 'default';
        let children: any[] = parent.querySelectorAll('.dx-expand-icon-container');
        children.forEach(e => e.parentNode.removeChild(e));
      }
    }

    if (evt.area == 'data') {
      let cell = evt.cell;
      if (
        cell != null &&
        cell.columnPath != null &&
        cell.columnPath.lenght != 0
      ) {
        let colName = cell.columnPath[0];
        let cellElement = evt.cellElement;
        if (cellElement == null) {
          return;
        }

        if (this.isIntegerColumn(colName)) {
          cellElement.innerText = formatNumber(cell.value, 'fixedPoint');
        }

        if (cell.rowType == 'GT') {
          // if it's grand totlal row, check if needs to show
          if (this.hasNoTotalColumn(colName)) {
            cellElement.innerText = '';
          }
        }
      }
    }
  }

  private onCellClick(evt:any, type: string) {
    if (evt.area == 'column') {
      if (this.noColumnExpand(evt.cell, type)) {
        // disable click
        evt.cancel = true;
      }
    }
  }

  private onContentReady(evt:any, type: string) {
    this.initExpand(type);
  }
}

class FofPivotCell {
  rowid!: number;
  totBenchDataSet!: string;
  cg1!: string;
  cg2!: string;
  rg1!: string;
  val!: number;
}

class FundCompTab {
  name!: string;
  dataSource!: PivotGridDataSource;
  onCellPrepared!: Function;
  onCellClick!: Function;
  onContentReady!: Function;
  chartLocation!: ChartLocation;
}
