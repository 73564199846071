import { Injectable } from "@angular/core";
import { IDropdownCodes, IDropdownMaps, IDropdownValues, ISecurityModel, ISecurityParams, SecurityLookupService } from "../security-lookup/security-lookup.service";
import { Observable } from "rxjs/internal/Observable";
import { HttpParams } from "@angular/common/http";
import { AuthService } from "src/app/shared/services";

export interface IBondFormModel {
    description: string | null; //
    coupon: number | null; // 
    maturity: Date | null; // 
    sector: string | null; //
    sector2: string;
    moody: string; //
    sandp: string; // 
    dated_date: Date | null; //
    first_coupdate: Date | null; // 
    pmt_freq: string | null; // 
    pmt_basis: string | null; //
    price: number | null;
    // amt_issued
    // outstanding
}

export interface IBondResults {
    yte: string;
    edur: string;
    ed2: string;
    ed3: string;
    spd_edur: string;
    ytm: string;
    dtm: string;
    convex: string;
    spread: string;
    avg_life: string;
}


@Injectable()
export class BondService {

    private shortPad: number = -32768;
    private intPad: number = -2147483647;
    private floatPad: number = Number.MIN_SAFE_INTEGER;
    private _bondFormModel: IBondFormModel;
    private _securityModel: ISecurityModel;
    private _dropdownCodes: IDropdownCodes;
    private dropdownMaps: IDropdownMaps;
    private _dropdownValues: IDropdownValues;
    private _date: Date = new Date();
    private _cusip: string = '';
    private _showBond: boolean = false;
    private _bondResults: IBondResults;
    private _showResults: boolean = false;
    private securityParams: ISecurityParams;
    private _loading: boolean = true;
    // Need to import securitymodel here 
    // How to import date and cusip, from display method?

    get date(): Date {
        return this._date;
    }

    set date(date: Date) {
        this._date = date;
    }

    get showBond(): boolean {
        return this._showBond;
    }

    get loading(): boolean {
        return this._loading;
    }

    set loading(loading: boolean) {
        this._loading = loading;
    }

    get bondResults(): IBondResults {
        return this._bondResults;
    }

    get showResults(): boolean {
        return this._showResults;
    }

    get cusip(): string {
        return this._cusip;
    }

    set cusip(cusip: string) {
        this._cusip = cusip;
    }

    get dropdownCodes(): IDropdownCodes {
        return this._dropdownCodes;
    }

    set dropdownCodes(dropdownCodes: IDropdownCodes) {
        this._dropdownCodes = dropdownCodes;
    }

    get dropdownValues(): IDropdownValues {
        return this._dropdownValues;
    }

    get securityModel() {
        return this._securityModel;
    }

    set securityModel(securityModel: ISecurityModel) {
        this._securityModel = securityModel;
    }

    get bondFormModel() {
        return this._bondFormModel;
    }

    set bondFormModel(bondFormModel: IBondFormModel) {
        this._bondFormModel = bondFormModel;
    }


    constructor(
        private securityLookupService: SecurityLookupService,
        private auth: AuthService
    ) {
        this._bondFormModel = {} as IBondFormModel;
        this._securityModel = {} as ISecurityModel;
        this._dropdownCodes = {} as IDropdownCodes;
        this.dropdownMaps = {} as IDropdownMaps;
        this._dropdownValues = {} as IDropdownValues;
        this._bondResults = {} as IBondResults;
        this.securityParams = {} as ISecurityParams;
    }

    display(securityModel: ISecurityModel, cusip: string, date: Date) {
        this._loading = true;
        this._securityModel = securityModel;
        this._cusip = cusip;
        this._date = date;
        this.getDropdowns().subscribe(data => {
            this.setDropdowns(data)
        }).add(() => {
            this.mapSecurityModelToFormData();
            this._showBond = true;
            this._loading = false;
        })


    }

    getDropdowns(): Observable<any> {
        let httpParams = new HttpParams()
            .set('date', '' + this.securityLookupService.dateToNum(this.date!))
            .set('cusip', '' + this.cusip);

        return this.auth.get('/api/SecurityLookup/GetBondCdsDropdowns', httpParams);
    }

    setDropdowns(data: any) {
        this._dropdownCodes = JSON.parse(data) as IDropdownCodes;

        this.dropdownMaps.sectorDescName = new Map<string, string>();
        this.dropdownMaps.sectorNameDesc = new Map<string, string>();
        this.dropdownMaps.pmtBasisCodeName = new Map<number, string>();
        this.dropdownMaps.pmtBasisNameCode = new Map<string, number>();
        this.dropdownMaps.pmtFreqCodeName = new Map<number, string>();
        this.dropdownMaps.pmtFreqNameCode = new Map<string, number>();

        for (let i = 0; i < this.dropdownCodes.sectorDesc.length; i++) {
            this.dropdownMaps.sectorDescName.set(this.dropdownCodes.sectorDesc[i], this.dropdownCodes.sectorNames[i]);
            this.dropdownMaps.sectorNameDesc.set(this.dropdownCodes.sectorNames[i], this.dropdownCodes.sectorDesc[i]);
        }

        for (let i = 0; i < this.dropdownCodes.pmtBasisNames.length; i++) {
            this.dropdownMaps.pmtBasisCodeName.set(this.dropdownCodes.pmtBasisCodes[i], this.dropdownCodes.pmtBasisNames[i]);
            this.dropdownMaps.pmtBasisNameCode.set(this.dropdownCodes.pmtBasisNames[i], this.dropdownCodes.pmtBasisCodes[i]);
        }

        for (let i = 0; i < this.dropdownCodes.pmtFreqNames.length; i++) {
            this.dropdownMaps.pmtFreqCodeName.set(this.dropdownCodes.pmtFreqCodes[i], this.dropdownCodes.pmtFreqNames[i]);
            this.dropdownMaps.pmtFreqNameCode.set(this.dropdownCodes.pmtFreqNames[i], this.dropdownCodes.pmtFreqCodes[i]);
        }
    }

    calculate() {
        this.bondFormModel = this.bondFormModel
        this._loading = true;
        this.getResults().subscribe(data => {
            this.parseResults(data);
        }).add(() => {
            this._showResults = true;
            this._loading = false;
        })
        
    }

    getResults(): Observable<any> {
        this.securityParams.cusip = this.cusip;
        this.securityParams.date = this.securityLookupService.dateToNum(this.date)!;
        this.mapFormDataToSecurityModel();
        this.securityParams.securityModel = this._securityModel;
        return this.auth.post('/api/SecurityLookup/CalculateBondCds', this.securityParams);
    }

    save(): Observable<any> {
        this.securityParams.cusip = this.cusip;
        this.securityParams.date = this.securityLookupService.dateToNum(this.date)!;
        this.mapFormDataToSecurityModel();
        this.securityParams.securityModel = this._securityModel;
        return this.auth.post('/api/SecurityLookup/Set', this.securityParams);
    }

    parseResults(data: any) {
        this._bondResults = JSON.parse(data) as IBondResults;
    }

    mapSecurityModelToFormData() {
        this._bondFormModel.description = this.securityModel.description;
        this._bondFormModel.coupon = this.securityLookupService.validNum(this.securityModel.coupon);
        this._bondFormModel.maturity = this.securityLookupService.numToDate(this.securityModel.maturity!);
        if (this.dropdownMaps.sectorNameDesc.has(this.securityModel.sector!)) this._bondFormModel.sector = this.dropdownMaps.sectorNameDesc.get(this.securityModel.sector!)!;
        else this._bondFormModel.sector = null; //necessary?
        this._bondFormModel.sector2 = this.securityModel.sector2;
        this._bondFormModel.moody = this.securityModel.moody;
        this._bondFormModel.sandp = this.securityModel.sandp;
        this._bondFormModel.dated_date = this.securityLookupService.numToDate(this.securityModel.dated_date!);
        this._bondFormModel.first_coupdate = this.securityLookupService.numToDate(this.securityModel.first_coupdate!);
        if (this.dropdownMaps.pmtFreqCodeName.has(this.securityModel.pmt_freq!)) this._bondFormModel.pmt_freq = this.dropdownMaps.pmtFreqCodeName.get(this.securityModel.pmt_freq!)!;
        else this._bondFormModel.pmt_freq = null;
        if (this.dropdownMaps.pmtBasisCodeName.has(this.securityModel.pmt_basis!)) this._bondFormModel.pmt_basis = this.dropdownMaps.pmtBasisCodeName.get(this.securityModel.pmt_basis!)!;
        else this._bondFormModel.pmt_basis = null;
        this._bondFormModel.price = this.securityLookupService.validNum(this.securityModel.price);

        // Reference Data


        // Set initial dropdown values
        this._dropdownValues.sector = this._bondFormModel.sector;
        this._dropdownValues.moody = this._bondFormModel.moody;
        this._dropdownValues.sandp = this._bondFormModel.sandp;
        this._dropdownValues.pmtBasis = this._bondFormModel.pmt_basis;
        this._dropdownValues.pmtFreq = this._bondFormModel.pmt_freq;
    }


    mapFormDataToSecurityModel() {
        this.securityModel.description = this._bondFormModel.description;

        if (this._bondFormModel.coupon == null) this.securityModel.coupon = this.floatPad;
        else this.securityModel.coupon = this._bondFormModel.coupon;

        if (this._bondFormModel.maturity == null) this.securityModel.maturity = this.intPad;
        else this.securityModel.maturity = this.securityLookupService.dateToNum(this._bondFormModel.maturity);

        this.securityModel.sector = this.dropdownMaps.sectorDescName.get(this._bondFormModel.sector!)!;

        this.securityModel.sector2 = this._bondFormModel.sector2;

        this.securityModel.moody = this._bondFormModel.moody;

        this.securityModel.sandp = this._bondFormModel.sandp;

        this.securityModel.dated_date = this.securityLookupService.dateToNum(this._bondFormModel.dated_date!);

        this.securityModel.first_coupdate = this.securityLookupService.dateToNum(this._bondFormModel.first_coupdate!);

        this.securityModel.pmt_freq = this.dropdownMaps.pmtFreqNameCode.get(this._bondFormModel.pmt_freq!)!;
        if (this.securityModel.pmt_freq == null) this.securityModel.pmt_freq = this.shortPad;

        this.securityModel.pmt_basis = this.dropdownMaps.pmtBasisNameCode.get(this._bondFormModel.pmt_basis!)!;
        if (this.securityModel.pmt_basis == null) this.securityModel.pmt_basis = this.intPad;

        if (this._bondFormModel.price == null) this.securityModel.price = this.floatPad;
        else this.securityModel.price = this._bondFormModel.price;

    }
}