<div class="newTabTooltipHide">New Tab</div>
<div class="drop-container ax-panel"
     (dragleave)="dragLeaveHandler($event)">
  <dx-sortable class="drop-container"
               *ngIf="historyTabs.length > 0; else dragInstruction"
               filter=".dx-tab"
               [data]="historyTabs"
               itemOrientation="horizontal"
               (onDragStart)="onTabDragStart($event)"
               (onReorder)="onTabDrop($event)">
    <dx-tab-panel id="tabPanelPopOver"
                  [dataSource]="historyTabs"
                  itemTitleTemplate="title"
                  itemTemplate="item"
                  [deferRendering]="false"
                  [showNavButtons]="true"
                  [repaintChangesOnly]="true"
                  [animationEnabled]="true"
                  [(selectedIndex)]="selectedIndex"
                  (dragover)="emptyTabDragOverHandler($event)"
                  (drop)="newDropHandler($event)">
      <div *dxTemplate="let content of 'title'"
           [appHistoryTabObj]="content"
           (onDragOver)="tabDragOverHandler($event)"
           (drop)="updateDropHandler($event)">
        <span>
          {{content.name}}
        </span>
        <i class="dx-icon dx-icon-close" (click)="closeButtonHandler(content)"></i>
      </div>
      <div *dxTemplate="let hItem of 'item'"
           (dragover)="tabContentDragOverHandler($event)"
           (drop)="updateDropHandler($event)">
        <app-chart [dataSource]="hItem.dataSource"
                   [series]="hItem.series"
                   [argumentAxis]="argumentAxis"
                   [valueAxis]="valueAxis">
        </app-chart>
      </div>
    </dx-tab-panel>
  </dx-sortable>
</div>

<ng-template #dragInstruction>
  <div class="drop-instr-container"
       (dragover)="dragOverHandler($event)"
       (drop)="newDropHandler($event)">
    <strong>Drag a value here to see the time series chart</strong>
    <br />
    <p>Available columns are Component Return Contribution, Factor Value, and Weighted Exposure</p>
  </div>
</ng-template>
