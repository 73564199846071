import { Component, NgModule, Output, Input, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { DxTreeViewModule, DxTreeViewComponent, DxTreeViewTypes } from 'devextreme-angular/ui/tree-view';

import * as events from 'devextreme/events';
import { Router } from '@angular/router';
import { SideNavigationMenuService } from './side-navigation-menu.service';

@Component({
  selector: 'app-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss']
})
export class SideNavigationMenuComponent implements AfterViewInit, OnDestroy {
  @ViewChild(DxTreeViewComponent, { static: true })
  menu!: DxTreeViewComponent;

  @Output()
  selectedItemChanged = new EventEmitter<DxTreeViewTypes.ItemClickEvent>();

  @Output()
  openMenu = new EventEmitter<any>();

  @Input()
  items: any[] = [];

  private _selectedItem!: String;
  @Input()
  set selectedItem(value: String) {
    this._selectedItem = this.navServ.getDefaultPath(value.toString());

    if (this.menu.instance) {
      this.menu.instance.selectItem(this._selectedItem);
      //this.focusTreeViewSelectedNode();
    }
  }

  private _compactMode = false;
  @Input()
  get compactMode() {
    return this._compactMode;
  }
  set compactMode(val) {
    this._compactMode = val;

    if (!this.menu.instance) {
      return;
    }

    if (val) {
      this.menu.instance.collapseAll();
    } else {
      this.menu.instance.expandItem(this._selectedItem);
    }
  }

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private navServ: SideNavigationMenuService
  ) {
    this.navServ.menuObservable.subscribe(menuItems => {
      this.items = menuItems;
    });
  }

  onItemClick(event: DxTreeViewTypes.ItemClickEvent) {
    this.selectedItemChanged.emit(event);
  }

  ngAfterViewInit() {
    events.on(this.elementRef.nativeElement, 'dxclick', (e: Event) => {
      this.openMenu.next(e);
    });

    /*
    // select report library initially
    var items: any = this.menu.instance.option("items");
    items[0].selected = true; 
    this.menu.instance.option("items", items); 
    */
   
    this.router.navigate(['/report-library']);
  }

  ngOnDestroy() {
    events.off(this.elementRef.nativeElement, 'dxclick');
  }

  updateSelection(event: any) {
    const nodeClass = 'dx-treeview-node';
    const selectedClass = 'dx-state-selected';
    const leafNodeClass = 'dx-treeview-node-is-leaf';
    const element: HTMLElement = event.element;

    const rootNodes = element.querySelectorAll(`.${nodeClass}:not(.${leafNodeClass})`);
    Array.from(rootNodes).forEach(node => {
      node.classList.remove(selectedClass);
    });

    let item = event.itemData;
    let iconClass: string | undefined = undefined;
    if (item) {
      if(item.selected) {
        let path = this.navServ.getDefaultPath(item.path);
        iconClass = this.navServ.getReportIconClass(path);
      }
    }

    let selectedNode = element.querySelector(`.${nodeClass}.${selectedClass}`);
    if (!selectedNode && iconClass !== undefined) {
      selectedNode = element.querySelector(`.dx-icon.${iconClass}`);
    }

    while (selectedNode && selectedNode.parentElement) {
      // add selected state
      if (selectedNode.classList.contains(nodeClass)) {
        selectedNode.classList.add(selectedClass);
      }

      selectedNode = selectedNode.parentElement;
    }
  }
}

/*
private focusTreeViewSelectedNode() {
  var $selectedItem = $(".dx-treeview").find(".dx-treeview-node").find(".dx-state-selected");
  $(".dx-treeview").find(".dx-treeview-node").removeClass("dx-state-focused");
  $selectedItem.focus().addClass("dx-state-focused");
}
*/

@NgModule({
  imports: [ DxTreeViewModule ],
  declarations: [ SideNavigationMenuComponent ],
  exports: [ SideNavigationMenuComponent ],
  providers: [
    SideNavigationMenuService
  ]
})

export class SideNavigationMenuModule { }
