<h6 class="content-block">Returns Variance Summary</h6>
<p>
  Report ID: <strong>{{this.summary.reportId}}</strong>
  &nbsp;&nbsp;&nbsp; Portfolio: <strong>{{this.summary.portfolioName}}</strong>
  &nbsp;&nbsp;&nbsp; Benchmark: <strong>{{this.summary.benchmarkName}}</strong>
</p>

<div>
  <div class="ax-panel">
    <app-chart (seriesClicked)="seriesClicked($event)"
               [seriesClickable]="true"></app-chart>
  </div>
  <div class="ax-panel">
    <app-grid name="Returns Variance Summary"
              [showBorders]=false>
    </app-grid>
 </div>
</div>
<!-- heatmap testing
<h6 class="content-block">Returns Variance Summary</h6>
<p>Report ID: <strong>{{this.summary.reportId}}</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Portfolio: <strong>{{this.summary.portfolioName}}</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Benchmark: <strong>{{this.summary.benchmarkName}}</strong> </p>

<div>
  <app-chart (seriesClicked)="seriesClicked($event)"
             [seriesClickable]="true"></app-chart>
  <app-heatmap></app-heatmap>
</div>
-->
