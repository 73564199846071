<h6 class="content-block">Returns Variance Summary In-Depth</h6>
<p>
  Report ID: <strong>{{this.summary.reportId}}</strong>
  &nbsp;&nbsp;&nbsp; Portfolio: <strong>{{this.summary.portfolioName}}</strong>
  &nbsp;&nbsp;&nbsp; Benchmark: <strong>{{this.summary.benchmarkName}}</strong>
</p>

<div>
  <div class="ax-panel">
    <app-bubble-chart (onSeriesChange)="seriesChanged($event)">
    </app-bubble-chart>
    <div class="container-fluid">
      <div class="row">
        <div class="col play-button">
          <dx-button icon="/assets/svg/play.svg"
                     (onClick)="onPlayClick($event)">
          </dx-button>
        </div>
        <div class="col">
         <dx-slider width="98%"
                     [min]="0"
                     [max]="maxNumDays"
                     [value]="maxNumDays"
                     [showRange]="false"
                     (onValueChanged)="onValueChanged($event)">
            <dxo-tooltip [enabled]="true"
                         [format]="tooltipFormat"
                         showMode="always"
                         position="bottom">
            </dxo-tooltip>
          </dx-slider>
        </div>
      </div>
    </div>
  </div>
</div>
