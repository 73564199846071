import { Injectable } from '@angular/core';
import { RptParamService } from '../../../shared/services';

@Injectable()
export class SummaryService {
  private _reportId!: number;
  private _portfolioName!: string;
  private _benchmarkName!: string;
  private _analysisDate!: Date | null;

  get reportId(): number {
    if (!this._reportId) {
      this._reportId = this.rptParam.getReportId(this.rptParam.FOF);
    }

    return this._reportId;
  }

  set reportId(rid: number) {
    this._reportId = rid;
    this.rptParam.setReportId(this.rptParam.FOF, rid);
  }

  get portfolioName(): string {
    if (!this._portfolioName) {
      this._portfolioName = this.rptParam.getPortfolioName(this.rptParam.FOF);
    }

    return this._portfolioName;
  }

  set portfolioName(name: string) {
    this._portfolioName = name;
    this.rptParam.setPortfolioName(this.rptParam.FOF, name);
  }

  get benchmarkName(): string {
    if (!this._benchmarkName) {
      this._benchmarkName = this.rptParam.getBenchmarkName(this.rptParam.FOF);
    }

    return this._benchmarkName
  }

  set benchmarkName(name: string) {
    this._benchmarkName = name;
    this.rptParam.setBenchmarkName(this.rptParam.FOF, name);
  }

  get analysisDate(): Date | null {
    if (!this._analysisDate) {
      this._analysisDate = this.rptParam.getAnalysisDate(this.rptParam.FOF);
    }

    return this._analysisDate;
  }

  set analysisDate(date: Date) {
    this._analysisDate = date;
    this.rptParam.setAnalysisDate(this.rptParam.FOF, date);
  }

  constructor(
    private rptParam: RptParamService
  ) { }
}
