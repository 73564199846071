import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { RptParamService, AuthService, AppConfigService, AppFormatService } from 'src/app/shared/services';

@Injectable()

export class SummaryService {
  private _reportId!: number;
  private _portfolioName!: string;
  private _benchmarkName!: string;
  private _beginDate!: Date;
  private _endDate!: Date;
  private _analysisDate!: Date;
  private _seriesName!: string;

  get reportId(): number {
    if (!this._reportId) {
      this._reportId = this.rptParam.getReportId(this.rptParam.GFR);
    }

    return this._reportId;
  }

  set reportId(rid: number) {
    this._reportId = rid;
    this.rptParam.setReportId(this.rptParam.GFR, rid);
  }

  get portfolioName(): string {
    if (!this._portfolioName) {
      this._portfolioName = this.rptParam.getPortfolioName(this.rptParam.GFR);
    }

    return this._portfolioName;
  }

  set portfolioName(name: string) {
    this._portfolioName = name;
    this.rptParam.setPortfolioName(this.rptParam.GFR, name);
  }

  get benchmarkName(): string {
    if (!this._benchmarkName) {
      this._benchmarkName = this.rptParam.getBenchmarkName(this.rptParam.GFR);
    }

    return this._benchmarkName
  }

  set benchmarkName(name: string) {
    this._benchmarkName = name;
    this.rptParam.setBenchmarkName(this.rptParam.GFR, name);
  }

  get beginDate(): Date {
    if (!this._beginDate) {
      let date = this.rptParam.getBeginDate(this.rptParam.GFR);
      if (date) {
        this._beginDate = date;
      }
    }

    return this._beginDate;
  }

  set beginDate(date: Date) {
    this._beginDate = date;
    this.rptParam.setBeginDate(this.rptParam.GFR, date);
  }

  get endDate(): Date {
    if (!this._endDate) {
      let date = this.rptParam.getEndDate(this.rptParam.GFR);
      if (date) {
        this._endDate = date;
      }
    }

    return this._endDate;
  }

  set endDate(date: Date) {
    this._endDate = date;
    this.rptParam.setEndDate(this.rptParam.GFR, date);
  }

  get analysisDate(): Date {
    if (!this._analysisDate) {
      let date = this.rptParam.getAnalysisDate(this.rptParam.GFR);
      if (date) {
        this._analysisDate = date;
      }
    }

    return this._analysisDate;
  }

  set analysisDate(date: Date) {
    this._analysisDate = date;
    this.rptParam.setAnalysisDate(this.rptParam.GFR, date);
  }

  get seriesName(): string {
    if (!this._seriesName) {
      this._seriesName = this.rptParam.getSeriesName(this.rptParam.GFR);
    }

    return this._seriesName;
  }

  set seriesName(name: string) {
    this._seriesName = name;
    this.rptParam.setSeriesName(this.rptParam.GFR, name);
  }

  constructor(
    private auth: AuthService,
    private rptParam: RptParamService,
    private appFormat: AppFormatService
  ) { }

  retrieve(): Observable<any> {
    let httpParams = new HttpParams()
      .set('reportId', '' + this.reportId)
      .set('date', this.appFormat.DateIntStr(this.beginDate));;

    return this.auth.get('/api/GfrSummary/Get', httpParams);
  }
}
