import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from './error.service';
import { NotificationService } from './notification.service';
import { NGXLogger } from 'ngx-logger';
import { NotifyType } from '../shared/components/notify/notify.component';
import { AuthService } from '../shared/services';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private injector: Injector,
    private logger: NGXLogger
  ) { }

  handleError(error: Error | HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const notifier = this.injector.get(NotificationService);
    const authService = this.injector.get(AuthService);
    let message;
    //let stackTrace;
    let showServerErr = false;
    if (error instanceof HttpErrorResponse) {
      showServerErr = true;
      //stackTrace = errorService.getServerErrorStackTrace(error);
    } else {
      if (!authService.isLoggedIn ||
        error.message.toLowerCase().search('network error') >= 0) {
        showServerErr = true;
      }

      // Client Error
      //message = errorService.getClientErrorMessage(error);
      //notifier.showError(message);
    }

    if (showServerErr) {
      // Server error
      message = errorService.getServerErrorMessage(error);
      notifier.showError(message);
    }

    // Always log errors
    this.logger.error(error);
    //this.logger.error(message, stackTrace);
  }
}
