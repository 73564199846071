import { Component, NgModule, ViewChild, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { DxChartModule, DxChartComponent } from 'devextreme-angular';
import { AppFormatService } from '../../services';

export class SeriesData {
  name: string = '';
  argumentField: string = '';
  valueField: string = '';
}

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class AxChartComponent implements AfterViewInit {
  @ViewChild(DxChartComponent) chart!: DxChartComponent;

  @Input() dataSource: any[] = [];
  @Input() series: any[] = [];
  @Input() argumentAxis: any;
  @Input() valueAxis: any;
  @Input() rotated: boolean = false;
  @Input() clickable = false;
  @Input() seriesClickable = false;
  @Input() customizeTooltipFunc = (arg:any) => {
    return {
      text: this.appFormat.GuiNumber(arg.value)
    }
  };

  @Output() pointClickedEvt = new EventEmitter();
  @Output() seriesClicked = new EventEmitter();

  constructor(private appFormat: AppFormatService) {
  }

  ngAfterViewInit() {
    this.chart.palette = 'Ocean';
  }

  // sometime the fields in dataSource and the one in series value field are only different in case sensitive
  // this functin will update the value field in series to match the dataSource field case sensitive
  public updateSeriesValueFieldFromDataSource(data: any, sdata: SeriesData[]) {
    if (data && Array.isArray(data) && data.length > 0
      && sdata && sdata.length > 0) {
      let keys = Object.keys(data[0]);
      for (let ss of sdata) {
        // we are going to change the valueField, keep the original valueField to the name field
        ss.name = ss.valueField;
        for (let kk of keys) {
          if (kk === ss.valueField) {
            continue;
          }

          if (kk.toLowerCase() == ss.valueField.toLowerCase()) {
            ss.valueField = kk;
          }
        }
      }
    }
  }
  public createSeries(sd: SeriesData[]) {
    if (sd == null || !Array.isArray(sd)) {
      return;
    }

    this.series = [];
    for (let ii = 0; ii < sd.length; ii++) {
      let ss = sd[ii];
      this.series.push({
        type: 'line',
        argumentField: ss.argumentField,
        valueField: ss.valueField,
        name: ss.name
      });
    }
  }

  pointClicked(e: any) {
    this.pointClickedEvt.emit(e);
  }

  showLoadingIndicator() {
    this.chart.instance.showLoadingIndicator();
  }

  hideLoadingIndicator() {
    this.chart.instance.hideLoadingIndicator();
  }

  resetVisualRange() {
    this.chart.instance.resetVisualRange();
  }

  onLegendClick(e: any) {
    let series = this.chart.instance.getSeriesByName(e.target.name);
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
  }

  onSeriesClick(e: any) {
    this.seriesClicked.emit(e);
  }

  onPointHoverChanged(e: any) {
    if (this.clickable) {
      if (e.target.isHovered()) {
        e.element.style.cursor = "pointer"
      } else {
        e.element.style.cursor = "auto"
      }
    }
  }

  onDrawn(e: any) {
    if (this.seriesClickable) {
      for (let ss of this.series) {
        // check if it's line series
        if (ss.type == 'line') {
          e.element.querySelectorAll(".dxc-series").forEach((el:any) => {
            el.style.cursor = "pointer";
          });
          break;
        }
      }
    }
    /*
    if (this.clickable) {
      for (let ss of this.series) {
        // check if it's bar series
        if (ss.type == 'bar') {
          e.element.querySelectorAll(".dxc-markers rect").forEach((el) => {
            el.style.cursor = "pointer";
          });
          break;
        }
      }
    }
    */
  }
}

@NgModule({
  declarations: [AxChartComponent],
  imports: [
    DxChartModule
  ],
  exports: [AxChartComponent]
})

export class AxChartModule { }
