import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//import { forEach } from '@angular/router/src/utils/collection';
import { SummaryService } from '../summary/summary.service';
import { DetailService } from '../detail/detail.service';
import { AxGridComponent, AxTreeMapComponent, AxTreeMapItem } from '../../../shared/components';
import { RptParamService } from 'src/app/shared/services';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})

export class DetailComponent implements AfterViewInit {
  @ViewChild(AxGridComponent) grid!: AxGridComponent;
  @ViewChild(AxTreeMapComponent) treeMap!: AxTreeMapComponent;

  private treeMapList!: AxTreeMapItem[];
  private treeMapPath!: string[];
  dataSet!: any;
  objId!: any;
  objName!: string;
  analysisDate!: Date;

  constructor(
    private detail: DetailService,
    private route: ActivatedRoute,
    public summary: SummaryService,
    private rptParam: RptParamService
  ) {
  }

  ngAfterViewInit() {
    this.grid.columns = [
      {
        dataField: 'group1',
        groupIndex: 0
      },
      {
        dataField: 'group2',
        groupIndex: 1
      },
      {
        dataField: 'beginDate',
        dataType: 'date',
        //width: 150,
        caption: 'Begin Date',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'endDate',
        dataType: 'date',
        //width: 150,
        caption: 'End Date',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'securityId',
        //width: 150,
        caption: 'Secuity',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'dataSet',
        //width: 90,
        caption: 'Data Set',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'coupon',
        dataType: 'number',
        //width: 150,
        caption: 'Coupon',
        format: '#,##0.000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'contribution',
        dataType: 'number',
        //width: 150,
        caption: 'Contribution',
        format: '#,##0.000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'allocationHigh',
        dataType: 'number',
        //width: 150,
        caption: 'Allocation High',
        format: '#,##0.000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'allocationLow',
        dataType: 'number',
        //width: 150,
        caption: 'Allocation Low',
        format: '#,##0.000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'selectionEffect',
        dataType: 'number',
        //width: 150,
        caption: 'Selection Effect',
        format: '#,##0.000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'fundResidual',
        dataType: 'number',
        //width: 150,
        caption: 'Fund Residual',
        format: '#,##0.000',
        headerCellTemplate: 'titleHeaderTemplate'
      },
      {
        dataField: 'currencyReturn',
        dataType: 'number',
        //width: 150,
        caption: 'Currency Return',
        format: '#,##0.000',
        headerCellTemplate: 'titleHeaderTemplate'
      }
    ];

    this.grid.summary = {
      groupItems: [
        {
          column: 'contribution',
          summaryType: 'sum',
          showInGroupFooter: false,
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'allocationHigh',
          summaryType: 'sum',
          showInGroupFooter: false,
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'allocationLow',
          summaryType: 'sum',
          showInGroupFooter: false,
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'selectionEffect',
          summaryType: 'sum',
          showInGroupFooter: false,
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'fundResidual',
          summaryType: 'sum',
          showInGroupFooter: false,
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'currencyReturn',
          summaryType: 'sum',
          showInGroupFooter: false,
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        }
      ],
      totalItems: [
        {
          column: 'contribution',
          summaryType: 'sum',
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'allocationHigh',
          summaryType: 'sum',
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'allocationLow',
          summaryType: 'sum',
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'selectionEffect',
          summaryType: 'sum',
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'fundResidual',
          summaryType: 'sum',
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        },
        {
          column: 'currencyReturn',
          summaryType: 'sum',
          displayFormat: '{0}',
          valueFormat: '#,##0.000',
          alignByColumn: true
        }
      ]
    }

    this.objId = this.route.snapshot.paramMap.get('reportId');
    this.dataSet = this.route.snapshot.paramMap.get('seriesName');
    let dateStr = this.route.snapshot.paramMap.get('date');

    if (dateStr == '0') {
      let date = this.summary.analysisDate;
      if (date) {
        this.analysisDate = date;
      }
    } else {
      this.analysisDate = new Date(dateStr!);
    }

    if (this.dataSet == '0') {
      this.dataSet = this.summary.seriesName;
    }

    this.objId = this.summary.reportId + '';
    if (this.dataSet == this.rptParam.PORTFOLIO) {
      this.objName = this.summary.portfolioName;
    } else if (this.dataSet == this.rptParam.BENCHMARK) {
      this.objName = this.summary.benchmarkName;
    }

    this.treeMap.hasHiddenSecurityGroup = true;
    this.treeMap.groupNames = this.grid.getGroupNames();
    this.retrieveTreeMap();

    this.grid.showLoadingIndicator();
    this.detail.retrieveGrid(+this.objId, this.analysisDate, this.dataSet).subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.grid.dataSource = jsonObj;
      })
      .add(() => {
        this.grid.hideLoadingIndicator();
      });
  }

  drill() {
    this.retrieveTreeMap();
  }

  private retrieveTreeMap() {
    this.treeMap.showLoadingIndicator();
    this.detail.retrieveTreeMap(+this.objId, this.analysisDate, this.dataSet, this.treeMap.path, this.treeMap.groupNames).subscribe(
      data => {
        if (data) {
          let jsonObj = JSON.parse(data);
          this.treeMapList = jsonObj as AxTreeMapItem[];
          let item: AxTreeMapItem;
          let maxValue = 0;
          for (let ii = 0; ii < this.treeMapList.length; ii++) {
            item = this.treeMapList[ii];
            if (item.value > maxValue) {
              maxValue = item.value;
            }
          }

          this.treeMap.dataSource = this.treeMapList;
        // adjust range for colorizer
        //this.treeMap.colorizer.range = [0, maxValue];
        }
      })
      .add(() => {
        this.treeMap.hideLoadingIndicator();
      });
  }

  /*
  onCustomizeTooltip(event) {
    if (event.node.data.realValue) {
      return {
        text: event.node.data.realValue.toFixed(3)
      }
    } else {
      return {
        text: event.value.toFixed(3)
      }
    }
  }
  */
}

