import { Injectable } from "@angular/core";
import { IDropdownCodes, IDropdownMaps, IDropdownValues, ISecurityModel, ISecurityParams, SecurityLookupService } from "../security-lookup/security-lookup.service";
import { Observable } from "rxjs/internal/Observable";
import { HttpParams } from "@angular/common/http";
import { AuthService } from "src/app/shared/services";

export interface ICdsFormModel {
    description: string | null;
    coupon: number | null;
    maturity: Date | null;
    recovery_rate: number | null;
    sector: string | null;
    sector2: string;
    moody: string;
    sandp: string;
    default_date: Date | null;
    dated_date: Date | null;
    first_coupdate: Date | null;
    pmt_freq: string | null;
    pmt_basis: string | null;
    recovery_amount: number | null;
    contract_size: number | null;
    country: number | null;
    prin_currency: number | null;
    underlying: string;
    use_reference_data: boolean;
    price: number | null;
    price2: number | null;
}

export interface ICdsResults {
    yte: string;
    edur: string;
    ed2: string;
    ed3: string;
    spd_edur: string;
    ytm: string;
    dtm: string;
    convex: string;
    spread: string;
    avg_life: string;
}


@Injectable()
export class CdsService {


    private shortPad: number = -32768;
    private intPad: number = -2147483647;
    private floatPad: number = Number.MIN_SAFE_INTEGER;
    private _cdsFormModel: ICdsFormModel;
    private _cdsResults: ICdsResults;
    private _securityModel: ISecurityModel;
    private _dropdownValues: IDropdownValues;
    private _dropdownMaps: IDropdownMaps;
    private securityParams: ISecurityParams;
    private _date: Date = new Date();
    private _cusip: string = '';
    private _dropdownCodes!: IDropdownCodes;
    private _showCds: boolean = false;
    private _loading: boolean = false;

    get date(): Date {
        return this._date;
    }

    set date(date: Date) {
        this._date = date;
    }

    get loading(): boolean {
        return this._loading;
    }

    set loading(loading: boolean) {
        this._loading = loading;
    }

    get showCds() {
        return this._showCds;
    }

    get cusip(): string {
        return this._cusip;
    }

    set cusip(cusip: string) {
        this._cusip = cusip;
    }

    get dropdownCodes(): IDropdownCodes {
        return this._dropdownCodes;
    }

    set dropdownCodes(dropdownCodes: IDropdownCodes) {
        this._dropdownCodes = dropdownCodes;
    }

    get dropdownMaps(): IDropdownMaps {
        return this._dropdownMaps;
    }

    set dropdownMaps(dropdownMaps: IDropdownMaps) {
        this._dropdownMaps = dropdownMaps;
    }

    get dropdownValues(): IDropdownValues {
        return this._dropdownValues;
    }

    set dropdownValues(dropdownValues: IDropdownValues) {
        this._dropdownValues = dropdownValues;
    }

    get securityModel() {
        return this._securityModel;
    }

    set securityModel(securityModel: ISecurityModel) {
        this._securityModel = securityModel;
    }

    get cdsFormModel() {
        return this._cdsFormModel;
    }

    set cdsFormModel(cdsFormModel: ICdsFormModel) {
        this._cdsFormModel = cdsFormModel;
    }

    get cdsResults() {
        return this._cdsResults;
    }

    constructor(
        private securityLookupService: SecurityLookupService,
        private auth: AuthService
    ) {
        this._cdsResults = {} as ICdsResults;
        this._cdsFormModel = {} as ICdsFormModel;
        this._securityModel = {} as ISecurityModel;
        this.securityParams = {} as ISecurityParams;
        this._dropdownMaps = {} as IDropdownMaps;
        this._dropdownValues = {} as IDropdownValues;
    }

    display(securityModel: ISecurityModel, cusip: string, date: Date) {
        this._securityModel = securityModel;
        this._cusip = cusip;
        this._date = date;
        this.getDropdowns().subscribe(data => {
            this.setDropdowns(data)
        }).add(() => {
            this.mapSecurityModelToFormData();
            this._showCds = true;
            this.loading = false;
        })
    }

    getDropdowns(): Observable<any> {
        let httpParams = new HttpParams()
            .set('date', '' + this.securityLookupService.dateToNum(this.date!))
            .set('cusip', '' + this.cusip);

        return this.auth.get('/api/SecurityLookup/GetBondCdsDropdowns', httpParams);
    }

    setDropdowns(data: any) {
        this._dropdownCodes = JSON.parse(data) as IDropdownCodes;

        this._dropdownMaps.sectorDescName = new Map<string, string>();
        this._dropdownMaps.sectorNameDesc = new Map<string, string>();
        this._dropdownMaps.pmtBasisCodeName = new Map<number, string>();
        this._dropdownMaps.pmtBasisNameCode = new Map<string, number>();
        this._dropdownMaps.pmtFreqCodeName = new Map<number, string>();
        this._dropdownMaps.pmtFreqNameCode = new Map<string, number>();

        for (let i = 0; i < this.dropdownCodes.sectorDesc.length; i++) {
            this._dropdownMaps.sectorDescName.set(this.dropdownCodes.sectorDesc[i], this.dropdownCodes.sectorNames[i]);
            this._dropdownMaps.sectorNameDesc.set(this.dropdownCodes.sectorNames[i], this.dropdownCodes.sectorDesc[i]);
        }

        for (let i = 0; i < this.dropdownCodes.pmtBasisNames.length; i++) {
            this._dropdownMaps.pmtBasisCodeName.set(this.dropdownCodes.pmtBasisCodes[i], this.dropdownCodes.pmtBasisNames[i]);
            this._dropdownMaps.pmtBasisNameCode.set(this.dropdownCodes.pmtBasisNames[i], this.dropdownCodes.pmtBasisCodes[i]);
        }

        for (let i = 0; i < this.dropdownCodes.pmtFreqNames.length; i++) {
            this._dropdownMaps.pmtFreqCodeName.set(this.dropdownCodes.pmtFreqCodes[i], this.dropdownCodes.pmtFreqNames[i]);
            this._dropdownMaps.pmtFreqNameCode.set(this.dropdownCodes.pmtFreqNames[i], this.dropdownCodes.pmtFreqCodes[i]);
        }
    }

    parseResults(data: any) {
        this._cdsResults = JSON.parse(data) as ICdsResults;
    }

    calculate(): Observable<any> {
        this.securityParams.cusip = this.cusip;
        this.securityParams.date = this.securityLookupService.dateToNum(this.date)!;
        this.mapFormDataToSecurityModel();
        this.securityParams.securityModel = this.securityModel;

        return this.auth.post('/api/SecurityLookup/CalculateBondCds', this.securityParams);
    }

    save(): Observable<any> {
        this.securityParams.cusip = this.cusip;
        this.securityParams.date = this.securityLookupService.dateToNum(this.date!)!;
        this.mapFormDataToSecurityModel();
        this.securityParams.securityModel = this._securityModel;
        return this.auth.post('/api/SecurityLookup/Set', this.securityParams);
    }

    mapSecurityModelToFormData() {
        this._cdsFormModel.description = this.securityModel.description;
        this._cdsFormModel.coupon = this.securityLookupService.validNum(this.securityModel.coupon);
        this._cdsFormModel.maturity = this.securityLookupService.numToDate(this.securityModel.maturity!);
        this._cdsFormModel.recovery_rate = this.securityLookupService.validNum(this.securityModel.recovery_rate);
        if (this._dropdownMaps.sectorNameDesc.has(this.securityModel.sector!)) this._cdsFormModel.sector = this._dropdownMaps.sectorNameDesc.get(this.securityModel.sector!)!;
        else this._cdsFormModel.sector = null; //necessary?
        this._cdsFormModel.sector2 = this.securityModel.sector2;
        this._cdsFormModel.moody = this.securityModel.moody;
        this._cdsFormModel.sandp = this.securityModel.sandp;
        this._cdsFormModel.default_date = this.securityLookupService.numToDate(this.securityModel.default_date!);
        this._cdsFormModel.dated_date = this.securityLookupService.numToDate(this.securityModel.dated_date!);
        this._cdsFormModel.first_coupdate = this.securityLookupService.numToDate(this.securityModel.first_coupdate!);
        // this._cdsFormModel.pmt_freq = this.validNum(this.securityModel.pmt_freq);
        // this._cdsFormModel.pmt_basis = this.securityModel.pmt_basis;
        if (this.dropdownMaps.pmtFreqCodeName.has(this.securityModel.pmt_freq!)) this._cdsFormModel.pmt_freq = this.dropdownMaps.pmtFreqCodeName.get(this.securityModel.pmt_freq!)!;
        else this._cdsFormModel.pmt_freq = null;
        if (this.dropdownMaps.pmtBasisCodeName.has(this.securityModel.pmt_basis!)) this._cdsFormModel.pmt_basis = this.dropdownMaps.pmtBasisCodeName.get(this.securityModel.pmt_basis!)!;
        else this._cdsFormModel.pmt_basis = null;
        this._cdsFormModel.recovery_amount = this.securityLookupService.validNum(this.securityModel.recovery_amount);
        this._cdsFormModel.contract_size = this.securityLookupService.validNum(this.securityModel.contract_size);
        this._cdsFormModel.country = this.securityLookupService.validNum(this.securityModel.country);
        this._cdsFormModel.prin_currency = this.securityLookupService.validNum(this.securityModel.prin_currency);
        this._cdsFormModel.underlying = this.securityModel.underlying;
        if (this.securityModel.use_reference_data == 0) this._cdsFormModel.use_reference_data = false;
        else this._cdsFormModel.use_reference_data = true;
        this._cdsFormModel.price = this.securityLookupService.validNum(this.securityModel.price);
        this._cdsFormModel.price2 = this.securityLookupService.validNum(this.securityModel.price2);

        // Reference Data


        // Set initial dropdown values
        this._dropdownValues.sector = this._cdsFormModel.sector;
        this._dropdownValues.moody = this._cdsFormModel.moody;
        this._dropdownValues.sandp = this._cdsFormModel.sandp;
        this._dropdownValues.pmtBasis = this._cdsFormModel.pmt_basis;
        this._dropdownValues.pmtFreq = this._cdsFormModel.pmt_freq;
    }

    mapFormDataToSecurityModel() {

        this.securityModel.description = this._cdsFormModel.description;

        if (this._cdsFormModel.coupon == null) this.securityModel.coupon = this.floatPad;
        else this.securityModel.coupon = this._cdsFormModel.coupon;

        if (this._cdsFormModel.maturity == null) this.securityModel.maturity = this.intPad;
        else this.securityModel.maturity = this.securityLookupService.dateToNum(this._cdsFormModel.maturity);

        if (this._cdsFormModel.recovery_rate == null) this.securityModel.recovery_rate = this.floatPad;
        else this.securityModel.recovery_rate = this._cdsFormModel.recovery_rate;

        this.securityModel.sector = this.dropdownMaps.sectorDescName.get(this._cdsFormModel.sector!)!;

        this.securityModel.sector2 = this._cdsFormModel.sector2;

        this.securityModel.moody = this._cdsFormModel.moody;

        this.securityModel.sandp = this._cdsFormModel.sandp;

        this.securityModel.default_date = this.securityLookupService.dateToNum(this._cdsFormModel.default_date!);

        this.securityModel.dated_date = this.securityLookupService.dateToNum(this._cdsFormModel.dated_date!);

        this.securityModel.first_coupdate = this.securityLookupService.dateToNum(this._cdsFormModel.first_coupdate!);

        this.securityModel.pmt_freq = this.dropdownMaps.pmtFreqNameCode.get(this._cdsFormModel.pmt_freq!)!;
        if (this.securityModel.pmt_freq == null) this.securityModel.pmt_freq = this.shortPad;

        this.securityModel.pmt_basis = this.dropdownMaps.pmtBasisNameCode.get(this._cdsFormModel.pmt_basis!)!;
        if (this.securityModel.pmt_basis == null) this.securityModel.pmt_basis = this.intPad;

        if (this._cdsFormModel.recovery_amount == null) this.securityModel.recovery_amount = this.floatPad;
        else this.securityModel.recovery_amount = this._cdsFormModel.recovery_amount;

        if (this._cdsFormModel.contract_size == null) this.securityModel.contract_size = this.floatPad;
        else this.securityModel.contract_size = this._cdsFormModel.contract_size;

        // this.securityModel.country = this._cdsFormModel.country;

        if (this._cdsFormModel.prin_currency == null) this.securityModel.prin_currency = this.shortPad;
        else this.securityModel.prin_currency = this._cdsFormModel.prin_currency;

        this.securityModel.underlying = this._cdsFormModel.underlying;

        if (this._cdsFormModel.use_reference_data) this.securityModel.use_reference_data = 1;
        else this.securityModel.use_reference_data = 0;

        if (this._cdsFormModel.price == null) this.securityModel.price = this.floatPad;
        else this.securityModel.price = this._cdsFormModel.price;

        if (this._cdsFormModel.price2 == null) this.securityModel.price2 = this.floatPad;
        else this.securityModel.price2 = this._cdsFormModel.price2;

    }
}