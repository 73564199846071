import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService, AppFormatService } from '../../../shared/services';

@Injectable({
  providedIn: 'root'
})
export class SummaryService {
  constructor(
    private auth: AuthService,
    private appFormat: AppFormatService
  ) {
  }

  generate(analysisDate: Date, cusip: string) {
    let dateStr: string = '0';

    if (analysisDate) {
      dateStr = this.appFormat.DateIntStr(analysisDate);
    }

    let httpParams = new HttpParams()
      .set('cusip', cusip)
      .set('date', dateStr);

    return this.auth.get('/api/NativeAccess/GetCashFlow', httpParams);
  }
}
