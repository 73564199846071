import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxTabsModule, DxDateBoxModule, DxButtonModule  } from 'devextreme-angular';
import { SummaryComponent } from './summary/summary.component';
import { SummaryService } from './summary/summary.service';
import { DetailService } from './detail/detail.service';
import { DetailComponent } from './detail/detail.component';
import { AxGridModule, AxChartModule, AxTreeMapModule } from '../../shared/components';

@NgModule({
  declarations: [SummaryComponent, DetailComponent],
  imports: [
    CommonModule,
    DxTabsModule,
    DxDateBoxModule,
    DxButtonModule,
    AxGridModule,
    AxChartModule,
    AxTreeMapModule
  ],
  providers: [SummaryService, DetailService ]
})
export class DataLoadModule { }
