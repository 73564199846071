import { Component } from '@angular/core';
import { HistoryTab, FACTOR_NAME_TIME_SERIES, FACTOR_SECURITY_TIME_SERIES } from 'src/app/shared/models/factor-history/history-tab';
import { HistoryTabService } from './history-tab.service';
import { FactorService } from '../factor.service';
import { AppFormatService } from 'src/app/shared/services';
import * as $ from 'jquery';

@Component({
  selector: 'app-factor-history',
  templateUrl: './history-tab.component.html',
  styleUrls: ['./history-tab.component.scss']
})
export class HistoryTabComponent {
  historyTabs: HistoryTab[] = [];
  argumentAxis: any;
  valueAxis: any;
  selectedIndex: number = -1;
  dataMap: Map<string, any> = new Map<string, any>();

  private dragOverExistingTab = false;
  private dropExistingTab = false;

  constructor(
    private factor: FactorService,
    private historyService: HistoryTabService,
    private appFormat: AppFormatService
  ) {
    this.argumentAxis = {
      argumentType: 'datetime',
      tickInterval: {
        days: 1
      },
      label: {
        format: {
          type: 'shortDate'
        }
      }
    };

    this.valueAxis = {
      label: {
        format: this.appFormat.GuiNumberFormat
      }
    }
  }

  closeButtonHandler(itemData:any) {
    if (!itemData) {
      return;
    }

    const index = this.historyTabs.indexOf(itemData);
    this.historyTabs.splice(index, 1);
    if (index >= this.historyTabs.length && index > 0) {
      this.selectedIndex = index - 1;
    }
  }

  onTabDragStart(e:any) {
    e.itemData = e.fromData[e.fromIndex];
  }

  onTabDrop(e:any) {
    e.fromData.splice(e.fromIndex, 1);
    e.toData.splice(e.toIndex, 0, e.itemData);
  }

  dragOverHandler(evt:any) {
    evt.preventDefault();
  }

  emptyTabDragOverHandler(evt:any) {
    if (this.dragOverExistingTab) {
      this.dragOverExistingTab = false;
    } else {
      //let targetPos = $('.drop-container').position();
      let targetPos = $('.drop-container')[0].getBoundingClientRect();
      this.showNewTabTooltip()
        .css({ top: targetPos.top - 20, left: evt.clientX, position: 'fixed' });
      evt.preventDefault();
    }
  }

  tabDragOverHandler(evt:any) {
    this.hideNewTabTooltip();
    this.dragOverExistingTab = true;
    for (let ii = 0; ii < this.historyTabs.length; ii++) {
      if (this.historyTabs[ii].id == evt.tabId) {
        this.selectedIndex = ii;
        break;
      }
    }
  }

  tabContentDragOverHandler(evt:any) {
    this.hideNewTabTooltip();
    this.dragOverExistingTab = true;
    evt.preventDefault();
  }

  newDropHandler(evt:any) {
    if (this.dropExistingTab) {
      this.dropExistingTab = false;
      return;
    } else {
      this.hideNewTabTooltip();
      evt.preventDefault();
      let tabStr = evt.dataTransfer.getData("text");
      let tabObj = JSON.parse(tabStr);
      if (tabObj) {
        this.historyService.fillHistoryTab(tabObj, this.dataMap);
        this.historyTabs.push(tabObj);
        this.selectedIndex = this.historyTabs.length - 1;
      }
    }
  }

  updateDropHandler(evt:any) {
    this.hideNewTabTooltip();
    // set this flag to be true, so the newDropHandler would not try to handle it again
    this.dropExistingTab = true;
    evt.preventDefault();
    let tabStr = evt.dataTransfer.getData("text");
    let tabObj = JSON.parse(tabStr);
    if (tabObj) {
      if (this.selectedIndex < this.historyTabs.length) {
        let selectedTab = this.historyTabs[this.selectedIndex];
        this.historyService.addHistoryTab(tabObj, selectedTab);
        this.historyService.fillHistoryTab(selectedTab, this.dataMap);
      }
    }
  }

  dragLeaveHandler(evt:any) {
    this.hideNewTabTooltip();
    evt.preventDefault();
  }

  private hideNewTabTooltip() {
    return $('.newTabTooltipShow, .newTabTooltipHide')
      .removeClass('newTabTooltipShow')
      .addClass('newTabTooltipHide');
  }

  private showNewTabTooltip() {
    return $('.newTabTooltipShow, .newTabTooltipHide')
      .removeClass('newTabTooltipHide')
      .addClass('newTabTooltipShow');
  }
}
