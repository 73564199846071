import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AppFormatService {
  public readonly GuiNumberFormat: string = '#,##0.#####';

  constructor(
    private datePipe: DatePipe
  ) { }

  public GuiNumber(val: Number) {
    if (val || val == 0) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 5
      });
    } else {
      return '';
    }
  }

  public GuiInt(val: Number) {
    if (val || val == 0) {
      return val.toFixed(0);
    } else {
      return '';
    }
  }

  public GuiDate(date: Date) {
    return this.datePipe.transform(date, 'MM/dd/yyyy');
  }

  public DateIntStr(date: Date) : string {
    let ss = this.datePipe.transform(date, 'yyyyMMdd');
    if (ss) {
      return ss;
    } else {
      return '';
    }
  }
}
