import { Component, NgModule, Input, AfterViewInit } from '@angular/core';
import { getPalette } from "devextreme/viz/palette";
import { DxLoadPanelModule } from 'devextreme-angular';
import * as d3 from 'd3';
import * as _ from 'underscore';
import { BrowserService } from '../../services';

declare const ParCoords: any;

const STRING_TICK_NUM_LIMIT = 50;

@Component({
  selector: 'app-parcoords',
  templateUrl: './parcoords.component.html',
  styleUrls: ['./parcoords.component.scss']
})
export class AxParcoordsComponent implements AfterViewInit {
  private _data: any;
  private _parcoords: any;
  loadingVisible = false;

  @Input() nameColumn!: [string, string];
  @Input() hideColumns!: string[];
  @Input() dimensions: any;
  @Input() set dataSource(data: any) {
    this._data = data;
    this.load(data);
  }

  constructor(private browserEvt: BrowserService) { }

  ngAfterViewInit() {
    this.browserEvt.onResize$.subscribe((win:any) => {
      this.load(this._data)
    });
  }

  public brushReset() {
    this._parcoords.brushReset();
  }

  public showLoadingIndicator() {
    this.loadingVisible = true;
  }

  public hideLoadingIndicator() {
    this.loadingVisible = false;
  }

  private load(data: any) {
    if (data == null || data == undefined) {
      return;
    }

    let names = _.chain(data)
      .pluck(this.nameColumn[0])
      .uniq().value();;

    /* 2021-05-18 james: the code above replaces the code as follows because _(data)>chain()
     * will cause WEBPACK_IMPORTED_MODULE not a function exception
     * not sure why it's working before ???
    let names = _(data).chain()
      .pluck(this.nameColumn[0])
      .uniq().value();
     */

    let colorList = getPalette('Bright').simpleSet;
    let colorScale = d3.scaleOrdinal().domain(names).range(colorList);
    let color = (d:any) => {
      return colorScale(d[this.nameColumn[0]]);
    };

    // find the longest text size in the first row to adjust left margin
    let textLength = 0;
    let textList;
    let namesCount = names.length;
    if (namesCount > STRING_TICK_NUM_LIMIT) {
      let linearScale = d3.scaleLinear()
        .domain([0, STRING_TICK_NUM_LIMIT])
        .rangeRound([0, namesCount - 1]);

      let tValues: string[] = [];
      for (let ii = 0; ii <= STRING_TICK_NUM_LIMIT; ii++) {
        let rangeIndex = linearScale(ii);
        let name: string = names[rangeIndex];
        tValues.push(name);
      }

      this.dimensions[this.nameColumn[0]] = {
        title: this.nameColumn[1],
        type: 'string',
        tickValues: tValues,
        innerTickSize: 8
      }

      textList = tValues;
    } else {
      textList = names;
    }

    textList.forEach(function (d) {
      if (d.length > textLength) {
        textLength = d.length;
      }
    });

    let width = document.getElementById('parcoords')!.clientWidth;
    d3.select('#parcoords').html('');
    //document.getElementById('parcoords').innerHTML = "";
    this._parcoords = ParCoords()("#parcoords")
      .margin({ top: 20, left: 3 * textLength, bottom: 20, right: 0 })
      .mode("queue")
      .data(data)
      .color(color)
      //.hideAxis(this.hideColumns)
      .width(width)
      .dimensions(this.dimensions)
      .render()
      //.createAxes()
      .shadows()
      .reorderable()
      .brushMode("1D-axes");

    /*
    this._parcoords.on('brushend', function (brushed, args) {
    });
    */

    this.enableTooltips();
  }

  private enableTooltips() {
    /*
    // set the initial coloring based on the 3rd column
    this.selectDimension(d3.keys(this._data[0])[2]);

    let selDim = _.bind(this.selectDimension, this);
    // click label to activate coloring
    this._parcoords.svg.selectAll(".dimension")
      .on("click", selDim)
      .selectAll(".label")
      .style("font-size", "14px"); // change font sizes of selected lable
    */
    /*
    this._parcoords.svg.selectAll(".dimension text.label")
      .on("dblclick", () => {
        this._parcoords.dimensions(this.dimensions)
          .render()
          .updateAxes();
      });
    */
    //add hover event
    d3.select("#parcoords svg")
      .on("mousemove", () => {
        var mousePosition:any[] = [
          // 2023-10-18 james after upgrade to later d3 version, event does not exist anymore
          //d3.event.pageX - document.getElementById('parcoords')!.getBoundingClientRect().left,
          //d3.event.pageY - document.getElementById('parcoords')!.getBoundingClientRect().top
        ];

        this.highlightLineOnClick(mousePosition, true); //true will also add tooltip
      })
      .on("mouseout", () => {
        this.cleanTooltip();
        this._parcoords.unhighlight();
      });
  }

  private cleanTooltip() {
    // removes any object under #tooltip is
    this._parcoords.svg.selectAll("#tooltip")
      .remove();
  }

  private selectDimension(dimension:any) {
    // change the fonts to bold
    this._parcoords.svg.selectAll(".dimension")
      .style("font-weight", "500")
      .filter(function (d:any) { return d == dimension; })
      .style("font-weight", "700");

    /*
    // change color of lines
    // set domain of color scale
    var values = this._parcoords.data().map(function (d) { return parseFloat(d[dimension]) });
    let colorList = getPalette('Bright').simpleSet;
    let colorScale = d3.scaleOrdinal().domain(names).range(colorList);
    color_set.domain([d3.min(values), d3.max(values)]);

    // change colors for each line
    this._parcoords.color(function (d) { return color_set([d[dimension]]) }).render();
    */
  }

  private getCentroids(data:any) {
    // this function returns centroid points for data. I had to change the source
    // for parallelcoordinates and make compute_centroids public.
    // I assume this should be already somewhere in graph and I don't need to recalculate it
    // but I couldn't find it so I just wrote this for now
    var margins = this._parcoords.margin();
    var graphCentPts:any[] = [];

    data.forEach((d:any) => {

      var initCenPts = this._parcoords.compute_centroids(d).filter(function (d:any, i:any) { return i % 2 == 0; });

      // move points based on margins
      var cenPts = initCenPts.map(function (d:any) {
        return [d[0] + margins["left"], d[1] + margins["top"]];
      });

      graphCentPts.push(cenPts);
    });

    return graphCentPts;
  }

  private getActiveData() {
    // I'm pretty sure this data is already somewhere in graph
    if (this._parcoords.brushed() != false) {
      return this._parcoords.brushed();
    }

    return this._parcoords.data();
  }

  private isOnLine(startPt:any, endPt:any, testPt:any, tol:any) {
    // check if test point is close enough to a line
    // between startPt and endPt. close enough means smaller than tolerance
    var x0 = testPt[0];
    var y0 = testPt[1];
    var x1 = startPt[0];
    var y1 = startPt[1];
    var x2 = endPt[0];
    var y2 = endPt[1];
    var Dx = x2 - x1;
    var Dy = y2 - y1;
    var delta = Math.abs(Dy * x0 - Dx * y0 - x1 * y2 + x2 * y1) / Math.sqrt(Math.pow(Dx, 2) + Math.pow(Dy, 2));
    //console.log(delta);
    if (delta <= tol) {
      return true;
    }

    return false;
  }

  private findAxes(testPt:any, cenPts:any) {
    // finds between which two axis the mouse is
    var x = testPt[0];
    var y = testPt[1];

    // make sure it is inside the range of x
    if (cenPts[0][0] > x) {
      return false;
    }

    if (cenPts[cenPts.length - 1][0] < x) {
      return false;
    }

    // find between which segment the point is
    for (var i = 0; i < cenPts.length; i++) {
      if (cenPts[i][0] > x) {
        return i;
      }
    }

    return false;
  }

  private addTooltip(clicked:any, clickedCenPts:any) {
    // sdd tooltip to multiple clicked lines
    var clickedDataSet = [];
    var margins = this._parcoords.margin()
    var currDim = this._parcoords.dimensions();
    // get all the values into a single list
    // 2023-10-18 james after upgrade to later d3 version, "keys" does not exist anymore
    var cols:any;
    // col = d3.keys(currDim);
    for (var i = 0; i < clicked.length; i++) {
      for (var j = 0; j < cols.length; j++) {
        var row = clicked[i];
        var text = row[cols[j]];
        // not clean at all!
        var x = clickedCenPts[i][j][0] - margins.left;
        var y = clickedCenPts[i][j][1] - margins.top;
        clickedDataSet.push([x, y, text, cols[j]]);
      }
    };

    // add rectangles
    var fontSize = 14;
    var padding = 2;
    var rectHeight = fontSize + 2 * padding; //based on font size
    ///*
    this._parcoords.svg.selectAll("rect[id='tooltip']")
      .data(clickedDataSet).enter()
      .append("rect")
      //.attr("x", function (d) { return d[0] - d[2].length * 5; })
      .attr("x", function (d:any) {
        if (currDim[d[3]].type != 'string' && typeof d[2] == 'number') {
          let val = d[2];
          return d[0] - val.toFixed(currDim[d[3]].decimalPlace).length * 5;
        } else {
          return d[0] - d[2].toString().length * 5;
        }
      })
      .attr("y", function (d:any) { return d[1] - rectHeight + 2 * padding; })
      .attr("rx", "2")
      .attr("ry", "2")
      .attr("id", "tooltip")
      .attr("fill", "grey")
      .attr("opacity", 0.9)
      //.attr("width", function (d) { return d[2].length * 10; })
      .attr("width", function (d:any) {
        if (currDim[d[3]].type != 'string' && typeof d[2] == 'number') {
          let val = d[2];
          return val.toFixed(currDim[d[3]].decimalPlace).length * 10;
        } else {
          return d[2].length * 10;
        }
      })
      .attr("height", rectHeight);
    //*/
    /*
    this._parcoords.svg.selectAll("rect[id='tooltip']")
      .data(clickedDataSet).enter()
      .append("rect")
      .attr("x", function (d) { return d[0]; })
      .attr("y", function (d) { return d[1]; })
      .attr("rx", "2")
      .attr("ry", "2")
      .attr("id", "tooltip")
      .attr("fill", "grey")
      .attr("opacity", 0.9)
      .attr("width", function (d) { return 20; })
      .attr("height", rectHeight);
    */
    // add text on top of rectangle
    this._parcoords.svg.selectAll("text[id='tooltip']")
      .data(clickedDataSet).enter()
      .append("text")
      .attr("x", function (d:any) { return d[0]; })
      .attr("y", function (d:any) { return d[1]; })
      .attr("id", "tooltip")
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .attr("font-size", fontSize)
      //.text(function (d) { return d[2]; })
      .text(function (d:any) {
        if (currDim[d[3]].type != 'string' && typeof d[2] == 'number') {
          let val = d[2];
          return val.toFixed(currDim[d[3]].decimalPlace);
        } else {
          return d[2];
        }
      })
  }

  private getClickedLines(mouseClick:any) {
    var clicked:any[] = [];
    var clickedCenPts:any[] = [];

    // find which data is activated right now
    var activeData = this.getActiveData();

    // find centriod points
    var graphCentPts = this.getCentroids(activeData);

    if (graphCentPts.length == 0) return false;

    // find between which axes the point is
    let axeNum = this.findAxes(mouseClick, graphCentPts[0]);
    if (!axeNum) {
      return false;
    }

    let axeIndex: number = axeNum;
    graphCentPts.forEach((d, i) => {
      if (this.isOnLine(d[axeIndex - 1], d[axeIndex], mouseClick, 2)) {
        clicked.push(activeData[i]);
        clickedCenPts.push(graphCentPts[i]); // for tooltip
      }
    });

    return [clicked, clickedCenPts]
  }

  private highlightLineOnClick(mouseClick:any, drawTooltip:any) {
    var clicked = [];
    var clickedCenPts = [];

    let clickedData = this.getClickedLines(mouseClick);
    if (clickedData && clickedData[0].length != 0) {

      clicked = clickedData[0];
      clickedCenPts = clickedData[1];

      // highlight clicked line
      this._parcoords.highlight(clicked);

      if (drawTooltip) {
        // clean if anything is there
        this.cleanTooltip();
        // add tooltip
        this.addTooltip(clicked, clickedCenPts);
      }

    }
  }
}


@NgModule({
  declarations: [
    AxParcoordsComponent
  ],
  imports: [
    DxLoadPanelModule
  ],
  exports: [AxParcoordsComponent]
})
export class AxParcoordsModule { }

