import { Inject, Injectable, isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AppConfigService {
  constructor() {
  }

  /*
  loadAppConfig() {
    return new Promise((resolve) => {
      // Read environment variables from browser window
      const browserWindow = window || {};
      const browserWindowEnv = browserWindow['__env'] || {};

      // Assign environment variables from browser window to env
      // In the current implementation, properties from env.js overwrite defaults from the EnvService.
      // If needed, a deep merge can be performed here to merge properties instead of overwriting them.
      for (const key in browserWindowEnv) {
        if (browserWindowEnv.hasOwnProperty(key)) {
          this.appConfig[key] = browserWindowEnv[key];
        }
      }
      resolve(this.appConfig);
    });
  }
  */

  public get title() {
    return environment.APP_TITLE;
  }

  public get authUrl() {
    return environment.COGNITO_APP_DOMAIN;
  }

  public get apiUrl() {
    return environment.API_URL;
  }

  public get clientUrl() {
    //return this.bUrl;
    return environment.CLIENT_URL;
  }
}
