<ng-content></ng-content>

<div class="menu-container">
  <dx-tree-view
    [items]="items"
    keyExpr="path"
    selectionMode="single"
    [focusStateEnabled]="false"
    [selectByClick]="true"
    expandEvent="click"
    (onItemClick)="onItemClick($event)"
    (onContentReady)="updateSelection($event)"
    (onItemSelectionChanged)="updateSelection($event)"
    width="100%">
  </dx-tree-view>
</div>
