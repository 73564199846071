import { Component, ViewChild, AfterViewInit, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from "@angular/common";
import { DxTreeMapModule, DxTreeMapComponent, DxLoadPanelModule } from 'devextreme-angular';
import { AppFormatService } from '../../services';
import { AxDrillDownBarpModule, AxDrillDownBarComponent } from '../drill-down-bar/drill-down-bar.component';

@Component({
  selector: 'app-tree-map',
  templateUrl: './tree-map.component.html',
  styleUrls: ['./tree-map.component.scss']
})

export class AxTreeMapComponent implements AfterViewInit {
  @ViewChild(AxDrillDownBarComponent) drillBar!: AxDrillDownBarComponent;
  @ViewChild(DxTreeMapComponent) treeMap!: DxTreeMapComponent;

  @Input() dataSource!: any[];
  @Input()
  set path(val: string[]) {
    this.drillBar.path = val;
  }
  get path() {
    return this.drillBar.path;
  }

  @Input()
  set currentPath(val: string[]) {
    this.drillBar.currentPath = val;
  }
  get currentPath() {
    return this.drillBar.currentPath;
  }

  //@Input() colorizer: any;
  @Input() groupNames!: string[];
  @Input() hasHiddenSecurityGroup: boolean;
  @Input('colorField')
  set colorField(value: string) {
    this.treeMap.colorField = value;
  }
  @Input() customizeTooltipFunc = (arg:any) => {
    let val = 0;
    if (arg.node.data.items) {
      for (let ii = 0; ii < arg.node.data.items.length; ii++) {
        val += arg.node.data.items[ii].realValue;
      }
    } else if (arg.node.data.realValue) {
      val = arg.node.data.realValue;
    } else {
      val = arg.value;
    }

    return {
      html: '<span><strong>' + arg.node.data.id + ':   </strong>' + this.appFormat.GuiNumber(val) + '</span>'
      //text: arg.node.data.realValue.toFixed(3)
    }
  }

  @Output() onDrill = new EventEmitter();

  public loadingVisible = false;

  constructor(private appFormat: AppFormatService) {
    this.hasHiddenSecurityGroup = false;
  }

  ngAfterViewInit() {
    this.drillBar.root = "All"
    /*
    this.treeMap.colorizer = {
      type: "gradient",
      palette: ["#d1e5f9", "#2a5599"],
      range: null,
      colorCodeField: "value",
      colorizeGroups: false
    };
    */
    this.treeMap.colorField = 'colorHex';
    //this.colorizer = this.treeMap.colorizer;
  }

  drill() {
    this.onDrill.emit();
  }

  showLoadingIndicator() {
    //this.treeMap.instance.showLoadingIndicator();
    this.loadingVisible = true;
  }

  hideLoadingIndicator() {
    //this.treeMap.instance.hideLoadingIndicator();
    this.loadingVisible = false;
  }

  nodeClick(evt:any) {
    let obj: AxTreeMapItem = evt.node.data as AxTreeMapItem;
    let ii;
    if (obj.id) {
      if (obj.parentId) {
        ii = this.path.indexOf(obj.parentId);
        if (ii >= 0) {
          this.path.splice(ii, this.path.length - ii, obj.parentId, obj.id)
        } else {
          this.path.push(obj.parentId, obj.id);
        }
      } else {
        ii = this.path.indexOf(obj.id);
        if (ii >= 0) {
          this.path.splice(ii, this.path.length - ii, obj.id)
        } else {
          this.path.push(obj.id);
        }
      }
    }

    if (this.hasHiddenSecurityGroup) {
      if (this.path.length > this.groupNames.length) {
        this.path = this.path.slice(0, this.groupNames.length);
      }
    } else {
      if (this.path.length > this.groupNames.length - 1) {
        this.path = this.path.slice(0, this.groupNames.length - 1);
      }
    }
    
    
    if (JSON.stringify(this.currentPath) == JSON.stringify(this.path)) {
      return;
    } else {
      this.currentPath = this.path.slice();
      this.onDrill.emit();
    }
  }
}

@NgModule({
  declarations: [AxTreeMapComponent],
  imports: [
    DxTreeMapModule,
    DxLoadPanelModule,
    CommonModule,
    AxDrillDownBarpModule
  ],
  exports: [AxTreeMapComponent]
})

export class AxTreeMapModule { }

export class AxTreeMapItem {
  id!: string;
  parentId!: string;
  name!: string;
  value!: number;
  realValue!: number;
  colorHex!: string;
}
