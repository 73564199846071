import { Injectable } from '@angular/core';
import { AuthService, AppFormatService } from 'src/app/shared/services';
import { SummaryService } from '../summary/summary.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class FactorService {

  constructor(
    private auth: AuthService,
    private summary: SummaryService,
    private appFormat: AppFormatService
  ) { }

  public retrieveCategory(): Observable<any> {
    let httpParams = new HttpParams()
      .set('reportId', '' + this.summary.reportId)
      .set('date', this.appFormat.DateIntStr(this.summary.analysisDate));

    return this.auth.get('/api/GfrFactor/GetCategory', httpParams);
  }

  public retrieveCurrency(category: string): Observable<any> {
    let httpParams = new HttpParams()
      .set('reportId', '' + this.summary.reportId)
      .set('date', this.appFormat.DateIntStr(this.summary.analysisDate))
      .set('category', category);

    return this.auth.get('/api/GfrFactor/GetCurrency', httpParams);
  }

  public retrieveNameColumns(): Observable<any> {
    return this.auth.get('/api/GfrFactor/GetNameColumns');
  }

  public retrieveName(seriesName: string, category: string, currency: string): Observable<any> {
    let httpParams = new HttpParams()
      .set('reportId', '' + this.summary.reportId)
      .set('date', this.appFormat.DateIntStr(this.summary.analysisDate))
      .set('seriesName', seriesName)
      .set('category', category)
      .set('currency', currency);

    return this.auth.get('/api/GfrFactor/GetName', httpParams);
  }

  public retrieveNameTimeSeries(seriesName: string, category: string, currency: string, name: string, valCol: string): Observable<any> {
    let httpParams = new HttpParams()
      .set('reportId', '' + this.summary.reportId)
      .set('date', this.appFormat.DateIntStr(this.summary.analysisDate))
      .set('seriesName', seriesName)
      .set('category', category)
      .set('currency', currency)
      .set('name', name)
      .set('valCol', valCol);

    return this.auth.get('/api/GfrFactor/GetNameTimeSeries', httpParams);
  }

  public retrieveSecurityColumns(): Observable<any> {
    return this.auth.get('/api/GfrFactor/GetSecurityColumns');
  }

  public retrieveSecurity(seriesName: string, category: string, currency: string, name: string): Observable<any> {
    let httpParams = new HttpParams()
      .set('reportId', '' + this.summary.reportId)
      .set('date', this.appFormat.DateIntStr(this.summary.analysisDate))
      .set('seriesName', seriesName)
      .set('category', category)
      .set('currency', currency)
      .set('name', name);

    return this.auth.get('/api/GfrFactor/GetSecurity', httpParams);
  }

  public retrieveSecurityTimeSeries(seriesName: string, category: string, currency: string, name: string, axsid: number, valCol: string): Observable<any> {
    let httpParams = new HttpParams()
      .set('reportId', '' + this.summary.reportId)
      .set('date', this.appFormat.DateIntStr(this.summary.analysisDate))
      .set('seriesName', seriesName)
      .set('category', category)
      .set('currency', currency)
      .set('name', name)
      .set('axsid', '' + axsid)
      .set('valCol', valCol);

    return this.auth.get('/api/GfrFactor/GetSecurityTimeSeries', httpParams);
  }

  public retrieveDetailColumns(): Observable<any> {
    return this.auth.get('/api/GfrFactor/GetDetailColumns');
  }

  public retrieveDetail(seriesName: string, category: string, currency: string, name: string, sid: number): Observable<any> {
    let httpParams = new HttpParams()
      .set('reportId', '' + this.summary.reportId)
      .set('date', this.appFormat.DateIntStr(this.summary.analysisDate))
      .set('seriesName', seriesName)
      .set('category', category)
      .set('currency', currency)
      .set('name', name)
      .set('sid', '' + sid);

    return this.auth.get('/api/GfrFactor/GetDetail', httpParams);
  }
}
