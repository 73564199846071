<dx-data-grid [dataSource]="dataSource"
              [columns]="columns"
              [summary]="summary"
              [showBorders]="showBorders"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [height]="height"
              (onContentReady)="contentReadyHandler($event)"
              (onCellHoverChanged)="cellHoverChangedHandler($event)"
              (onCellPrepared)="cellPreparedHandler($event)"
              (onCellClick)="cellClickHandler($event)"
              (onExporting)="exportingHandler($event)">

  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-export [enabled]="enableExport"></dxo-export>
  <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
  <dxo-load-panel [enabled]="true">
  </dxo-load-panel>
  <dxo-scrolling mode="virtual"
                 showScrollbar="always">
  </dxo-scrolling>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <div *dxTemplate="let info of 'titleHeaderTemplate'">
    <p style="font-size:14px">{{info.column.caption}}</p>
  </div>
  <div *dxTemplate="let data of 'buttonCellTemplate'">
    <dx-button
        stylingMode="contained"
        text={{data.value}}
        type="default"
        [height]="20"
        (onClick)="onCellButtonClick(data.data)"
    >
    </dx-button>
  </div>
  <div *dxTemplate="let data of 'cellDragNumberTemplate'"
       draggable="true"
       (dragstart)="cellDrag($event)">
    {{appFormat.GuiNumber(data.value)}}
  </div>
</dx-data-grid>

<!--
  <dxo-paging [pageSize]="20">
  </dxo-paging>
  <dxo-pager [showPageSizeSelector]="true"
             [allowedPageSizes]="[20, 40, 60]"
             [showInfo]="true">
  </dxo-pager>
  <dxo-row-dragging [allowReordering]="true"
                    [showDragIcons]="false">
  </dxo-row-dragging>
  -->
