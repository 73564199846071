
<h6 class="content-block">Report Runner</h6>
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#runpanel' }"
    [(visible)]="this.ttyaxiomRunner.loading" [showIndicator]="true" [showPane]="true" [shading]="false"
    [hideOnOutsideClick]="false">
</dx-load-panel>
<div class="ax-panel" id="runpanel">
    <dx-form id="form" [formData]="ttyaxiomParam" [colCount]="1">
        <dxi-item dataField="rspFile" [label]="{ text: 'RSP File' }" >
            <dxi-validation-rule type="required" message="RSP File is required"></dxi-validation-rule>
        </dxi-item>
    </dx-form>
    <dx-button stylingMode="contained" type="normal" text="Run" [width]="120" (click)="ttyaxiomRun()"></dx-button>
</div>
<div class="ax-panel">
    <app-grid [enableExport]="false">
    </app-grid>
</div>
