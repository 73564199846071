<div id="drill-down-title">
  <div *ngIf="path.length > 0; else all">
    <span *ngFor="let name of path; let isLast = last; let isFirst = first">
      <span *ngIf="isFirst"><span class="link" (click)="drillUpClick(null)">{{root}}</span> > </span>
      <span [ngClass]="{'link': !isLast }" (click)="drillUpClick(name)">{{name}}</span>
      {{isLast ? '' : ' > '}}
    </span>
  </div>
</div>

<ng-template #all>
  <span>{{root}}</span>
</ng-template>
