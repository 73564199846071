import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityLookupComponent } from './security-lookup/security-lookup.component';
import { DxDateBoxModule, DxButtonModule, DxTextBoxModule, DxTabPanelModule, DxFormModule, DxMultiViewModule, DxLoadPanelModule, DxValidatorModule } from 'devextreme-angular';
import { SecurityLookupService } from './security-lookup/security-lookup.service';
import { CdsService } from './cds/cds.service'
import { BondService } from './bond/bond.service'
import { CdsComponent } from './cds/cds.component';
import { BondComponent } from './bond/bond.component';

@NgModule({
  declarations: [
    SecurityLookupComponent,
    CdsComponent,
    BondComponent
  ],
  imports: [
    CommonModule,
    DxDateBoxModule,
    DxButtonModule,
    DxTextBoxModule,
    DxTabPanelModule,
    DxFormModule,
    DxMultiViewModule,
    DxLoadPanelModule,
    DxValidatorModule
  ],
  providers: [
    SecurityLookupService,
    CdsService,
    BondService
  ]
})
export class SecurityLookupModule { }
