import { AfterViewInit, Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AxPivotGridComponent } from '../../../shared/components/pivot-grid/pivot-grid.component';
import { SummaryPivotService } from './summary-pivot.service';
import { SummaryService } from './summary.service';

@Component({
  selector: 'app-summary-tab',
  templateUrl: './summary-tab.component.html',
  styleUrls: ['./summary-tab.component.scss'],
  providers: [
    SummaryPivotService
  ]
})
export class SummaryTabComponent implements AfterViewInit {
  public selectedIndex!: number;

  constructor(
    public summary: SummaryService,
    public summaryPivot: SummaryPivotService
  ) {
  }

  ngAfterViewInit() {
    this.summaryPivot.retrieve().subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.summaryPivot.buildCells(jsonObj);
      });
  }

  processChartCell(cellData:any) {
    // hide chart data if it's not a drill-down
    cellData.visible = cellData.rowPath.length >= 1 &&
      cellData.columnPath.length >= 2;
  }
}


