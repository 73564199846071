import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { AuthService, AppConfigService, AppFormatService } from '../../../shared/services';
import { SummaryService } from '../summary/summary.service';


@Injectable()

export class DetailService {
  constructor(
    private auth: AuthService,
    private summary: SummaryService,
    private appFormat: AppFormatService
  ) { }

    retrieveGrid(id: number, date: Date, seriesName: string): Observable<any> {
        if (id == 0) {
            id = this.summary.reportId;
        }

        if (!date) {
          if (this.summary.analysisDate) {
            date = this.summary.analysisDate;
          }
        }

        if (seriesName == '0') {
            seriesName = this.summary.seriesName;
        }

        let httpParams = new HttpParams()
          .set('reportId', '' + id)
            .set('date', this.appFormat.DateIntStr(date))
            .set('seriesName', seriesName);

        return this.auth.get('/api/RvDetail/Get', httpParams);
    }

  retrieveByGroup(
    seriesName: string,
    grpNames: string[]
  ): Observable<any> {
    let id = this.summary.reportId;
    let group1 = grpNames[0];

    if (group1 && group1.toUpperCase() == 'TOTAL') {
      // group1=Total only exists in rv summary table not in rv security detail table
      // set group1 to blank, so it will retrieve all  
      group1 = '';
    }

    let httpParams = new HttpParams()
      .set('reportId', '' + id)
      .set('seriesName', seriesName)
      .set('group1', group1 ? group1 : '')
      .set('group2', grpNames[1] ? grpNames[1] : '')
      .set('group3', grpNames[2] ? grpNames[2] : '')
      .set('group4', grpNames[3] ? grpNames[3] : '')
      .set('group5', grpNames[4] ? grpNames[4] : '');

    return this.auth.get('/api/RvDetail/GetByGroup', httpParams);
  }

    retrieveTreeMap(id: number, date: Date, seriesName: string, path: string[], grpNames: string[]): Observable<any> {
        if (id == 0) {
            id = this.summary.reportId;
        }

        if (!date && this.summary.analysisDate) {
            date = this.summary.analysisDate;
        }

        if (seriesName == '0') {
            seriesName = this.summary.seriesName;
        }

        let pathStr: string = '';
        if (path && path.length > 0) {
            pathStr = path.join(',');
        }

        let httpParams = new HttpParams()
          .set('reportId', '' + id)
            .set('date', this.appFormat.DateIntStr(date))
            .set('seriesName', seriesName)
            .set('itemName', 'portsec_contribution')
            .set('itemDesc', 'Contribution')
            .set('treeMapPath', pathStr)
            .set('grpNames', grpNames.join(','));

        return this.auth.get('/api/RvDetail/GetTreeMap', httpParams);
    }
}
