export const environment = {
  production: false,
  COGNITO_REDIRECT_URI:'https://axiom.dev.wilshire.com',
  COGNITO_REDIRECT_URI_SIGNOUT:'https://axiom.dev.wilshire.com',
  COGNITO_USERPOOL_ID:'us-east-1_8ae66yNRc',
  COGNITO_APP_DOMAIN: 'account.dev.wilshire.com', 
  COGNITO_CLIENT_ID:'1dt6igjipe6fd5lvp22pvddm0u',
  APP_TITLE: 'Axiom',
  API_URL: 'https://axiom.dev.wilshire.com',
  CLIENT_URL: 'https://axiom.dev.wilshire.com'
};
