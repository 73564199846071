import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class RptParamService {

  get RV(): string {
    return 'rv';
  }

  get GFPA(): string {
    return 'gfpa';
  }

  get GFR(): string {
    return 'gfr';
  }

  get FOF(): string {
    return 'fof';
  }

  get PORTFOLIO(): string {
    return 'Portfolio';
  }

  get BENCHMARK(): string {
    return 'Benchmark';
  }

  get ACTIVE(): string {
    return 'Active';
  }

  constructor(
    private auth: AuthService
  ) { }

  public isReportType(rptType: string): boolean {
    switch (rptType.toLocaleLowerCase()) {
      case this.RV:
      case this.GFPA:
      case this.GFR:
      case this.FOF:
        return true;
      default:
        return false;
    }
  }

  public getReportId(rptType: string): number {
    let rid = this.auth.getItem(rptType + 'ReportId');
    if (rid) {
      return +rid;
    } else {
      return 0;
    }
  }

  public setReportId(rptType: string, rid: number) {
    this.auth.setItem(rptType + 'ReportId', rid + '');
  }

  public getAnalysisDate(rptType: string): Date | null {
    let dt = new Date(this.auth.getItem(rptType + 'AnalysisDate')!);
    if (dt) {
      return dt;
    } else {
      return null;
    }
  }

  public setAnalysisDate(rptType: string, date: Date) {
    this.auth.setItem(rptType + 'AnalysisDate', date.toISOString());
  }

  public getBeginDate(rptType: string): Date | null {
    let dt = new Date(this.auth.getItem(rptType + 'BeginDate')!);
    if (dt) {
      return dt;
    } else {
      return null;
    }
  }

  public setBeginDate(rptType: string, date: Date) {
    this.auth.setItem(rptType + 'BeginDate', date.toISOString());
  }

  public getEndDate(rptType: string): Date | null {
    let dt = new Date(this.auth.getItem(rptType + 'EndDate')!);
    if (dt) {
      return dt;
    } else {
      return null;
    }
  }

  public setEndDate(rptType: string, date: Date) {
    this.auth.setItem(rptType + 'EndDate', date.toISOString());
  }

  public getSeriesName(rptType: string): string {
    let name = this.auth.getItem(rptType + 'SeriesName');
    if (name) {
      return name;
    } else {
      // default to portfolio data set
      return this.PORTFOLIO;
    }
  }

  public setSeriesName(rptType: string, name: string) {
    this.auth.setItem(rptType + 'SeriesName', name);
  }

  public getPortfolioName(rptType: string): string {
    let name = this.auth.getItem(rptType + 'PortfolioName');
    if (name) {
      return name;
    } else {
      return '';
    }
  }

  public setPortfolioName(rptType: string, name: string) {
    this.auth.setItem(rptType + 'PortfolioName', name);
  }

  public getBenchmarkName(rptType: string): string {
    let name = this.auth.getItem(rptType + 'BenchmarkName');
    if (name) {
      return name;
    } else {
      return '';
    }
  }

  public setBenchmarkName(rptType: string, name: string) {
    this.auth.setItem(rptType + 'BenchmarkName', name);
  }
}
