<h6 class="content-block">Returns Variance Detail </h6>
<p>
  Report ID: <strong>{{this.objId}}</strong>
  &nbsp;&nbsp;&nbsp; {{this.dataSet}}: <strong>{{this.objName}}</strong>
  <!--&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Date: <strong>{{this.analysisDate | date: 'shortDate'}}</strong>-->
</p>

<div>
  <div class="ax-panel">
    <app-tree-map (onDrill)="drill()">
    </app-tree-map>
  </div>
  <div class="ax-panel">
    <app-grid name="Returns Variance Detail"
              [height]="500">
    </app-grid>
  </div>
</div>
