import { Component, OnInit, NgModule, Input, AfterViewInit } from '@angular/core';
import { DxPopupModule } from 'devextreme-angular';

export enum NotifyType {
  Information = 0,
  Warning = 1,
  Error = 2
}

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class AxNotifyComponent implements AfterViewInit {

  public title: string = '';
  public message: string = 'No message';
  public popupVisible = false;

  constructor() {
  }

  ngAfterViewInit() {
  }

  /*
  public showSystemError() {
    this.title = 'Connection Error';
    this.message = 'If your internet connection is working, please contact our client support team at&nbsp;<a href="mailto:service@wilshire.com">service@wilshire.com</a>';
    this.popupVisible = true;
  }

  public showNoData() {
    this.title = 'Information';
    this.message = 'No data';
    this.popupVisible = true;
  }
  */

  public show(type: NotifyType, msg: string) {
    switch (type) {
      case NotifyType.Error:
        this.title = 'Error';
        break;
      case NotifyType.Warning:
        this.title = 'Warning';
        break;
      case NotifyType.Information:
      default:
        this.title = 'Information';
        break;
    }

    this.message = msg;
    this.popupVisible = true;
  }
}



@NgModule({
  declarations: [AxNotifyComponent],
  imports: [
    DxPopupModule
  ],
  exports: [AxNotifyComponent]
})

export class AxNotifyModule { }
