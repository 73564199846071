import { Component, HostBinding, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, ScreenService, AppConfigService } from './shared/services';
import { NotificationService } from './error/notification.service';
import { AxNotifyComponent, NotifyType } from './shared/components/notify/notify.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  @ViewChild(AxNotifyComponent) notifyComp!: AxNotifyComponent;
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }

  constructor(
    private authService: AuthService,
    private screen: ScreenService,
    public appInfo: AppConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private notifyServ: NotificationService
  ) {
    //Log.logger = console;
    //Log.level = Log.DEBUG;
    this.notifyServ.errObservable.subscribe(msg => {
      this.showError(msg);
    });
  }

  isAuthenticated() {
    return this.authService.isLoggedIn;
  }

  public showError(message: string) {
    // 2020-09-29 james: we need to review the system error popup to see if we should show it to the user
    this.notifyComp.show(NotifyType.Error, message);
  }
}
