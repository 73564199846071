import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { IDropdownCodes, IDropdownMaps, IDropdownValues, IIncomingParams, ISecurityModel, SecurityLookupService } from '../security-lookup/security-lookup.service';
import { CdsService, ICdsFormModel, ICdsResults } from './cds.service';
import { debug } from 'console';

@Component({
  selector: 'app-cds',
  templateUrl: './cds.component.html',
  styleUrls: ['./cds.component.scss']
})
export class CdsComponent implements AfterViewInit {
  showCdsReferenceData: boolean = false;
  cdsFormModel!: ICdsFormModel;
  cdsResults!: ICdsResults;
  securityModel!: ISecurityModel;
  dropdownMaps!: IDropdownMaps;
  showReferenceData: boolean = false;
  dropdownCodes!: IDropdownCodes;
  showResults: boolean = false;

  constructor(
    public securityLookupService: SecurityLookupService,
    public cdsService: CdsService
  ) {

  }

  save() { //debug
    this.cdsService.loading = true;
    this.cdsService.save().subscribe().add(() => {
      this.cdsService.loading = false;
    });
  }

  calculate() {
    this.cdsService.loading = true;
      this.cdsService.cdsFormModel = this.cdsFormModel;
      this.cdsService.calculate().subscribe(data => {
          this.cdsService.parseResults(data);
      }).add(() => {
          this.cdsResults = this.cdsService.cdsResults;
          this.cdsService.loading = false;
          this.showResults = true;
      })
  }

  display(securityModel: ISecurityModel, cusip: string, date: Date) {
    this.cdsService.loading = true;
    this.cdsService.display(securityModel, cusip, date);
    this.cdsFormModel = this.cdsService.cdsFormModel;
  }

  cdsCalculate() {
    this.cdsService.cdsFormModel = this.cdsFormModel;
    this.cdsService.calculate().subscribe((data: any) => {
      this.cdsService.parseResults(data);
    }).add(() => {
      this.cdsResults = this.cdsService.cdsResults;
      this.showResults = true;
    })
  }

  ngAfterViewInit() {

  }
}