import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService, AppFormatService } from '../../../shared/services';

@Injectable({
    providedIn: 'root'
})
export class SummaryService {
    get beginDate(): Date {
        let dtStr = this.auth.getItem('dataLoadBeginDate');
        if (dtStr) {
            return new Date(dtStr);
        } else {
            return new Date();
        }
    }

    set beginDate(date: Date) {
        if (date) {
            this.auth.setItem('dataLoadBeginDate', date.toISOString());
        }
    }

    get endDate(): Date {
        let dtStr = this.auth.getItem('dataLoadEndDate');
        if (dtStr) {
            return new Date(dtStr);
        } else {
            return new Date();
        }
    }

    set endDate(date: Date) {
        if (date) {
          this.auth.setItem('dataLoadEndDate', date.toISOString());
        }
    }

  constructor(
    private auth: AuthService,
    private appFormat: AppFormatService
  ) {
        if (!this.endDate) {
            this.endDate = new Date();
        }

        if (!this.beginDate) {
            let bdt = new Date();
            bdt.setDate(this.endDate.getDate() - 7);
            this.beginDate = bdt;
        }
    }

    getTabs(): Tab[] {
        return tabs;
    }

    generate(beginDate: Date, endDate: Date) {
        let beginDateStr: string = '0';
        let endDateStr: string = '0';

        if (beginDate) {
            beginDateStr = this.appFormat.DateIntStr(beginDate);
        }

        if (endDate) {
            endDateStr = this.appFormat.DateIntStr(endDate);
        }

        let httpParams = new HttpParams()
            .set('beginDate', beginDateStr)
            .set('endDate', endDateStr);

        return this.auth.get('/api/DataLoadSummary/Generate', httpParams);
    }

    retrieveGrid(beginDate: Date, endDate: Date): Observable<any> {
        let beginDateStr: string = '0';
        let endDateStr: string = '0';

        if (beginDate) {
            beginDateStr = this.appFormat.DateIntStr(beginDate);
        }

        if (endDate) {
            endDateStr = this.appFormat.DateIntStr(endDate);
        }

        let httpParams = new HttpParams()
            .set('beginDate', beginDateStr)
            .set('endDate', endDateStr);

        return this.auth.get('/api/DataLoadSummary/Get', httpParams);
    }

    retrieveRejected(beginDate: Date, endDate: Date): Observable<any> {
        let beginDateStr: string = '0';
        let endDateStr: string = '0';

        if (beginDate) {
            beginDateStr = this.appFormat.DateIntStr(beginDate);
        }

        if (endDate) {
            endDateStr = this.appFormat.DateIntStr(endDate);
        }

        let httpParams = new HttpParams()
            .set('beginDate', beginDateStr)
            .set('endDate', endDateStr);

        return this.auth.get('/api/DataLoadSummary/GetRejected', httpParams);
    }

    retrieveRejectedSeries(beginDate: Date, endDate: Date): Observable<any> {
        let beginDateStr: string = '0';
        let endDateStr: string = '0';

        if (beginDate) {
            beginDateStr = this.appFormat.DateIntStr(beginDate);
        }

        if (endDate) {
            endDateStr = this.appFormat.DateIntStr(endDate);
        }

        let httpParams = new HttpParams()
            .set('beginDate', beginDateStr)
            .set('endDate', endDateStr);

        return this.auth.get('/api/DataLoadSummary/GetRejectedSeries', httpParams);
    }

    retrieveLoaded(beginDate: Date, endDate: Date): Observable<any> {
        let beginDateStr: string = '0';
        let endDateStr: string = '0';

        if (beginDate) {
            beginDateStr = this.appFormat.DateIntStr(beginDate);
        }

        if (endDate) {
            endDateStr = this.appFormat.DateIntStr(endDate);
        }

        let httpParams = new HttpParams()
            .set('beginDate', beginDateStr)
            .set('endDate', endDateStr);

        return this.auth.get('/api/DataLoadSummary/GetLoaded', httpParams);
    }

    retrieveLoadedSeries(beginDate: Date, endDate: Date): Observable<any> {
        let beginDateStr: string = '0';
        let endDateStr: string = '0';

        if (beginDate) {
            beginDateStr = this.appFormat.DateIntStr(beginDate);
        }

        if (endDate) {
            endDateStr = this.appFormat.DateIntStr(endDate);
        }

        let httpParams = new HttpParams()
            .set('beginDate', beginDateStr)
            .set('endDate', endDateStr);

        return this.auth.get('/api/DataLoadSummary/GetLoadedSeries', httpParams);
    }
}

let tabs: Tab[] = [
    {
        id: 0,
        text: "Total",
        //icon: "user",
        //content: "Total Count"
    },
    {
        id: 1,
        text: "Portfolio Rejections",
        //icon: "comment",
        //content: "Portfolio Rejected"
    },
    {
        id: 2,
        text: "Portfolio Load Counts",
        //icon: "find",
        //content: "Portfolio Loaded"
    }
];

export class Tab {
    id!: number;
    text!: string;
    //icon: string;
    //content: string;
}
