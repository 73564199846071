import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { SummaryService } from '../summary/summary.service';
import { DxMultiViewComponent } from 'devextreme-angular';
import { CategoryComponent } from './category/category.component';
//import { FactorViewItem } from './factor-view-item';
import { AxDrillDownBarComponent } from 'src/app/shared/components/drill-down-bar/drill-down-bar.component';
import { CurrencyComponent } from './currency/currency.component';
import { NameComponent } from './name/name.component';
import { SecurityComponent } from './security/security.component';
import { SecurityDetailComponent } from './security-detail/security-detail.component';

const CATEGORY_INDEX = 0;
const CURRENCY_INDEX = 1;
const NAME_INDEX = 2;
const SECURITY_INDEX = 3;
const DETAIL_INDEX = 4;

@Component({
  selector: 'app-factor',
  templateUrl: './factor.component.html',
  styleUrls: ['./factor.component.scss']
})
export class FactorComponent implements AfterViewInit {
  @ViewChild(AxDrillDownBarComponent) drillBar!: AxDrillDownBarComponent;
  @ViewChild(DxMultiViewComponent) views!: DxMultiViewComponent;
  @ViewChild(CategoryComponent) categoryChart!: CategoryComponent;
  @ViewChild(CurrencyComponent) currencyChart!: CurrencyComponent;
  @ViewChild(NameComponent) nameGrid!: NameComponent;
  @ViewChild(SecurityComponent) securityGrid!: SecurityComponent;
  @ViewChild(SecurityDetailComponent) detailGrid!: SecurityDetailComponent;
  //private _currencyChart: CurrencyComponent;
  //@ViewChild(CurrencyComponent) set currencyChart(currChart: CurrencyComponent) {
  //  this._currencyChart = currChart;
  //}

  public selectedIndex: number = 0;

  private pageMap = new Map();
  private fCat!: string;
  private fCurr!: string;
  private seriesName!: string;
  private fName!: string;
  private fSid!: number;
  /*
  public hasCurrency: boolean = true;
  public hasName: boolean = false;
  
  public viewItems: FactorViewItem[] = [{
    name: "Factor Category",
    path: ["Factor Category"]
  }];
  */

  constructor(
    public summary: SummaryService
  ) { }

  ngAfterViewInit() {
    this.drillBar.root = "Factor Category";
    this.pageMap.set(CATEGORY_INDEX, []);
    this.views.swipeEnabled = false;
  }

  public drill() {
    this.views.selectedIndex = this.drillBar.currentPath.length;
  }

  public selectionChanged(evt:any) {
    this.selectedIndex = this.views.selectedIndex;
    if (this.pageMap.has(this.selectedIndex)) {
      this.drillBar.path = this.pageMap.get(this.selectedIndex);
    }
  }

  public categoryClicked(evt:any) {
    //this.hasCurrency = false;
    this.fCat = evt.target.data['category'];
    if (this.fCat) {
      let path = [this.fCat];
      this.pageMap.set(CURRENCY_INDEX, path);

      //this.viewItems = [{
      //  name: "Factor Category",
      //  path: ["Factor Category"]
      //}, {
      //  name: category,
      //  path: ["Factor Category", category]
      //  }];        

      this.currencyChart.retrieve(this.fCat);
    }
  }

  public currencyLoaded() {
    this.views.selectedIndex = CURRENCY_INDEX;
  }

  public currencyClicked(evt:any) {
    //this.hasName = true;
    var point = evt.target;
    this.fCurr = point.argument;
    this.seriesName = point.series.name;

    //let currency: string = evt.target.data['currency'];
    if (this.fCurr) {
      let path = this.drillBar.path.slice();
      path.push(this.fCurr + '(' + this.seriesName + ')');
      this.pageMap.set(NAME_INDEX, path);

      //this.viewItems = [{
      //  name: "Factor Category",
      //  path: ["Factor Category"]
      //}, {
      //  name: category,
      //  path: ["Factor Category", category]
      //  }];

      this.nameGrid.retrieve(this.seriesName, this.fCat, this.fCurr);
    }
  }

  public nameLoaded() {
    this.views.selectedIndex = NAME_INDEX;
  }

  public nameClicked(evt:any) {
    this.fName = evt.element.innerText;

    if (this.fName) {
      let path = this.drillBar.path.slice();
      path.push(this.fName);
      this.pageMap.set(SECURITY_INDEX, path);
      this.securityGrid.retrieve(this.seriesName, this.fCat, this.fCurr, this.fName);
    }
  }

  public securityLoaded() {
    this.views.selectedIndex = SECURITY_INDEX;
  }

  public securityClicked(evt:any) {
    let securityId = evt.security_id;
    this.fSid = evt.axsid;

    if (securityId) {
      let path = this.drillBar.path.slice();
      path.push(securityId);
      this.pageMap.set(DETAIL_INDEX, path);
      this.detailGrid.retrieve(this.seriesName, this.fCat, this.fCurr, this.fName, this.fSid);
    }
  }

  public detailLoaded() {
    this.views.selectedIndex = DETAIL_INDEX;
  }
}
