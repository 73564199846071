<h6 class="content-block">Global Factor Performance Attribution</h6>
<p>
  Report ID: <strong>{{this.summary.reportId}}</strong>
  &nbsp;&nbsp;&nbsp; Portfolio: <strong>{{this.summary.portfolioName}}</strong>
  &nbsp;&nbsp;&nbsp; Benchmark: <strong>{{this.summary.benchmarkName}}</strong>
</p>

<div>
  <div class="ax-panel">
    <app-chart (seriesClicked)="seriesClicked($event)"
               [seriesClickable]="true">
    </app-chart>
  </div>
  <div class="ax-panel">
    <app-grid name="Global Factor Performance Attribution Summary">
    </app-grid>
  </div>
</div>
