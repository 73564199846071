import { Injectable } from '@angular/core';
import { FactorService } from '../factor.service';
import { HistoryTab, FACTOR_NAME_TIME_SERIES, FACTOR_SECURITY_TIME_SERIES } from 'src/app/shared/models/factor-history/history-tab';
import { NGXLogger } from 'ngx-logger';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { HistoryItem } from 'src/app/shared/models/factor-history/history-item';

@Injectable()
export class HistoryTabService {
  private counter: number = 0;

  constructor(
    private factor: FactorService,
    private logger: NGXLogger
  ) { }

  public addHistoryTab(from: HistoryTab, to: HistoryTab) {
    // change the name to multiple if the names are different
    if (from.name.toLocaleLowerCase() != to.name.toLocaleLowerCase()) {
      to.name = 'Multiple';
    }

    let map = new Map<string, any>();
    to.historyItems.forEach((tii) => {
      map.set(this.getItemKey(tii), tii);
    });

    // only add it if it does not exist in the destination
    from.historyItems.forEach((fii) => {
      if (!map.has(this.getItemKey(fii))) {
        to.historyItems.push(fii);
      }
    });
  }

  public createNameHistoryTab(reportId: number, seriesName: string, category: string, currency: string, name: string, valCol: string, valColHdr:any): HistoryTab {
    return {
      id: this.counter++,
      name: valColHdr,
      reportId: reportId,
      historyItems: [{
        type: FACTOR_NAME_TIME_SERIES,
        seriesName: seriesName,
        category: category,
        currency: currency,
        name: name,
        security: '',
        axsid: 0,
        valCol: valCol
      }],
      dataSource: new DataSource({
        store: new CustomStore({
          load: () => {
            return [];
          },
          loadMode: 'raw'
        }),
        paginate: false
      }),
      series: []
    };
  }

  public createSecurityHistoryTab(reportId: number, seriesName: string, category: string, currency: string, name: string, security: string, axsid: number, valCol: string, valColHdr:any): HistoryTab {
    return {
      id: this.counter++,
      name: valColHdr,
      reportId: reportId,
      historyItems: [{
        type: FACTOR_SECURITY_TIME_SERIES,
        seriesName: seriesName,
        category: category,
        currency: currency,
        name: name,
        security: security,
        axsid: axsid,
        valCol: valCol
      }],
      dataSource: new DataSource({
        store: new CustomStore({
          load: () => {
            return [];
          },
          loadMode: 'raw'
        }),
        paginate: false
      }),
      series: []
    };
  }

  public fillHistoryTab(history: HistoryTab, map: Map<string, any>) {
    if (!history) {
      return;
    }

    this.fillDataSource(history, map);
    this.fillSeries(history);
  }

  private fillDataSource(history: HistoryTab, map: Map<string, any>) {
    if (!history) {
      return;
    }

    if (!history.historyItems) {
      return;
    }

    history.dataSource = new DataSource({
      store: new CustomStore({
        load: () => {
          return new Promise(async (resolve) => {
            let dataList = [];
            for (let item of history.historyItems) {
              let key = this.getItemKey(item);
              if (map.has(key)) {
                dataList.push(map.get(key));
              } else {
                let data = await this.loadTimeSeries(item);
                let jsonObj = JSON.parse(data);
                map.set(key, jsonObj);
                dataList.push(jsonObj);
              }
            }

            resolve(this.mergeDataSources(dataList));
          });
        },
        loadMode: 'raw'
      }),
      //filter: ['t', '>', '6'],
      paginate: false
    });
  }

  private fillSeries(history: HistoryTab) {
    if (!history) {
      return;
    }

    if (!history.historyItems) {
      return;
    }

    let series = [];
    for (let ii = 0; ii < history.historyItems.length; ii++) {
      let item = history.historyItems[ii];
      switch (item.type) {
        case FACTOR_NAME_TIME_SERIES:
          series.push({
            type: 'line',
            argumentField: 'begin_date',
            valueField: 'val' + ii,
            name: item.name + '(' + item.seriesName + ')'
          });
          break;
        case FACTOR_SECURITY_TIME_SERIES:
          series.push({
            type: 'line',
            argumentField: 'begin_date',
            valueField: 'val' + ii,
            name: item.security + '(' + item.seriesName + ')'
          });
          break;
        default:
          throw new Error('Type ' + item.type + ' is not supported for the history tabs');
      }
    }

    history.series = series;
  }

  private async loadTimeSeries(item: HistoryItem): Promise<any> {
    switch (item.type) {
      case FACTOR_NAME_TIME_SERIES:
        return await this.factor
          .retrieveNameTimeSeries(item.seriesName, item.category, item.currency, item.name, item.valCol)
          .toPromise();
      case FACTOR_SECURITY_TIME_SERIES:
        return await this.factor
          .retrieveSecurityTimeSeries(item.seriesName, item.category, item.currency, item.name, item.axsid, item.valCol)
          .toPromise()
      default:
        throw new Error('Type ' + item.type + ' is not supported for the history tabs');
    }
  }

  private mergeDataSources(dataList: any[]): any {
    if (!dataList) {
      return null;
    }

    let map = new Map();
    for (let ii = 0; ii < dataList.length; ii++) {
      let data = dataList[ii];
      if (data) {
        for (let row of data) {
          let key = row['begin_date'];
          if (!map.has(key)) {
            map.set(key, row);
          }

          map.get(key)['val' + ii] = row['val'];
        }
      }
    }

    return Array.from(map.values());
  }

  private getItemKey(item: HistoryItem): string {
    if (item == null) {
      return '';
    } else {
      return item.valCol + '/' + item.seriesName + '/' + item.name + '/' + item.security + '/' + item.axsid;
    }
  }
}
