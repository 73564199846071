import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//import { forEach } from '@angular/router/src/utils/collection';
import { SummaryService } from '../summary/summary.service';
import { DetailService } from '../detail/detail.service';
import { AxGridComponent, AxTreeMapComponent, AxTreeMapItem } from '../../../shared/components';
import { NGXLogger } from 'ngx-logger';
import { RptParamService } from 'src/app/shared/services';
//import CustomStore from 'devextreme/data/custom_store';
//import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements AfterViewInit {
  @ViewChild(AxGridComponent) grid!: AxGridComponent;
  @ViewChild(AxTreeMapComponent) treeMap!: AxTreeMapComponent;

  private treeMapList: AxTreeMapItem[] = [];
  private treeMapPath: string[] = [];
  //private dataSource;
  //private customStore: CustomStore;
  dataSet: string | null;
  objId: string | null;
  objName: string;
  objValue: string;
  isActive: boolean;
  analysisDate!: Date;

  constructor(
    private detail: DetailService,
    private route: ActivatedRoute,
    public rptParam: RptParamService,
    public summary: SummaryService,
    private logger: NGXLogger
  ) {
    this.objId = this.route.snapshot.paramMap.get('reportId');
    this.dataSet = this.route.snapshot.paramMap.get('seriesName');
    let dateStr = this.route.snapshot.paramMap.get('date');

    if (dateStr == '0') {
      if (this.summary.analysisDate) {
        this.analysisDate = this.summary.analysisDate;
      }
      
    } else {
      this.analysisDate = new Date(dateStr!);
    }

    if (this.dataSet == '0') {
      this.dataSet = this.summary.seriesName;
    }

    this.isActive = false;
    this.objId = this.summary.reportId + '';
    this.objName = '';
    this.objValue = '';
    if (this.dataSet == this.rptParam.PORTFOLIO) {
      this.objName = this.rptParam.PORTFOLIO;
      this.objValue = this.summary.portfolioName;
    } else if (this.dataSet == this.rptParam.BENCHMARK) {
      this.objName = this.rptParam.BENCHMARK;
      this.objValue = this.summary.benchmarkName;
    } else if (this.dataSet == this.rptParam.ACTIVE) {
      this.isActive = true;
    }

    //this.customStore = this.detail.createGridStore(+this.objId, this.analysisDate, this.dataSet);
  }

  ngAfterViewInit() {
    this.treeMap.hasHiddenSecurityGroup = false;
    this.detail.retrieveGroupGridColumns().subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.grid.setColumns(jsonObj);
        this.treeMap.groupNames = this.grid.getGroupNames();
        this.retrieveTreeMap();
        this.retrieveGrid();
      });
  }

  drill() {
    this.retrieveTreeMap();
  }

  private retrieveGrid() {
    //this.dataSource = this.customStore;
    this.logger.debug("GFPA retrieveGrid started");
    this.grid.showLoadingIndicator();
    this.detail.retrieveGrid(+this.objId!, this.analysisDate, this.dataSet!).subscribe(
      data => {
        let jsonObj = JSON.parse(data);
        this.grid.dataSource = jsonObj;
        this.logger.debug("GFPA retrieveGrid finished");
      })
      .add(() => {
        this.grid.hideLoadingIndicator();
      });
  }

  private retrieveTreeMap() {
    this.treeMap.showLoadingIndicator();
    this.detail.retrieveTreeMap(+this.objId!, this.analysisDate, this.dataSet!, this.treeMap.path, this.treeMap.groupNames).subscribe(
      data => {
        if (data) {
          let jsonObj = JSON.parse(data);
          this.treeMapList = jsonObj as AxTreeMapItem[];
          let item: AxTreeMapItem;
          let maxValue = 0;
          for (let ii = 0; ii < this.treeMapList.length; ii++) {
            item = this.treeMapList[ii];
            if (item.value > maxValue) {
              maxValue = item.value;
            }
          }

          this.treeMap.dataSource = this.treeMapList;
          // adjust range for colorizer
          //this.treeMap.colorizer.range = [0, maxValue];
        }
      })
      .add(() => {
        this.treeMap.hideLoadingIndicator();
      });
  }

  /*
  onCustomizeTooltip(event) {
    if (event.node.data.realValue) {
      return {
        text: event.node.data.realValue.toFixed(3)
      }
    } else {
      return {
        text: event.value.toFixed(3)
      }
    }
  }
  */
}

