import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppUtilService {

  constructor() { }

  private _pad: number = Number.MIN_SAFE_INTEGER;

  get pad(): number {
    return this._pad;
  }

  public isPad(num: number): boolean {
    if (num <= this.pad) return true;
    return false;
  }

  public isNotEmpty(value: any): boolean {
    return value !== undefined && value !== null && value !== "";
  }

  public isEmpty(value: any): boolean {
    return !this.isNotEmpty(value);
  }
}
