<ng-container *ngIf="isAuthenticated()">
  <app-side-nav-outer-toolbar title="{{appInfo.title}}">
    <router-outlet></router-outlet>
    <app-footer>
      <p>&copy; 2022 - Wilshire Axiom</p>
    </app-footer>
  </app-side-nav-outer-toolbar>
</ng-container>

<app-notify></app-notify>
